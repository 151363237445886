import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import Typography from '@mui/material/Typography';

import { palette } from '../../theme/colors';
import { OrderSummary } from '../CustomOrders/pageHeaderHelperOrders';

import { Button } from '@mui/material';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { NavLink } from 'react-router-dom';
import BrainBulbPng from './brainbulb.png';
import { populateMicrobatchPageName } from '../../redux/smartbatch/slice';
import { useDispatch } from 'react-redux';

const PageHeader = (props) => {
  // Destructure props for easier access
  const { initial, selected, filteredData, showFilteredData, totalsLive } =
    props;

  const initialClone = { ...initial };

  initialClone.total = initialClone.orders;
  selected.total = selected.orders;

  let initialToUse = !totalsLive ? initialClone : false;

  if (!initialToUse) {
    initialToUse = {
      products: totalsLive.ordersCount,
      socks: totalsLive.socksCount,
      underwear: totalsLive.underwearCount,
      orders: totalsLive.ordersCount,
      finished: totalsLive.finishedGoodsCount,
      notFound: totalsLive.missingProductsCount,
      total: totalsLive.ordersCount,
      inventory: totalsLive.inventory,
    };
  }
  const dispatch = useDispatch();

  return (
    <div className="pageHeader">
      <div className="pageHeading">
        <Typography variant="h1">DS Queue</Typography>

        <OrderSummary title="Total" counter={initialToUse} palette={palette} />
        {selected.orders > 0 && (
          <OrderSummary title="Selected" counter={selected} palette={palette} />
        )}

        {filteredData && showFilteredData && (
          <OrderSummary
            title="Filtered"
            counter={filteredData}
            palette={palette}
          />
        )}
      </div>
      <NavLink
        to="/smartbatch"
        style={{ textDecoration: 'none', color: 'inherit' }}
      >
        <Button
          color="primary"
          onClick={() => {
            dispatch(populateMicrobatchPageName('Dropship'));
          }}
          startIcon={<AutoAwesomeIcon />}
          variant="outlined"
        >
          See smart batches
        </Button>
      </NavLink>
    </div>
  );
};

const styles = {
  brainBulb: { width: '50px', height: '50px' },
  button: {
    textTransform: 'none',
    fontSize: '18px',
    fontWeight: 'bold',
    padding: '5px 10px',
    borderRadius: '10px',
    backgroundColor: 'primary.main',
    color: 'white',
    '&:hover': {
      backgroundColor: 'primary.main',
    },
  },
};

PageHeader.propTypes = {
  initial: PropTypes.number.isRequired,
};

export default PageHeader;
