import React, { useMemo, useState } from 'react';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Collapse,
  IconButton,
  TableHead,
  Button,
} from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { KeyboardArrowDown } from '@mui/icons-material';
import AssignmentIcon from '@mui/icons-material/Assignment';

import PrintModal from '../Page/printModal';
import { countProductsAndBundlesWeb } from '../WebQueues/helpers';
import {
  getUniqueBatchName,
  saveNameIfUsed,
} from '../../utils/getUniqueBatchName';
import {
  checkIfOrdersAreCancelled,
  createInlineBatch,
} from '../../db/inlineOrder';
import Loading from '../Loading';
import { GeneralDialog } from '../Page/generalModal';
import { Toast } from '../Toast';
import { removeBatch } from '../../redux/smartbatch/slice';
import { palette } from '../../theme/colors';
import RunnerPng from './runner.png';
import SockSvg from '../SVGS/sock';
import UnderwearSvg from '../SVGS/underwear';
import { finishedGoodsStyle } from '../../utils/microbatching/constants';

const iconSize = 90;

const SmartBatchTable = ({ batches, microbatchPageName, props, view }) => {
  const { db } = props.firebase;
  const [openRows, setOpenRows] = useState({});
  const [printInlineModal, setPrintInlineModal] = useState(false);
  const [printModalType, setPrintModalType] = useState('inline');
  const [name, setName] = useState('');
  const [notes, setNotes] = useState('');
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [uniqueName, setUniqueName] = useState('');
  const [loadingName, setLoadingName] = useState(false);
  const [batchName, setBatchName] = useState('');
  const [batchModal, setBatchModal] = useState(false);
  const [showToastInfo, setShowToastInfo] = useState(false);
  const [showToastData, setShowToastData] = useState({});
  const [selectedBatchId, setSelectedBatchId] = useState(null);
  const [infoOfItemsInBatch, setInfoOfItemsInBatch] = useState({});

  const selectedInfo = useMemo(() => {
    // Filter out duplicates based on unique order numbers
    const uniqueSelectedOrders = selectedOrders.filter(
      (order, index, self) =>
        index ===
        self.findIndex(
          (o) => o.orderNumber.toString() === order.orderNumber.toString(),
        ),
    );

    return countProductsAndBundlesWeb(uniqueSelectedOrders, true);
  }, [selectedOrders]);

  const toggleRow = (id) => {
    setOpenRows((prevOpenRows) => ({
      ...prevOpenRows,
      [id]: !prevOpenRows[id],
    }));
  };

  const displayProductTypes = (productTypeCheckers) => {
    if (!productTypeCheckers) {
      return '';
    }
    const result = [];

    if (productTypeCheckers.hasCustoms) {
      result.push('Custom');
    }
    if (productTypeCheckers.hasSocks) {
      result.push(<SockSvg color="black" width={iconSize} height={iconSize} />);
    }
    if (productTypeCheckers.hasBoxers) {
      result.push(
        <UnderwearSvg color="black" width={iconSize} height={iconSize} />,
      );
    }

    return result;
  };

  const runBatch = async (event, batch) => {
    event.stopPropagation();

    const orderData = batch.rows.map((row) => row.orderData);

    setSelectedOrders(orderData);

    setSelectedBatchId(batch.id);

    setLoadingName(true);

    const productsInBatch = {
      hasUnderwear: batch.products.hasBoxers,
      hasSocks: batch.products.hasSocks,
    };

    setInfoOfItemsInBatch(productsInBatch);

    await getUniqueBatchName(setUniqueName, setName, db);

    setLoadingName(false);

    setPrintInlineModal(true);
  };

  const handleClosePrintModal = () => {
    setPrintInlineModal(false);
  };

  const handleBatchResult = (result) => {
    setBatchModal(true);
    // setWorking(false);
    if (result) {
      setSelectedOrders([]);
    }
  };

  const handlePrintBatch = async (
    name,
    secondaryName = '',
    color = '#000000',
    type = 'belt',
    underwearFormat = 'image',
    barcode = '',
    sockFormat = 'image',
    sockSheetType = 'belt',
    underwearSheetType = 'belt',
  ) => {
    try {
      const isDsQueue = microbatchPageName !== 'web';

      setBatchName(name);

      let selectedOrdersInNewBatch = selectedOrders;

      const originalOrderNumbers = _.map(
        selectedOrdersInNewBatch,
        'orderNumber',
      );

      const orderNumbers = [...new Set(originalOrderNumbers)];

      const data = await checkIfOrdersAreCancelled(db, orderNumbers, isDsQueue);
      // compare the orderNumbers with the canceled orders
      const canceledOrders = _.intersection(orderNumbers, data);

      if (canceledOrders.length > 0) {
        setShowToastInfo(true);
        setShowToastData({
          isError: true,
          message:
            'Some of the selected orders have been canceled: ' +
            canceledOrders.join(', ') +
            '. They have been removed.',
        });
        // remove the canceled orders from the selected orders
        const newSelectedOrders = _.filter(
          selectedOrdersInNewBatch,
          (order) => !canceledOrders.includes(order.orderNumber),
        );
        selectedOrdersInNewBatch = newSelectedOrders;
      }

      const selectedOrdersUpdated = selectedOrdersInNewBatch;

      if (selectedOrdersUpdated.length === 0) {
        setPrintInlineModal(false);
        setShowToastInfo(true);
        setShowToastData({
          isError: true,
          message: 'All selected orders have been canceled',
        });
        return;
      }

      createInlineBatch(
        db,
        name,
        secondaryName,
        color,
        type,
        underwearFormat,
        barcode,
        selectedOrdersUpdated,
        handleBatchResult,
        isDsQueue,
        props.formats,
        props.sizes,
        notes,
        sockFormat,
        sockSheetType,
        underwearSheetType,
      );

      // reset selected orders
      setSelectedOrders([]);
      handleClosePrintModal();

      if (name?.toLowerCase().trim() === uniqueName?.toLowerCase().trim()) {
        console.log('User used generated name, so we save it');
        await saveNameIfUsed(name, db);
      }
      setUniqueName('');
    } catch (error) {
      console.error('Error printing batch inline', error);
    } finally {
      setSelectedBatchId(null);
    }
  };

  const handleCloseBatchModal = () => {
    setBatchModal(false);
  };

  const getTextColor = (style) =>
    finishedGoodsStyle.includes(style?.toLowerCase())
      ? palette.productPurple
      : 'inherit';

  return (
    <>
      <TableBody>
        {batches.map((batch, index) => {
          const isFinishedGoods = batch.type === 'finishedGoods';
          const type = isFinishedGoods
            ? 'FINISHED GOODS'
            : microbatchPageName.toUpperCase();
          // Group rows by unique order numbers
          const groupedOrders = batch.rows.reduce((acc, item) => {
            const { orderNumber } = item;
            if (!acc[orderNumber]) {
              acc[orderNumber] = {
                ...item,
                items: [],
              };
            }
            acc[orderNumber].items.push(item);
            return acc;
          }, {});

          // Get the number of orders in the batch by finding unique order numbers
          const numberOfOrders = new Set(
            batch.rows.map((row) => row.orderNumber),
          ).size;

          const totalItems = batch.rows.reduce(
            (acc, order) =>
              !finishedGoodsStyle.includes(order?.style?.toLowerCase())
                ? acc +
                  (order.isBoxSet
                    ? order.boxSetItems * order.quantity
                    : order.quantity)
                : acc,
            0,
          );

          const totalItemsFinishedGoods = batch.rows.reduce(
            (acc, order) =>
              finishedGoodsStyle.includes(order?.style?.toLowerCase())
                ? acc +
                  (order.isBoxSet
                    ? order.boxSetItems * order.quantity
                    : order.quantity)
                : acc,
            0,
          );

          const dateInfoUpdated = batch.oldestOrder.replace(',', '');

          const dateParts = dateInfoUpdated.split(' ');

          return (
            <React.Fragment key={index}>
              {/* Main Row */}
              <TableRow onClick={() => toggleRow(index)}>
                <TableCell
                  onClick={(e) => e.stopPropagation()}
                  sx={styles.tableCell}
                >
                  {/* <Checkbox /> */}
                </TableCell>
                <TableCell sx={styles.tableCell}>
                  <Box display="flex" alignItems="center">
                    <IconButton aria-label="expand row" size="small">
                      {openRows[index] ? (
                        <KeyboardArrowDown />
                      ) : (
                        <KeyboardArrowRightIcon />
                      )}
                    </IconButton>
                    <Box display="flex" flexDirection="column" ml={1}>
                      <Typography variant="h4">{dateParts[0]}</Typography>
                      <Typography variant="body1">
                        {`${dateParts[1]} ${dateParts[2]}`}{' '}
                        {/* Combine time and AM/PM */}
                      </Typography>
                    </Box>
                  </Box>
                </TableCell>
                <TableCell sx={styles.tableCell}>
                  {totalItemsFinishedGoods !== 0 && (
                    <AssignmentIcon sx={styles.finishedIcon} />
                  )}
                </TableCell>
                <TableCell
                  sx={isFinishedGoods ? styles.finishedGoods : styles.tableCell}
                >
                  {type}
                </TableCell>
                <TableCell
                  sx={isFinishedGoods ? styles.finishedGoods : styles.tableCell}
                >
                  {displayProductTypes(batch?.products)}
                </TableCell>
                <TableCell
                  sx={isFinishedGoods ? styles.finishedGoods : styles.tableCell}
                >
                  {view}
                </TableCell>
                <TableCell sx={styles.tableCell}>
                  {!isFinishedGoods ? batch.sheetCount : 0}
                </TableCell>
                <TableCell sx={styles.tableCell}>
                  <Button
                    variant="contained"
                    onClick={(e) => runBatch(e, batch)}
                    sx={styles.runBatch}
                  >
                    <img src={RunnerPng} alt="runner" style={styles.runner} />
                  </Button>
                </TableCell>
              </TableRow>

              {/* Collapsible Row for grouped orders */}
              <TableRow>
                <TableCell
                  style={{ paddingBottom: 0, paddingTop: 0 }}
                  colSpan={8}
                >
                  <Collapse in={openRows[index]} timeout="auto" unmountOnExit>
                    <Box
                      margin={2}
                      padding={2}
                      bgcolor="#f9f9f9"
                      borderRadius={2}
                    >
                      <Typography variant="subtitle1" gutterBottom>
                        Order Details
                      </Typography>
                      {/* Summary Section */}
                      <Box
                        display="flex"
                        justifyContent="center"
                        mb={2}
                        gap={2}
                        paddingY={1}
                        borderBottom="1px solid #ddd"
                      >
                        <Typography variant="h3" color="textSecondary">
                          <strong>{numberOfOrders}</strong> orders
                        </Typography>
                        <Typography variant="h3" color="textSecondary">
                          <strong>{totalItems}</strong> items
                        </Typography>
                        {totalItemsFinishedGoods !== 0 && (
                          <Typography variant="h3" color="textSecondary">
                            <strong
                              style={{
                                color: palette.productPurple,
                              }}
                            >
                              {totalItemsFinishedGoods}
                            </strong>{' '}
                            <strong
                              style={{
                                color: palette.productPurple,
                              }}
                            >
                              Finished Goods
                            </strong>
                          </Typography>
                        )}
                      </Box>

                      {/* Display each order and its items */}
                      <Table size="small" aria-label="order-details">
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              <strong>Order #</strong>
                            </TableCell>
                            <TableCell>
                              <strong>SKU</strong>
                            </TableCell>
                            <TableCell>
                              <strong>Quantity</strong>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {Object.values(groupedOrders).map(
                            (order, orderIndex) => (
                              <React.Fragment key={orderIndex}>
                                {/* Main row for each order */}
                                <TableRow>
                                  <TableCell rowSpan={order.items.length}>
                                    #{order.orderNumber}
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      color: getTextColor(
                                        order.items[0]?.style,
                                      ),
                                    }}
                                  >
                                    {order.items[0].sku}
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      color: getTextColor(
                                        order.items[0]?.style,
                                      ),
                                    }}
                                  >
                                    {order.items[0].quantity}
                                  </TableCell>
                                </TableRow>
                                {/* Additional rows for more items in the order */}
                                {order.items.slice(1).map((item, itemIndex) => (
                                  <TableRow key={itemIndex}>
                                    <TableCell
                                      style={{
                                        color: getTextColor(item?.style),
                                      }}
                                    >
                                      {item.sku}
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        color: getTextColor(item?.style),
                                      }}
                                    >
                                      {item.quantity}
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </React.Fragment>
                            ),
                          )}
                        </TableBody>
                      </Table>
                    </Box>
                  </Collapse>
                </TableCell>
              </TableRow>
            </React.Fragment>
          );
        })}
      </TableBody>
      {loadingName ? (
        <Loading />
      ) : (
        <PrintModal
          {...props}
          open={printInlineModal}
          orders={selectedInfo.orders}
          onPrint={handlePrintBatch}
          onClose={handleClosePrintModal}
          source="Order"
          bundles={selectedInfo.bundles}
          products={selectedInfo.products}
          name={name}
          setName={setName}
          titleName="Web"
          isCustom={printModalType === 'custom'}
          notes={notes}
          setNotes={setNotes}
          showTheseOptions={infoOfItemsInBatch}
        />
      )}
      <GeneralDialog
        title="Batch"
        open={batchModal}
        onClose={handleCloseBatchModal}
        onConfirm={handleCloseBatchModal}
      >
        <Typography variant="body2" gutterBottom>
          Inline BATCH name is <strong>{batchName}</strong>
        </Typography>
      </GeneralDialog>
      <Toast
        show={showToastInfo}
        data={showToastData}
        onClose={() => setShowToastInfo(false)}
      />
    </>
  );
};

const styles = {
  finishedGoods: {
    color: palette.productPurple,
    fontWeight: 'bold',
    fontSize: '18px',
  },
  finishedIcon: { color: '#14B779', verticalAlign: 'middle' },
  runner: { width: '40px', height: '40px' },
  runBatch: {
    backgroundColor: '#1F7CF9',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#1F7CF9',
    },
    borderRadius: '10px',
    width: '100px',
  },
  tableCell: {
    fontSize: '18px',
  },
};

export default SmartBatchTable;
