import { Box } from '@mui/material';

import CardsBarGraphs from './CardsBarGraphs';

const HalfCards = ({ data, keyValue, isMobile, mainDropDownProduct }) => {
  const dataToUse = data[mainDropDownProduct];

  const productTypes = Object.keys(dataToUse || {});

  // make sure socks is the first item in the array and remove any duplicates
  const uniqueProductTypes = [...new Set(productTypes)];

  const socksIndex = uniqueProductTypes.indexOf('socks');

  if (socksIndex !== 0) {
    uniqueProductTypes.splice(socksIndex, 1);
    uniqueProductTypes.unshift('socks');
  }

  return (
    <Box
      sx={{
        ...styles.card,
        justifyContent: isMobile ? '' : 'space-evenly',
        gap: 2,
      }}
      key={keyValue}
    >
      {uniqueProductTypes.map((type, index) => (
        <CardsBarGraphs
          product={{
            type: type,
            formats: dataToUse[type],
          }}
          chosenProductType={mainDropDownProduct}
          index={index}
          isMobile={isMobile}
          key={index}
        />
      ))}
    </Box>
  );
};

const styles = {
  card: {
    display: 'flex',
    flexDirection: 'row',
  },
  swipeContainer: {
    display: 'flex',
    overflowX: 'scroll',
    WebkitOverflowScrolling: 'touch',
    scrollSnapType: 'x mandatory',
    gap: '16px',
  },
  swipeItem: {
    flex: '0 0 auto',
    scrollSnapAlign: 'start',
    width: '80%',
    marginRight: '1px', // Add margin to create the sliver effect
  },
};

export default HalfCards;
