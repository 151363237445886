import { makeStyles } from '@mui/styles';

const useHeaderStyles = makeStyles((theme) => ({
  modalTitle: {
    fontSize: '32px',
    lineHeight: '45px',
    fontWeight: '500',
    color: '#000000',
  },
  modalText: {
    fontSize: '16px',
    lineHeight: '22px',
    fontWeight: '400',
    color: '#000000',
  },
  closeButton: {
    position: 'absolute',
    right: '0px',
    top: '10px',
    color: '#000000',
  },
  iconButton: {
    position: 'absolute',
    left: '-30px',
    padding: '0',
  },
  inputRoot: {
    width: '100%',
    fontSize: '14px',
    fontWeight: '500',
    letterSpacing: '1.5px',
    color: '#000000',
    background: 'rgba(0,0,0,0.04)',
    padding: '11px 15px',
    borderRadius: '4px',
  },
  inputLabel: {
    fontSize: '16px',
    fontWeight: '500',
    color: '#000000',
  },
  inputInlineLabel: {
    fontSize: '16px',
    fontWeight: '500',
    color: '#000000',
    marginTop: '15px',
    marginRight: '25px',
  },
  addButton: {
    textTransform: 'uppercase!important',
    textDecoration: 'underline!important',
    '&:hover': {
      textDecoration: 'underline!important',
    },
  },
  saveButton: {
    background: '#1F7CF2',
    padding: '8px 56px',
    borderRadius: '6px',
    fontSize: '16px',
    fontWeight: '500',
    color: '#FFFFFF',
    textTransform: 'initial',
  },
  duplicateButton: {
    background: 'rgb(61, 61, 61)',
    padding: '8px 56px',
    borderRadius: '6px',
    fontSize: '16px',
    fontWeight: '500',
    color: '#FFFFFF',
    textTransform: 'initial',
    marginRight: '20px',
  },
  link: {
    display: 'block',
    marginTop: '10px',
    fontSize: '16px',
    fontWeight: '500',
    color: '#000000',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  tagList: {
    display: 'flex',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    marginTop: '16px',
    '& li': {
      marginBottom: '10px',
    },
  },
  chip: {
    marginRight: '16px',
    borderRadius: '6px',
    background: '#3d3d3d',
    height: '40px',
    padding: '0 15px 0 25px',
  },
  chipLabel: {
    fontSize: '16px',
    fontWeight: '500',
    color: '#FFFFFF',
  },
  chipButton: {
    color: '#ffffff',
    opacity: '0.4',
    marginLeft: '15px',
    padding: '0',
    '&:hover': {
      opacity: '1',
    },
  },
  image: {
    width: '100%',
    height: 'auto',
  },
  dropBox: {
    textAlign: 'center',
    padding: '88px 0px',
    borderRadius: '6px',
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    '& svg': {
      opacity: '0.56',
    },
  },
  artDropBox: {
    position: 'absolute',
    left: '0',
    top: '0',
    width: '100%',
    height: 'calc(100% - 140px)',
    padding: '35px 0',
    zIndex: '100',
  },

  dropLabel: {
    fontSize: '16px',
    fontWeight: '500',
    color: '#000000',
    opacity: '0.56',
    margin: '0',
  },
  dropButton: {
    fontSize: '16px',
    fontWeight: '500',
    color: '#FFFFFF',
    width: '160px',
    background: '#3D3D3D',
    borderRadius: '6px',
    textTransform: 'Capitalize',
    height: '40px',
    padding: '0',
    marginTop: '20px',
  },
  subTitle: {
    fontSize: '24px',
    fontWeight: '500',
    color: '#000000',
    marginBottom: '10px',
  },
  boldText: {
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '0.94',
    color: '#000000',
    marginBottom: '10px',
  },
  text: {
    fontSize: '16px',
    lineHeight: '0.94',
    color: '#000000',
    marginBottom: '10px',
  },
  errorText: {
    fontSize: '16px',
    lineHeight: '0.94',
    color: '#FF0000',
    marginBottom: '10px',
  },
  errorList: {
    paddingLeft: '0px',
    listStyle: 'none',
  },
  errorRow: {
    marginBottom: '10px',
  },
  errorItem: {},
  progress: {
    height: '6px',
    borderRadius: '3px',
    backgroundColor: 'rgba(20, 183, 121, 0.16 )',
  },
  fullImage: {
    width: '100%',
    height: 'auto',
  },
  itemBoxOverlay: {
    position: 'absolute',
    left: '0',
    top: '0',
    width: '100%',
    height: '100%',
    zIndex: '100',
    background: 'rgba(255,255,255, 0.4)',
  },
  ImageBox1: {
    width: '60px',
  },
  ImageBox2: {
    width: 'calc(50% - 50px)',
  },
  ImageBox3: {
    width: '40px',
    opacity: '0.5',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  ImageBox4: {
    width: 'calc(50% - 50px)',
  },
  ImageBox5: {
    width: 'calc(50% - 10px)',
  },
  ImageBox6: {
    width: 'calc(50% - 10px)',
  },
  ImageBoxHeader: {
    fontSize: '16px',
    lineHeight: '24px',
    color: '#000000',
    textAlign: 'center',
  },
  ImageBoxFooter: {
    fontSize: '16px',
    lineHeight: '24px',
    color: '#000000',
    textAlign: 'center',
  },
  ImageWrapper: {
    position: 'relative',
    border: '1px solid gray',
    borderRadius: '5px',
    fontSize: '0',
    padding: '3px',
    minHeight: '140px',
    textAlign: 'center',
  },
  IdenticalImageWrapper: {
    position: 'relative',
    border: '1px solid gray',
    borderRadius: '5px',
    fontSize: '0',
    padding: '3px',
    minHeight: '220px',
    textAlign: 'center',
  },
  BoxedImageWrapper: {
    position: 'relative',
    border: '1px solid gray',
    borderRadius: '5px',
    fontSize: '0',
    padding: '3px',
    textAlign: 'center',
  },
  SoloImageWrapper: {
    position: 'relative',
    border: '1px solid gray',
    borderRadius: '5px',
    fontSize: '0',
    padding: '3px',
    textAlign: 'center',
  },
  ImageWrapperError: {
    position: 'relative',
    border: '1px solid red',
    borderRadius: '5px',
    fontSize: '0',
    padding: '3px',
    minHeight: '220px',
    textAlign: 'center',
  },
  selectButton: {
    background: '#12B275',
    color: '#FFFFFF',
    fontSize: '16px',
    fontWeight: '500',
    borderRadius: '3px',
    width: '100%',
    marginBottom: '20px',
    border: '1px solid rgb(0 0 0 / 12%)',
    '&:hover': {
      background: '#000000',
    },
  },
  approveButton: {
    background: '#12B275!important',
    color: '#FFFFFF!important',
    fontSize: '16px!important',
    fontWeight: '500!important',
    borderRadius: '3px!important',
    width: '100%',
    border: '1px solid rgb(0 0 0 / 12%)!important',
    '&:hover': {
      background: '#000000!important',
    },
  },
  approveSoloButton: {
    background: '#12B275!important',
    color: '#FFFFFF!important',
    fontSize: '16px!important',
    fontWeight: '500!important',
    borderRadius: '3px!important',
    width: '100%',
    padding: '7px 0!important',
    marginTop: '20px!important',
    border: '1px solid rgb(0 0 0 / 12%)!important',
    '&:hover': {
      background: '#000000!important',
    },
  },
  deleteButton: {
    background: '#E24716!important',
    color: '#FFFFFF!important',
    fontSize: '16px!important',
    fontWeight: '500!important',
    borderRadius: '3px!important',
    width: '100%',
    border: '1px solid rgb(0 0 0 / 12%)!important',
    '&:hover': {
      background: '#000000!important',
    },
  },
  deleteSoloButton: {
    background: '#E24716!important',
    color: '#FFFFFF!important',
    fontSize: '16px!important',
    fontWeight: '500!important',
    borderRadius: '3px!important',
    width: '100%',
    padding: '7px 0!important',
    marginTop: '20px!important',
    border: '1px solid rgb(0 0 0 / 12%)!important',
    '&:hover': {
      background: '#000000!important',
    },
  },
  itemBox1: {
    width: '35%',
  },
  itemBox2: {
    width: '50%',
  },
  itemBox3: {
    width: '15%',
    padding: '20px',
  },
  itemBox4: {
    width: '25%',
  },
  itemBox5: {
    width: '30%',
  },
  itemBox6: {
    width: '45%',
    padding: '20px 0',
  },
  grayRow: {
    backgroundColor: 'rgba(224, 224, 224, 0.35)',
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  whiteRow: {},
  autoRow: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    backgroundColor: '#F1F7FE',
  },

  sizeText: {
    fontSize: '32px!important',
    fontWeight: 'bold!important',
  },
  sizeIcon: {
    fontSize: '40px!important',
    marginTop: '5px!important',
    color: 'rgba(0, 0, 0, 0.26)!important',
  },
  buttonBox: {
    textAlign: 'right',
    padding: '0 0px',
  },
  centeredSpinnerBox: {
    position: 'relative',
    padding: '3px',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    display: 'flex',
  },
}));

export default useHeaderStyles;
