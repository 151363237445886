import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Typography,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';

import Layout from '../Page';
import { withAuthorization } from '../Session';
import SmartBatchTable from './table';
import LoadingMicroBatch from './LoadingMicroBatch';
import {
  searchInlineOrdersForMicroBatching,
  getDsOrdersForSmartBatchTypesense,
} from '../../module/typesense';
import { setIsLoading } from '../../redux/smartbatch/slice';
import microbatchingModule from '../../utils/microbatching';
import { RollsToSee } from './Rolls';
import {
  getUniqueBatchName,
  saveNameIfUsed,
} from '../../utils/getUniqueBatchName';
import PrintModalSmartBatches from './PrintModalForMany';
import { createInlineBatch } from '../../db/inlineOrder';

const SmartBatch = (props) => {
  const { firebase, formats, sizes } = props;
  const { db } = firebase;
  const dispatch = useDispatch();
  const { microbatchedOrders, microbatchPageName, populatedRolls } =
    useSelector((state) => state.microbatch);

  const [view, setView] = useState('BELT'); // State to manage BELT/SHUTTLE
  const [loading, setLoading] = useState(true); // Control loading state
  const pageToNavigate = microbatchPageName === 'web' ? '/' : '/dsqueues';
  const [indexToPrint, setIndexToPrint] = useState(null);
  const [openPrintModal, setOpenPrintModal] = useState(false);
  const [rollToMake, setRollToMake] = useState(null);
  const [batchName, setBatchName] = useState('');
  const [uniqueName, setUniqueName] = useState('');
  const [notes, setNotes] = useState('');
  const [viewRolls, setViewRolls] = useState(false);

  useEffect(() => {
    fetchForMicroBatching();
  }, [view]);

  if (microbatchPageName === '' || !microbatchPageName) {
    // redirect to the legacy view
    return (window.location.href = '/');
  }

  const fetchForMicroBatching = async () => {
    if (microbatchPageName === 'web') {
      setLoading(true);
      dispatch(setIsLoading(true));
      searchInlineOrdersForMicroBatching(async (orderData) => {
        await microbatchingModule(
          orderData,
          microbatchPageName === 'web',
          dispatch,
          db,
          sizes,
          formats,
          view === 'BELT',
        );
        setTimeout(() => {
          dispatch(setIsLoading(false));
          setLoading(false);
        }, 2000);
      }, db);
    } else {
      getDsOrdersForSmartBatchTypesense(db, async (orders) => {
        await microbatchingModule(
          orders,
          microbatchPageName === 'web',
          dispatch,
          db,
          sizes,
          formats,
          view === 'BELT',
        );
        setTimeout(() => {
          dispatch(setIsLoading(false));
          setLoading(false);
        }, 2000);
      });
    }
  };

  const handleViewRollsChange = (event) => {
    setViewRolls(event.target.checked);
  };

  const hasRollsToMake =
    populatedRolls?.socksOnlyRolls?.length > 0 ||
    populatedRolls?.underwearOnlyRolls?.length > 0;

  const createRoll = async (roll) => {
    setIndexToPrint(0);
    await getUniqueBatchName(setUniqueName, setBatchName, db);
    setRollToMake(roll);
    setOpenPrintModal(true);
  };

  const handlePrint = async (
    name,
    secondaryName = '',
    color = '#000000',
    type = 'belt',
    underwearFormat = 'image',
    barcode = '',
    sockFormat = 'png',
    sockBatchType,
    underwearBatchType,
  ) => {
    setBatchName(name);

    const selectedOrdersInNewBatch = rollToMake[indexToPrint]?.rows.map(
      (order) => order.orderData,
    );

    createInlineBatch(
      db,
      name,
      secondaryName,
      color,
      type,
      underwearFormat,
      barcode,
      selectedOrdersInNewBatch,
      null,
      false,
      formats,
      sizes,
      notes,
      sockFormat,
      sockBatchType,
      underwearBatchType,
    );

    setOpenPrintModal(false);

    if (name?.toLowerCase().trim() === uniqueName?.toLowerCase().trim()) {
      await saveNameIfUsed(name, db);
    }

    setUniqueName('');

    if (indexToPrint + 1 < rollToMake.length) {
      setOpenPrintModal(false);
      // up the current index using the
      setIndexToPrint(indexToPrint + 1);
      await getUniqueBatchName(setUniqueName, setBatchName, db);
      setOpenPrintModal(true);
    } else {
      setOpenPrintModal(false);
    }
  };

  if (loading) {
    return <LoadingMicroBatch />;
  }

  if (microbatchedOrders.length === 0) {
    return (
      <Layout page="smartbatch" user={props.user}>
        <Box sx={styles.zeroOrders}>
          <NavLink
            to={pageToNavigate}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            <Button sx={styles.backToLegacyView}>Back to Legacy View</Button>
          </NavLink>
          <Typography variant="h6">No orders found!</Typography>
        </Box>
      </Layout>
    );
  }
  return (
    <Layout page="smartbatch" user={props.user}>
      <Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <NavLink
            to={pageToNavigate}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            <Button sx={styles.backToLegacyView}>Back to Legacy View</Button>
          </NavLink>
          {hasRollsToMake && (
            <Box sx={styles.rollsToMake}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={viewRolls}
                    onChange={handleViewRollsChange}
                    color="primary"
                  />
                }
                label={
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: '16px',
                      fontWeight: 'bold',
                      color: '#000000',
                    }}
                  >
                    View Rolls
                  </Typography>
                }
              />
            </Box>
          )}
        </Box>

        {!viewRolls && (
          <TableContainer component={Paper}>
            <Table>
              <TableHead sx={styles.tableHead}>
                <TableRow>
                  <TableCell />
                  <TableCell sx={styles.tableCell}>Order Dates</TableCell>
                  <TableCell
                    sx={{
                      ...styles.tableCell,
                      width: '10%',
                    }}
                  >
                    Notes
                  </TableCell>
                  <TableCell sx={styles.tableCell}>Batch Type</TableCell>
                  <TableCell sx={styles.tableCell}>Products</TableCell>
                  <TableCell sx={styles.tableCell}>Styles</TableCell>
                  <TableCell sx={styles.tableCell}>Sheets</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <SmartBatchTable
                batches={microbatchedOrders}
                microbatchPageName={microbatchPageName}
                props={props}
                dispatch={dispatch}
                view={view}
              />
            </Table>
          </TableContainer>
        )}

        {viewRolls && (
          <RollsToSee rolls={populatedRolls} createRoll={createRoll} />
        )}
      </Box>
      {openPrintModal && (
        <PrintModalSmartBatches
          {...props}
          open={openPrintModal}
          onClose={() => setOpenPrintModal(false)}
          onPrint={handlePrint}
          source="Order"
          products={rollToMake[indexToPrint]?.productCounts}
          setName={setBatchName}
          name={batchName}
          isCustom={false}
          titleName="Web"
          notes={notes}
          setNotes={setNotes}
          showTheseOptions={rollToMake[indexToPrint]?.products}
          indexToPrint={indexToPrint + 1}
          totalBatches={rollToMake.length}
        />
      )}
    </Layout>
  );
};

const styles = {
  backToLegacyView: {
    backgroundColor: '#FB7701',
    color: 'white',
    padding: '10px 20px',
    borderRadius: '5px',
    textTransform: 'none',
    fontWeight: 'bold',
    fontSize: '16px',
    '&:hover': {
      backgroundColor: '#FB7701',
      color: 'white',
    },
  },
  rollsToMake: {
    display: 'flex',
  },
  tableHead: {
    backgroundColor: '#F5F5F5',
    letterSpacing: '1.5px',
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#000000',
  },
  tableCell: {
    fontWeight: 'bold',
    fontSize: '20px',
    paddingRight: '20px',
    width: '15%',
  },
  zeroOrders: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    gap: '20px',
  },
};

const condition = (authUser) => !!authUser;
export default withAuthorization(condition)(SmartBatch);
