import { useState, useEffect, useRef } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import InputBase from '@mui/material/InputBase';

import { validateBatchName } from '../../module/functions';
import useStyles from './modal.styles';
import iconShuttle from '../../images/shuttle.svg';
import iconBelt from '../../images/belts.svg';
import UnderwearSvg from '../SVGS/underwear';
import SockSvg from '../SVGS/sock';

const colorCodes = ['#000000', '#ff0000', '#0000ff', '#00ff00', '#A6A6A6'];

const PrintModalSmartBatches = (props) => {
  const {
    onPrint,
    onClose,
    open,
    products,
    source,
    setName,
    name,
    titleName,
    notes,
    setNotes,
    isCustom,
    showTheseOptions,
    indexToPrint,
    totalBatches,
  } = props;

  let optionsForPrinting = showTheseOptions;

  if (!showTheseOptions) {
    optionsForPrinting = {
      hasUnderwear: true,
      hasSocks: true,
    };
  }

  const [secondaryName, setSecondaryName] = useState('');
  const [barcode, setBarcode] = useState('');
  const [batchNameError, setBatchNameError] = useState(false);
  const [batchNameErrorMsg, setBatchNameErrorMsg] = useState('');
  const [batchTypeError, setBatchTypeError] = useState(false);
  const [batchTypeErrorMsg, setBatchTypeErrorMsg] = useState('');
  const [press, setPress] = useState(0);
  const [color, setColor] = useState(colorCodes[0]);
  // Update state based on isCustom prop
  useEffect(() => {
    if (isCustom) {
      setSockBatchType('shuttle');
      setSockPrinterType('PNG');
    } else {
      setUnderwearBatchType('shuttle');
      setUnderwearPrinterType('PDF');
      setSockBatchType('');
      setSockPrinterType('');
    }
  }, [isCustom]); // Separate state for each row's batch type
  const [underwearBatchType, setUnderwearBatchType] = useState('');
  const [sockBatchType, setSockBatchType] = useState('');
  // Separate states for printer type
  const [underwearPrinterType, setUnderwearPrinterType] = useState('PDF');
  const [sockPrinterType, setSockPrinterType] = useState('');

  // Function to handle printer type change with toggle behavior
  const handlePrinterTypeChange = (type, rowType) => {
    if (rowType === 'underwear') {
      setUnderwearPrinterType((prevType) => (prevType === type ? '' : type));
    } else if (rowType === 'socks') {
      setSockPrinterType((prevType) => (prevType === type ? '' : type));
    }
  };

  // Function to handle batch type change with toggle behavior
  const handleBatchTypeChange = (type, rowType) => {
    if (rowType === 'underwear') {
      setUnderwearBatchType((prevType) => (prevType === type ? '' : type));
    } else if (rowType === 'socks') {
      setSockBatchType((prevType) => (prevType === type ? '' : type));
    }
  };

  const nameRef = useRef(null);
  const notesRef = useRef(null);

  useEffect(() => {
    const downHandler = (e) => {
      setPress(e.keyCode);
    };

    window.addEventListener('keydown', downHandler);
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', downHandler);
    };
  }, []);

  useEffect(() => {
    if (open) {
      setColor(colorCodes[0]);
      setSecondaryName('');
      setBarcode('');
      setBatchNameError(false);
      setBatchNameErrorMsg('');
      setBatchTypeError(false);
      setBatchTypeErrorMsg('');
      setNotes('');
      setPress(0);
      // nameRef.current.focus();
    }
  }, [open]);

  useEffect(() => {
    if (press === 13 && open) {
      handlePrint();
    } else if (press === 9 && open) {
      console.log('Tab Clicked');
      // secondRef.current.focus();
    }
  }, [press]);

  const handleCloseDialog = () => {
    onClose();
  };

  const debounceTimeout = useRef(null);

  const onChangeName = (event) => {
    const { name, value } = event.target;

    // Clear the previous timeout
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    // Set a new timeout
    debounceTimeout.current = setTimeout(() => {
      const result = validateBatchName(value);
      if (result !== 'Success') {
        setBatchNameError(true);
        setBatchNameErrorMsg(result);
      } else {
        setBatchNameError(false);
        setBatchNameErrorMsg('');
      }
    }, 10); // Adjust the delay as needed

    if (name === 'name') {
      setName(value);
    } else {
      setSecondaryName(value);
    }
  };

  const debounceTimeoutNotes = useRef(null);
  const onChangeNotes = (event) => {
    // add debounce

    const value = event.target.value;
    if (debounceTimeoutNotes.current) {
      clearTimeout(debounceTimeoutNotes.current);
    }
    debounceTimeoutNotes.current = setTimeout(() => {
      setNotes(value);
    }, 10);
  };

  const handlePrint = async () => {
    setBatchTypeError(false);
    setBatchTypeErrorMsg('');

    if (underwearBatchType === '' && optionsForPrinting.hasBoxers) {
      setBatchTypeError(true);
      setBatchTypeErrorMsg('Please select Shuttle or Belt');
      return;
    }

    if (sockBatchType === '' && optionsForPrinting.hasSocks) {
      setBatchTypeError(true);
      setBatchTypeErrorMsg('Please select Shuttle or Belt');
      return;
    }

    if (underwearPrinterType === '' && optionsForPrinting.hasBoxers) {
      setBatchTypeError(true);
      setBatchTypeErrorMsg('Please select a printer');
      return;
    }

    if (sockPrinterType === '' && optionsForPrinting.hasSocks) {
      setBatchTypeError(true);
      setBatchTypeErrorMsg('Please select a printer');
      return;
    }

    const underwearFormatChosen =
      underwearPrinterType === 'PDF' ? 'pdf' : 'png';
    const sockFormatChosen = sockPrinterType === 'PDF' ? 'pdf' : 'png';

    let batchType = '';

    if (optionsForPrinting.hasBoxers && !optionsForPrinting.hasSocks) {
      batchType = underwearBatchType;
    } else if (!optionsForPrinting.hasBoxers && optionsForPrinting.hasSocks) {
      batchType = sockBatchType;
    } else {
      batchType = sockBatchType;
    }

    if (onPrint) {
      onPrint(
        name,
        secondaryName,
        color,
        batchType,
        underwearFormatChosen,
        barcode,
        sockFormatChosen,
        sockBatchType,
        underwearBatchType,
      );
    }
  };

  const renderPrinterType = (rowType) => {
    const printerType =
      rowType === 'underwear' ? underwearPrinterType : sockPrinterType;

    return (
      <Box className={classes.nameRow} sx={{ paddingLeft: '20px' }}>
        <Box className={classes.rowHalfItem}>
          <Box
            className={
              printerType === 'PNG' ? classes.typeActiveBox : classes.typeBox
            }
            sx={{
              height: '65px',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              width: '60px',
            }}
            onClick={() => handlePrinterTypeChange('PNG', rowType)}
          >
            <Typography>PNG</Typography>
          </Box>
        </Box>
        <Box className={classes.rowMiddleItem}></Box>
        <Box className={classes.rowHalfItem}>
          <Box
            className={
              printerType === 'PDF' ? classes.typeActiveBox : classes.typeBox
            }
            sx={{
              height: '65px',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              width: '60px',
            }}
            onClick={() => handlePrinterTypeChange('PDF', rowType)}
          >
            <Typography className={classes.typeLabel}>PDF</Typography>
          </Box>
        </Box>
      </Box>
    );
  };

  const renderBeltOrShuttle = (rowType) => {
    const isUnderwearRow = rowType === 'underwear';
    const batchType = isUnderwearRow ? underwearBatchType : sockBatchType;

    return (
      <Box className={classes.nameRow}>
        <Box className={classes.rowHalfItem}>
          <Box
            className={
              batchType === 'shuttle' ? classes.typeActiveBox : classes.typeBox
            }
            onClick={() => handleBatchTypeChange('shuttle', rowType)}
          >
            <img src={iconShuttle} alt="Shuttle" className={classes.typeIcon} />
            <Typography className={classes.typeLabel}>SHUTTLE</Typography>
          </Box>
        </Box>
        <Box className={classes.rowMiddleItem}></Box>
        <Box className={classes.rowHalfItem}>
          <Box
            className={
              batchType === 'belt' ? classes.typeActiveBox : classes.typeBox
            }
            onClick={() => handleBatchTypeChange('belt', rowType)}
          >
            <img src={iconBelt} alt="Belt" className={classes.typeIcon} />
            <Typography className={classes.typeLabel}>BELT</Typography>
          </Box>
        </Box>
      </Box>
    );
  };

  const renderBatchType = () => {
    const iconSize = 150;
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '20px',
          marginBottom: '20px',
        }}
      >
        {/* Socks Row */}
        {optionsForPrinting.hasSocks && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              alignItems: 'center',
            }}
          >
            <SockSvg color="black" width={iconSize} height={iconSize} />
            {renderBeltOrShuttle('socks')}
            {renderPrinterType('socks')}
          </Box>
        )}

        {/* Underwear Row */}
        {optionsForPrinting.hasBoxers && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              alignItems: 'center',
            }}
          >
            <UnderwearSvg color="black" width={iconSize} height={iconSize} />
            {renderBeltOrShuttle('underwear')}
            {renderPrinterType('underwear')}
          </Box>
        )}

        {batchTypeError && (
          <Box>
            <Typography className={classes.fieldError}>
              {batchTypeErrorMsg}
            </Typography>
          </Box>
        )}
      </Box>
    );
  };

  const classes = useStyles();

  return (
    <Dialog
      aria-labelledby="print-dialog-title"
      open={open}
      fullWidth
      // use onClose with the reason argument to handle the backdropClick event
      onClose={(event, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
          handleCloseDialog();
        }
      }}
    >
      <DialogTitle
        id="print-dialog-title"
        onClose={handleCloseDialog}
        sx={{
          padding: '30px 40px 5px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h2">Print {titleName} Batch</Typography>
        <Typography variant="subtitle1">
          {indexToPrint} of {totalBatches}
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ padding: '0px 40px' }}>
        {source !== 'Manual' && (
          <Typography variant="subtitle1">
            <strong>{products}</strong> PRODUCTS
          </Typography>
        )}

        <Divider
          component="div"
          sx={{ marginTop: '10px', marginBottom: '10px' }}
        />

        <Box className={classes.nameRow}>
          <Box className={classes.rowHalfItem}>
            <Typography variant="inputLabel">Batch Name:</Typography>
            <InputBase
              name="name"
              label="1"
              inputProps={{ tabIndex: '1' }}
              ref={nameRef}
              defaultValue={name}
              className={classes.inputBase}
              onChange={onChangeName}
              autoFocus
            />
          </Box>
        </Box>

        <Box className={classes.notesRow}>
          <Box className={classes.rowHalfItem}>
            <Typography variant="inputLabel">Notes:</Typography>
            <InputBase
              name="notes"
              label="1"
              inputProps={{ tabIndex: '1' }}
              ref={notesRef}
              defaultValue={notes}
              className={classes.inputBase}
              onChange={onChangeNotes}
              autoFocus
            />
          </Box>
        </Box>

        {batchNameError && (
          <Box>
            <Typography className={classes.fieldError}>
              {batchNameErrorMsg}
            </Typography>
          </Box>
        )}

        {renderBatchType()}
      </DialogContent>
      <DialogActions sx={{ padding: '20px 40px' }}>
        <Button
          onClick={handlePrint}
          disabled={batchNameError}
          variant="blue"
          classes={{ disabled: classes.disabledButton }}
          tabIndex="3"
          sx={{ width: '150px' }}
        >
          Print
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PrintModalSmartBatches;
