const convertToReadableRow = (order) => {
  // Calculate productCounts using reduce
  const productCounts = order.rows?.reduce((total, item) => {
    if (item.isBoxSet) {
      return total + item.boxSetItems * item.quantity;
    }
    return total + item.quantity;
  }, 0);

  // Return the updated order object
  return {
    ...order,
    productCounts,
  };
};

// Helper function to create rolls from batches
const createRollsFromBatches = (batches, batchesPerRoll) => {
  const rolls = [];
  for (let i = 0; i < batches.length; i += batchesPerRoll) {
    const roll = batches.slice(i, i + batchesPerRoll);
    if (roll.length === batchesPerRoll) {
      rolls.push(roll); // Only add complete rolls
    }
  }
  return rolls;
};

export const createRolls = async (_orders, isBelt) => {
  const orders = _.cloneDeep(_orders.map(convertToReadableRow));

  // Separate batches by type and constraints
  const socksOnlyBatches = orders.filter(
    (order) =>
      order.type === 'sockOnly' &&
      order.sheetCount === 3 &&
      order.productCounts === 42,
  );

  const underwearOnlyBatches = orders.filter(
    (order) =>
      order.type === 'underwearOnly' &&
      order.sheetCount === 3 &&
      order.productCounts === 9,
  );

  // Create rolls
  const socksOnlyRolls = createRollsFromBatches(socksOnlyBatches, 26); // 26 batches per roll
  const underwearOnlyRolls = createRollsFromBatches(underwearOnlyBatches, 26); // 26 batches per roll

  // Combine rolls into a result object
  const rolls = {
    socksOnlyRolls,
    underwearOnlyRolls,
  };

  return rolls;
};
