// requires environment variable
const GCP_PROJECT_ID = process.env.REACT_APP_PROJECT_ID;
const collections = {
  USERS: 'Users',
  PERMISSIONS: 'Permissions',
  CUSTOM_PRODUCTS_QUEUE: 'CustomProductsQueue',
  INLINE_PRODUCTS_QUEUE: 'InlineProductsQueue',
  CUSTOM_PRODUCT_PRINTBATCHES: 'CustomProductPrintBatches',
  CUSTOM_PRODUCT_PRINTSHEETS: 'CustomProductPrintSheets',
  PRODUCTS: 'Items',
  TEMPLATES: 'Templates',
  CROPSETTINGS: 'CropSettings',
  SIZES: 'Sizes',
  SHIPPINGS: 'Shippings',
  FORMATS: 'Formats',
  HISTORY: 'History',
  ORDERS: 'Orders',
  RESTORE: 'Restore',
  BOXSETS: 'BoxSets',
  QUICKBOOKS: 'QuickBooks',
  PRINT_ERRORS: 'Errors',
  TAGS: 'Tags',
  INVENTORY: 'Inventory',
  SETTINGS: 'Settings',
  UPLOADS: 'Uploads',
  UPLOADS_HISTORY: 'UploadsHistory',
  BARCODES: 'Barcodes',
  JOBS: 'Jobs',
  ACTIVITIES: 'Activities',
  DOWNLOADS_SHIPSTATION: 'DownloadsShipstation',
  DAILY_ANALYTICS: 'DailyAnalytics',
  UNITS_PRODUCED: 'UnitsProduced',
  DAILY_PRODUCED_AND_TO_BE: 'DailyProducedAndToBe',
  DATES: 'Dates',
  MIX_MATCH_COLLECTIONS: 'MixMatchCollections',
  DS_INLINE_QUEUE: 'DSInlineQueue',
  DS_CUSTOM_QUEUE: 'DSCustomQueue',
  DS_ERRORS: 'DSErrors',
  DS_JOBS: 'DSJobs',
  USED_BATCH_NAMES: 'UsedBatchNames',
  UPDATES: 'Updates',
  COUNTS: 'Counts',
  INVENTORY_LOCATIONS: 'InventoryLocations',
  TYPES: 'Types',
  INVENTORY_LOGS: 'InventoryLogs',
  DROPSHIP_COUNTS: 'DropshipCounts',
  COUNTS_SPECIFICS: 'productSpecifics',
};

const orderStatus = {
  PENDING: 'pending',
  STAGE2: 'stage2',
  COMPLETE: 'complete',
  APPROVED: 'approved',
  FLAGGED: 'flagged',
  NEEDSREVISIONS: 'needs-revisions',
  ARCHIVED: 'archived',
};

const orderTypes = {
  SINGLE: 'Single',
  MULTIPLE: 'Multiple',
  OTHER: 'Other',
  MANUAL: 'manual',
};

const SIZES = [
  { id: '1a3uvlwU0548yLVBGLcO', name: 'TDL' },
  { id: '9mIfvRdbpw78UdYA1VZ3', name: 'L/XL' },
  { id: 'AuXXAwCybPRItw3WRxCN', name: 'S/M' },
  { id: 'TXnyH7yzOjq5l64fOAzB', name: 'ADLT' },
  { id: 'Z3XFUStaMILVl22hUH4N', name: 'YM' },
  { id: 'qBw4xfNB6ZMUMxI8T2I9', name: 'YTH' },
  {
    id:
      GCP_PROJECT_ID === 'a2-migration'
        ? 'qBw4xfNB6ZMUMxI8T2I9'
        : 'qMCylx7brDrG81evC606',
    name: 'YTHL',
    sku: 'YTHL',
  },
];

const TYPES = { Socks: 'Socks', Masks: 'Masks' };

const STYLES = {
  Graphic: 'Graphic',
  HyperOptic: 'HyperOptic',
  'Hex Full': 'Hex Full',
  'Hex Window': 'Hex Window',
  'Graphic Tee': 'Graphic Tee',
  Hoodie: 'Hoodie',
};

const projectSettings = {
  'artomator-sandbox': {
    defaultBucket: 'artomator-sandbox.appspot.com',
    customAssetBucket: 'custom-assets-sandbox',
    apiEndpoint: 'https://us-central1-artomator-sandbox.cloudfunctions.net/api',
    artStorage:
      'https://console.firebase.google.com/u/0/project/artomator-sandbox/storage/artomator-sandbox.appspot.com/files/~2Fart',
    environmentName: 'sandbox',
  },
  'artomator-test': {
    defaultBucket: 'artomator-test.appspot.com',
    customAssetBucket: 'custom-assets-test',
    // apiEndpoint: 'https://us-central1-artomator-test.cloudfunctions.net/api',
    apiEndpoint: 'http://127.0.0.1:5003/artomator-test/us-central1/api',
    artStorage:
      'https://console.firebase.google.com/u/0/project/artomator-test/storage/artomator-test.appspot.com/files/~2FupdatedArt2024/',
    environmentName: 'dev',
  },
  'artomator-stage': {
    defaultBucket: 'artomator-stage.appspot.com',
    customAssetBucket: 'custom-assets-stage',
    apiEndpoint: 'https://us-central1-artomator-stage.cloudfunctions.net/api',
    artStorage:
      'https://console.firebase.google.com/u/0/project/artomator-stage/storage/artomator-stage.appspot.com/files/~2FupdatedArt2024/',
    environmentName: 'stage',
  },
  'rock-em-test': {
    environmentName: 'stage',
    defaultBucket: 'rock-em-test.appspot.com',
    customAssetBucket: 'custom-assets',
    apiEndpoint: 'https://us-central1-rock-em-test.cloudfunctions.net/api',
    artStorage:
      'https://console.firebase.google.com/u/0/project/rock-em-test/storage/rock-em-test.appspot.com/files/~2Fart',
    environmentName: 'stage',
  },
  'rock-em-print-file-creator': {
    defaultBucket: 'rock-em-print-file-creator.appspot.com',
    customAssetBucket: 'rock-em-custom-assets',
    apiEndpoint:
      'https://us-central1-rock-em-print-file-creator.cloudfunctions.net/api',
    // apiEndpoint: 'http://127.0.0.1:5003/rock-em-print-file-creator/us-central1/api' ,
    artStorage:
      'https://console.firebase.google.com/u/0/project/rock-em-print-file-creator/storage/rock-em-print-file-creator.appspot.com/files',
    environmentName: 'production',
  },
  'a2-migration': {
    defaultBucket: 'a2-migration.appspot.com',
    customAssetBucket: 'custom-assets',
    // apiEndpoint: 'http://127.0.0.1:5003/a2-migration/us-central1/api',
    apiEndpoint: 'https://us-central1-a2-migration.cloudfunctions.net/api',
    artStorage:
      'https://console.firebase.google.com/u/0/project/a2-migration/storage/a2-migration.appspot.com/files',
    environmentName: 'production',
  },
};

// ! Using ngrok Example: !
// https://d036-97-68-149-10.ngrok-free.app/artomator-test/us-central1/api/newOrder
//-------------------

const storageBucket = projectSettings[GCP_PROJECT_ID].defaultBucket;

const environmentName = projectSettings[GCP_PROJECT_ID].environmentName;

const customAssetBucket = `gs://${projectSettings[GCP_PROJECT_ID].customAssetBucket}`;
const storageLink = projectSettings[GCP_PROJECT_ID].artStorage;

const API = {
  downloadAttachable: `${projectSettings[GCP_PROJECT_ID].apiEndpoint}/downloadAttachable`,
  downloadHistory: `${projectSettings[GCP_PROJECT_ID].apiEndpoint}/downloadBatch`,
  downloadJobSheet: `${projectSettings[GCP_PROJECT_ID].apiEndpoint}/downloadJobSheet`,
  downloadMockup: `${projectSettings[GCP_PROJECT_ID].apiEndpoint}/downloadMockup`,
  downloadUnderwearMockup: `${projectSettings[GCP_PROJECT_ID].apiEndpoint}/downloadUnderwearMockup`,
  fetchEstimate: `${projectSettings[GCP_PROJECT_ID].apiEndpoint}/fetchEstimate`,
  shipOrder: `${projectSettings[GCP_PROJECT_ID].apiEndpoint}/shipOrder`,
  printInlineOrders: `${projectSettings[GCP_PROJECT_ID].apiEndpoint}/printInlineOrders`,
  createUsers: `${projectSettings[GCP_PROJECT_ID].apiEndpoint}/createUsers`,
  resetUserPassword: `${projectSettings[GCP_PROJECT_ID].apiEndpoint}/resetUserPassword`,
  getWholeSaleOrders: `${projectSettings[GCP_PROJECT_ID].apiEndpoint}/getWholeSaleOrders`,
  updateStatusViaApi: `${projectSettings[GCP_PROJECT_ID].apiEndpoint}/updateStatusViaApi`,
  getUpdatedQbOrder: `${projectSettings[GCP_PROJECT_ID].apiEndpoint}/getUpdatedQbOrder`,
  getInlineAndProcess: `${projectSettings[GCP_PROJECT_ID].apiEndpoint}/getInlineAndProcess`,
  getHistoryAndProcess: `${projectSettings[GCP_PROJECT_ID].apiEndpoint}/getHistoryAndProcess`,
  getBundlesCsv: `${projectSettings[GCP_PROJECT_ID].apiEndpoint}/getBundlesCsv`,
  getBundles: `${projectSettings[GCP_PROJECT_ID].apiEndpoint}/getBundles`,
  retrieveAListOfOrdersAndCreate: `${projectSettings[GCP_PROJECT_ID].apiEndpoint}/retrieveAListOfOrdersAndCreate`,
  getAnalytics: `${projectSettings[GCP_PROJECT_ID].apiEndpoint}/getAnalytics`,
  getShippedBydate: `${projectSettings[GCP_PROJECT_ID].apiEndpoint}/getShippedBydate`,
  getAwaitingShipment: `${projectSettings[GCP_PROJECT_ID].apiEndpoint}/getAwaitingShipment`,
  getProducedData: `${projectSettings[GCP_PROJECT_ID].apiEndpoint}/getProducedData`,
  checkForMissingMocks: `${projectSettings[GCP_PROJECT_ID].apiEndpoint}/checkForMissingMocks`,
  sendClientSideMock: `${projectSettings[GCP_PROJECT_ID].apiEndpoint}/sendClientSideMock`,
  createSingleThumbnail: `${projectSettings[GCP_PROJECT_ID].apiEndpoint}/createSingleThumbnail`,
  createDropshipOrders: `${projectSettings[GCP_PROJECT_ID].apiEndpoint}/createDropshipOrders`,
  printDsInline: `${projectSettings[GCP_PROJECT_ID].apiEndpoint}/printDsInline`,
  editOrderShipstation: `${projectSettings[GCP_PROJECT_ID].apiEndpoint}/editOrderShipstation`,
  getLabelFolderForDs: `${projectSettings[GCP_PROJECT_ID].apiEndpoint}/getLabelFolderForDs`,
  generateAutoNamesApi: `${projectSettings[GCP_PROJECT_ID].apiEndpoint}/generateAutoNamesApi`,
  updateIfUsedName: `${projectSettings[GCP_PROJECT_ID].apiEndpoint}/updateIfUsedName`,
  getOrderInfoByTrack: `${projectSettings[GCP_PROJECT_ID].apiEndpoint}/getOrderInfoByTrack`,
  reSendBatch: `${projectSettings[GCP_PROJECT_ID].apiEndpoint}/reSendBatch`,
};

const PRODUCT_ART_PREFIX =
  GCP_PROJECT_ID === 'a2-migration' ? '' : 'updatedArt2024/';
const PRODUCT_THUMB_PREFIX = 'thumb/';
const PRODUCT_MOCK_PREFIX = 'mockup/';
const BOXSET_MOCK_PREFIX = 'boxset/';
const BARCODE_PREFIX = 'barcode/';

const StoragePath = {
  Barcode: 'barcode',
  Art: GCP_PROJECT_ID === 'a2-migration' ? '' : 'updatedArt2024/',
  Mockup: 'mockup',
  Thumb: 'thumb',
};

const BARCODE_DIMENSION = {
  width: 638,
  height: 300,
};

const UserPermissions = [
  {
    name: 'Admin',
    val: 'Admin',
    view: [
      'custom',
      'inline',
      'wholesale',
      'manual',
      'running',
      'history',
      'products',
      'bundles',
      'product',
      'bundle',
      'formats',
      'templates',
      'uploads',
      'tags',
      'timestamp',
      'inventory',
      'barcodes',
      'users',
    ],
    edit: [
      'custom',
      'inline',
      'wholesale',
      'manual',
      'running',
      'history',
      'products',
      'bundles',
      'product',
      'bundle',
      'formats',
      'templates',
      'tags',
      'inventory',
      'barcodes',
      'users',
    ],
  },
  {
    name: 'Customer Service',
    val: 'Customer Service',
    view: ['timestamp'],
    edit: [],
  },
  {
    name: 'Product Manager',
    val: 'Product Manager',
    view: ['products', 'product', 'bundle', 'bundles', 'tags', 'uploads'],
    edit: ['products', 'product', 'bundle', 'bundles', 'tags'],
  },
  {
    name: 'Print Admin',
    val: 'Print Admin',
    view: [
      'custom',
      'inline',
      'wholesale',
      'manual',
      'running',
      'product',
      'products',
      'bundle',
      'bundles',
      'history',
      'tags',
      'inventory',
      'barcodes',
      'users',
      'timestamp',
      'uploads',
    ],
    edit: [
      'custom',
      'inline',
      'wholesale',
      'manual',
      'running',
      'product',
      'products',
      'bundle',
      'bundles',
      'history',
      'tags',
      'inventory',
      'barcodes',
      'users',
    ],
  },
  {
    name: 'Print Manager',
    val: 'Print Manager',
    view: [
      'custom',
      'inline',
      'wholesale',
      'manual',
      'running',
      'history',
      'product',
      'products',
      'bundle',
      'bundles',
      'uploads',
      'tags',
      'timestamp',
      'inventory',
      'barcodes',
    ],
    edit: [
      'custom',
      'inline',
      'wholesale',
      'manual',
      'running',
      'products',
      'bundles',
      'history',
      'tags',
      'inventory',
      'barcodes',
    ],
  },
  {
    name: 'Product Admin',
    val: 'Product Admin',
    view: [
      'custom',
      'manual',
      'history',
      'products',
      'product',
      'bundle',
      'bundles',
      'uploads',
      'templates',
      'tags',
      'timestamp',
      'running',
    ],
    edit: [
      'custom',
      'manual',
      'history',
      'products',
      'product',
      'bundles',
      'bundle',
      'templates',
      'tags',
      'running',
    ],
  },
];

const HYPEROPTIC_PREFIX = {
  preStretch: 'pre-stretch',
  left: 'left',
  right: 'right',
};

const TYPE_OF_PRODUCT = {
  SOCKS: 'socks',
  MASKS: 'masks',
  UNDERWEAR: 'underwear',
  ADD_ON: 'Add-On',
  TSHIRTL: 'T-Shirt',
  HOODIE: 'Hoodie',
};

const ART_TYPE = {
  LEGACY: 'legacy',
  SOLO: 'solo',
  MODERN: 'modern',
};

const ERROR_TYPE = {
  MISSING_ART: 'missing art',
  PRODUCT_NOT_FOUND: 'product not found',
};

const ADDITIONAL_STYLES = {
  CUT_AND_SEW: 'Cut & Sew',
  KNITTED: 'Knitted',
  PACKAGING: 'Packaging',
  TSHIRT: 'Graphic Tee',
  HOODIE: 'Hoodie',
  ACCESSORIES: 'Accessories',
};

const excludedStyles = ['HyperOptic', 'Cut & Sew', 'Packaging', 'Knitted'];

export {
  GCP_PROJECT_ID,
  collections,
  orderStatus,
  orderTypes,
  environmentName,
  API,
  storageLink,
  customAssetBucket,
  PRODUCT_ART_PREFIX,
  PRODUCT_THUMB_PREFIX,
  PRODUCT_MOCK_PREFIX,
  BOXSET_MOCK_PREFIX,
  BARCODE_PREFIX,
  SIZES,
  TYPES,
  STYLES,
  StoragePath,
  BARCODE_DIMENSION,
  UserPermissions,
  HYPEROPTIC_PREFIX,
  TYPE_OF_PRODUCT,
  ART_TYPE,
  ADDITIONAL_STYLES,
  ERROR_TYPE,
  excludedStyles,
  storageBucket,
};

// test push
