import * as React from 'react';

const BrasSvg = (props) => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width={45} // Decrease this value
    height={45} // Decrease this value
    viewBox="0 0 150 150" // Keep this the same for proper scaling
    enableBackground="new 0 0 141.732 141.732"
    xmlSpace="preserve"
    {...props}
  >
    <defs>
      <style>{'\n      .cls-1 {\n        display: none;\n      }\n    '}</style>
    </defs>
    <g>
      <g id="Layer_1" className="cls-1">
        <path
          d="M111.9,27.7H29.9L13.5,114.1h53.1l4.2-22.3,4.2,22.3h53.1l-16.3-86.4ZM21.8,107.2l12.2-64.7h20.7v23.7c0,7.7,3,14.9,8.4,20.4l1.4,1.4-3.6,19.2s-39.1,0-39.1,0ZM66,82.7c-4.2-4.5-6.6-10.3-6.6-16.5v-23.7h22.9v23.7c0,6.2-2.4,12-6.6,16.5h-9.8ZM77.2,88l1.4-1.4c5.4-5.5,8.4-12.7,8.4-20.4v-23.7h20.6l12.2,64.7h-39.1l-3.6-19.1Z"
          fill={props.color ? props.color : 'white'}
        />
        <path d="M436.1-12.2" fill={props.color ? props.color : 'white'} />
      </g>
      <g id="Layer_2" className="cls-1">
        <path
          d="M109.9,34.6H31.8s-17.6,57.8-17.6,57.8l52.3,14.7,4.4-11.2,4.4,11.2,52.3-14.7-17.6-57.8ZM74.7,86.6h-7.7l-4.8,12.1-39.3-11.1,11.8-38.5h72.4s11.8,38.5,11.8,38.5l-39.3,11.1-4.8-12.1Z"
          fill={props.color ? props.color : 'white'}
        />
      </g>
      <g id="Layer_3">
        <path
          d="M125.3,82.2c-9.9-7.5-15.8-19-16.3-31.4l-.8-19.5-17.7-6.5-5.1,31.1c-.7,4.3-3.2,8-7,10.3-4.7,2.8-10.5,2.8-15.2,0-3.7-2.2-6.3-6-7-10.3l-5.1-31.1-17.7,6.5-.8,19.5c-.5,12.4-6.4,23.9-16.3,31.4l-1.8,1.4,7.3,33.3h97.9l7.3-33.3-1.8-1.4ZM115.7,102.8H26l-3.6-16.5c10.5-8.8,16.7-21.5,17.3-35.3l.6-14.8,5.4-2,3.7,22.7c1,6.3,4.8,11.8,10.3,15.1,6.9,4.1,15.5,4.1,22.4,0,5.5-3.3,9.2-8.8,10.3-15.1l3.7-22.7,5.4,2,.6,14.8c.5,13.8,6.8,26.5,17.3,35.3l-3.6,16.5Z"
          fill={props.color ? props.color : 'white'}
        />
      </g>
    </g>
  </svg>
);
export default BrasSvg;
