import React, { useState } from 'react';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import _ from 'lodash';

import { changeFormatName } from '../../utils/changeFormatName';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getcontentanchorel={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    minWidth: '120px',
    '&:focus': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
      color: '#000000',
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: '#000000',
      },
    },
  },
}))(MenuItem);

export default function FilterDropdown(props) {
  const disabled = props.disabled || false;
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleItemClick = (item_id) => {
    setAnchorEl(null);
    props.onSelect(item_id);
  };

  const items = [...props.items];
  let activeItem = _.find(items, (item) => item.id === props.active);
  if (!activeItem) {
    // select ALL item
    activeItem = items[0];
    props.onSelect(activeItem.id);
  }

  // Apply formatting to activeItem.name
  const formattedActiveItemName = changeFormatName(
    activeItem.name,
    'Boxer',
    "Men's Boxer Briefs",
  );

  let heading = formattedActiveItemName; // Use formatted name
  if (props.title !== '') {
    heading = `${props.title}: ${formattedActiveItemName}`;
  } // Use formatted name in heading

  return (
    <Box>
      <Button
        aria-controls="customized-menu"
        aria-haspopup="true"
        onClick={handleClick}
        endIcon={Boolean(anchorEl) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        disabled={disabled}
      >
        {heading}
      </Button>
      <StyledMenu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {items.map((item) => (
          <StyledMenuItem
            selected={item.id === activeItem.id}
            key={`Filter-${activeItem.name}-${item.id}`}
            onClick={() => handleItemClick(item.id)}
          >
            <ListItemText
              primary={changeFormatName(
                item.name,
                'Boxer',
                "Men's Boxer Briefs",
              )}
            />
          </StyledMenuItem>
        ))}
      </StyledMenu>
    </Box>
  );
}
