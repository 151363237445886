export const DEFAULT_PRODUCT_CHECKERS = {
  hasSocks: false,
  hasBoxers: false,
  hasCustoms: false,
  hasSportsBras: false,
  hasBoyShorts: false,
  hasYouthBoxers: false,
};

export const UNDERWEAR_PRODUCT_CHECKERS = {
  hasBoxers: false,
  hasBoyShorts: false,
  hasSportsBras: false,
  hasYouthBoxers: false,
};

export const SOCK_PRODUCT_CHECKERS = {
  hasSocks: false,
  hasCustoms: false,
};
