import sortAndBatchOrders from './sortingFunction';

const findAndProcessUnderwearOnly = async (
  orders,
  isWebQueue = false,
  db,
  sizes,
  formats,
  type,
  isBelt,
) => {
  // look for orders that have underwear in them
  const underwearOrders = orders.filter((order) =>
    order.items.every((item) => item?.type?.toLowerCase() === 'underwear'),
  );

  const rows = sortAndBatchOrders(
    underwearOrders,
    isWebQueue,
    true,
    db,
    sizes,
    formats,
    type,
    isBelt,
  );

  return rows;
};

export default findAndProcessUnderwearOnly;
