import {
  adjustForBothShipments,
  dataSetToAdjust,
  processWhatToSet,
} from './helpers';
import {
  INJECT_ANALYTICS,
  UPDATE_SELECTED_TYPE_OF_PRODUCT,
  SORT_DATA_BASED_ON_FILTER,
  UPDATE_SELECTED_DATE,
  UPDATE_SELECTED_PRODUCT,
  INJECT_SHIPMENT_ANALYTICS,
  UPDATE_SELECTED_DATASET_FOR_SHIPPING,
  SORT_SHIPMENT_DATA,
  SET_LOADING,
  SET_OF_ITEMS_WITH_ERRORS,
  SET_PRODUCED_ANALYTICS,
  SET_SHIPPED_YESTERDAY_ANALYTICS,
  INJECT_UNSHIPPED_GRAPH_DATA,
  SET_MAIN_DROPDOWN_PRODUCT,
} from './types';

const initialState = {
  units: [],
  originalUnits: [],
  timedAnalytics: [],
  produced: {
    selectedProduct: 'socks',
    selectedTypeOfProduct: 'shopify',
  },
  toBeProduced: {
    selectedProduct: 'socks',
    selectedTypeOfProduct: 'shopify',
  },
  originalShipmentData: [],
  shipmentData: [],
  unshippedDataForGraph: [],
  toBeShipped: {
    orderType: 'dtc',
  },
  shipped: {
    orderType: 'dtc',
  },
  isLoading: true,
  allArtomatorOrders: {},
  itemsWithErrors: [],
  shippedYesterday: [],
  mainDropDownProduct: 'DTC',
};

const analyticsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SHIPPED_YESTERDAY_ANALYTICS:
      return {
        ...state,
        shipmentData: state.shipmentData.map((item) =>
          item.value === 'shipped' ? action.payload.shipped : item,
        ),
      };
    case SET_OF_ITEMS_WITH_ERRORS:
      return {
        ...state,
        itemsWithErrors: action.payload,
      };

    case SET_PRODUCED_ANALYTICS:
      const deepClonedUnits = _.cloneDeep(state.originalUnits);

      const newData = action.payload;

      // find in units the value of produced and replace that object with the new data
      const dataToUpdate = deepClonedUnits.map((unit) => {
        if (unit.value === 'produced') {
          console.log('Found the produced data');
          unit.data = [newData];
        }
        return unit;
      });

      const selectionsUpdated = {
        selectedProduct: 'socks',
        selectedTypeOfProduct: 'shopify',
      };

      const updatedUnits = dataSetToAdjust(
        dataToUpdate,
        selectionsUpdated,
        'produced',
        true,
      );

      // get current units and replace the object with the new data
      const oldUnits = _.cloneDeep(state.units);

      const indexOfProduced = oldUnits.findIndex(
        (unit) => unit.value === 'produced',
      );

      oldUnits[indexOfProduced] = updatedUnits;

      return {
        ...state,
        originalUnits: dataToUpdate,
        units: oldUnits,
      };

    case INJECT_SHIPMENT_ANALYTICS:
      const { data: shippingAnayltic } = action.payload;
      const orderTypeToBeShipped = state.toBeShipped.orderType;

      const orderTypeShipped = state.shipped.orderType;

      const shipmentDataAdjusted = adjustForBothShipments(
        shippingAnayltic,
        orderTypeToBeShipped,
        orderTypeShipped,
      );

      return {
        ...state,
        originalShipmentData: shippingAnayltic,
        shipmentData: shipmentDataAdjusted,
      };

    case SORT_SHIPMENT_DATA:
      const orderTypeToBeShippedUpdate = state.toBeShipped.orderType;

      const orderTypeShippedUpdate = state.shipped.orderType;

      const adjustedData = adjustForBothShipments(
        state.originalShipmentData,
        orderTypeToBeShippedUpdate,
        orderTypeShippedUpdate,
      );

      return {
        ...state,
        shipmentData: adjustedData,
      };

    case UPDATE_SELECTED_DATASET_FOR_SHIPPING:
      const { option, value } = action.payload;

      const orderType = value === 'shipped' ? 'shipped' : 'toBeShipped';

      const objectToReturnOrderType = {
        [orderType]: {
          ...state[orderType],
          orderType: option,
        },
      };

      return {
        ...state,
        ...objectToReturnOrderType,
      };

    case INJECT_ANALYTICS:
      const { typeOfAnalytic, data } = action.payload;

      const { selectedProduct, selectedDate, selectedTypeOfProduct } =
        state.produced;

      const selections = {
        selectedProduct,
        selectedDate,
        selectedTypeOfProduct,
      };

      const stateUpdate = processWhatToSet(data, typeOfAnalytic, selections);

      return {
        ...state,
        ...stateUpdate,
      };

    case INJECT_UNSHIPPED_GRAPH_DATA:
      const { data: unshippedData } = action.payload;

      return {
        ...state,
        unshippedDataForGraph: unshippedData,
      };

    case SET_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case UPDATE_SELECTED_PRODUCT:
      const arrayChosenProduct =
        action.payload.value === 'produced' ? 'produced' : 'toBeProduced';

      const newSelectedProduct = action.payload.option;

      const objectToReturnProduct = {
        [arrayChosenProduct]: {
          ...state[arrayChosenProduct],
          selectedProduct: newSelectedProduct,
        },
      };

      return {
        ...state,
        ...objectToReturnProduct,
      };

    case UPDATE_SELECTED_TYPE_OF_PRODUCT:
      const arrayChosen =
        action.payload.value === 'produced' ? 'produced' : 'toBeProduced';

      const selectedTypeOfProd = action.payload.option;

      const objectToReturn = {
        [arrayChosen]: {
          ...state[arrayChosen],
          selectedTypeOfProduct: selectedTypeOfProd,
        },
      };

      return {
        ...state,
        ...objectToReturn,
      };

    case UPDATE_SELECTED_DATE:
      const arrayChosenDate =
        action.payload.value === 'produced' ? 'produced' : 'toBeProduced';

      const singleSelectedDate = action.payload.option;

      const objectToReturnDate = {
        [arrayChosenDate]: {
          ...state[arrayChosenDate],
          selectedDate: singleSelectedDate,
        },
      };

      return {
        ...state,
        ...objectToReturnDate,
      };

    case SORT_DATA_BASED_ON_FILTER:
      // Dynamically access either 'produced' or 'toBeProduced'
      const dataSetToUse = state[action.payload];

      const nameOfDataSet =
        action.payload === 'produced' ? 'produced' : 'toBeProduced';

      const newSelections = {
        selectedProduct: dataSetToUse.selectedProduct,
        selectedDate: dataSetToUse.selectedDate,
        selectedTypeOfProduct:
          dataSetToUse.selectedTypeOfProduct === 'dtc'
            ? 'shopify'
            : dataSetToUse.selectedTypeOfProduct,
      };

      const dataToUse = dataSetToAdjust(
        state.originalUnits,
        newSelections,
        nameOfDataSet,
      );
      // in the units array replace the object with the new data, find it by using the nameOfDataSet which is the value

      const clonedUnits = [...state.units];

      const indexOfDataSet = clonedUnits.findIndex(
        (unit) => unit.value === nameOfDataSet,
      );

      clonedUnits[indexOfDataSet] = dataToUse;

      return {
        ...state,
        units: clonedUnits,
      };
    case SET_MAIN_DROPDOWN_PRODUCT:
      return {
        ...state,
        mainDropDownProduct: action.payload,
      };
    default:
      return state;
  }
};

export default analyticsReducer;
