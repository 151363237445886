import React, { useMemo } from 'react';
import {
  Modal,
  Box,
  Typography,
  Divider,
  Paper,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Fade,
  Backdrop,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import { FixedSizeList as List } from 'react-window'; // Virtualization

export const MicrobatchedOrders = React.memo(
  ({ microbatchedOrders, open, onClose, onProcessOrders }) => {
    // Render order with cumulative total
    const renderOrder = ({ index, style, data }) => {
      const order = data[index];
      const quantity = order.isBoxSet
        ? order.boxSetItems * order.quantity
        : order.quantity;
      let runningTotal = 0;

      // Update running total for the current batch
      data.slice(0, index + 1).forEach((prevOrder) => {
        runningTotal += prevOrder.isBoxSet
          ? prevOrder.boxSetItems * prevOrder.quantity
          : prevOrder.quantity;
      });

      return (
        <Box
          key={index}
          sx={{
            display: 'flex',
            bgcolor: index % 2 === 0 ? 'grey.50' : 'grey.100',
            p: 1.5,
            borderRadius: 2,
            boxShadow: 1,
            gap: 2,
          }}
          style={style}
        >
          <Inventory2OutlinedIcon color="primary" sx={{ fontSize: 30 }} />
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={3}>
              <Typography variant="body2">
                <strong>Order #:</strong> {order.orderNumber}
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography variant="body2">
                <strong>SKU:</strong> {order.sku}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="body2">
                <strong>Qty:</strong> {order.quantity}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="body2">
                <strong>Total:</strong> {runningTotal}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      );
    };

    const uniqueOrders = useMemo(
      () =>
        microbatchedOrders.map((batchType) =>
          batchType.batches.map((batch) => [
            ...new Set(batch.map((order) => order.orderNumber)),
          ]),
        ),
      [microbatchedOrders],
    );

    return (
      <Modal
        open={open}
        onClose={onClose}
        BackdropComponent={(props) => (
          <Backdrop {...props} transitionDuration={500} />
        )}
      >
        <Fade in={open} timeout={400}>
          <Box sx={styles.modal}>
            <Typography variant="h4" sx={styles.header}>
              Smart <span style={styles.gradientText}>Batched Orders</span>
            </Typography>

            {microbatchedOrders.map((batchType, typeIndex) => (
              <Box key={typeIndex} sx={{ mb: 2 }}>
                <Typography variant="h5" sx={styles.subHeader}>
                  {batchType.type}
                </Typography>

                {batchType.batches.length === 0 ? (
                  <Typography
                    variant="body1"
                    color="text.secondary"
                    sx={{ pl: 2 }}
                  >
                    No batches available
                  </Typography>
                ) : (
                  batchType.batches.map((batch, batchIndex) => {
                    const numOrders =
                      uniqueOrders[typeIndex][batchIndex].length;

                    const totalItems = batch.reduce(
                      (acc, order) =>
                        acc +
                        (order.isBoxSet
                          ? order.boxSetItems * order.quantity
                          : order.quantity),
                      0,
                    );

                    return (
                      <Accordion key={batchIndex} sx={styles.accordion}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          sx={styles.accordionSummary}
                        >
                          <Box sx={styles.batchSummary}>
                            <Typography
                              variant="subtitle1"
                              sx={styles.batchTitle}
                            >
                              Batch {batchIndex + 1}
                            </Typography>
                            <Typography variant="body2">
                              {totalItems} items
                            </Typography>
                            <Typography variant="body2">
                              {numOrders} orders
                            </Typography>
                          </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Paper sx={styles.batchPaper}>
                            {/* Headers */}
                            <Grid
                              container
                              spacing={2}
                              alignItems="center"
                              sx={{ px: 1, py: 1 }}
                            >
                              <Grid item xs={3}>
                                <Typography variant="body2" fontWeight="bold">
                                  Order #
                                </Typography>
                              </Grid>
                              <Grid item xs={5}>
                                <Typography variant="body2" fontWeight="bold">
                                  SKU
                                </Typography>
                              </Grid>
                              <Grid item xs={2}>
                                <Typography variant="body2" fontWeight="bold">
                                  Qty
                                </Typography>
                              </Grid>
                              <Grid item xs={2}>
                                <Typography variant="body2" fontWeight="bold">
                                  Total
                                </Typography>
                              </Grid>
                            </Grid>
                            <Divider />

                            <List
                              height={650} // Adjust height as needed
                              itemCount={batch.length}
                              itemSize={50} // Approximate height of each item
                              itemData={batch}
                              width="100%"
                              style={{ overflowX: 'hidden' }}
                            >
                              {renderOrder}
                            </List>
                            <Divider sx={{ my: 2 }} />
                            <Button
                              variant="contained"
                              sx={styles.processButton}
                              onClick={() => onProcessOrders(batch)}
                            >
                              Process Orders
                            </Button>
                          </Paper>
                        </AccordionDetails>
                      </Accordion>
                    );
                  })
                )}
              </Box>
            ))}
          </Box>
        </Fade>
      </Modal>
    );
  },
);

const styles = {
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '75%',
    height: '85%',
    bgcolor: 'background.paper',
    border: '1px solid #ddd',
    boxShadow: 24,
    overflowY: 'auto',
    p: 4,
    borderRadius: 4,
  },
  header: {
    mb: 3,
    fontWeight: 'bold',
    color: 'primary.main',
    textAlign: 'center',
  },
  gradientText: {
    background: 'linear-gradient(90deg, #3b82f6, #a78bfa)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  },
  subHeader: {
    mb: 1,
    fontWeight: 'medium',
    color: 'text.secondary',
    textAlign: 'center',
  },
  batchSummary: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
    gap: 2,
  },
  batchTitle: {
    fontWeight: 'bold',
  },
  batchPaper: {
    p: 2,
    backgroundColor: 'background.default',
    borderRadius: 3,
    boxShadow: 3,
    transition: 'transform 0.3s ease-in-out',
    '&:hover': {
      transform: 'scale(1.02)',
    },
  },
  accordion: {
    boxShadow: 1,
    borderRadius: 2,
    mb: 2,
    backgroundImage: 'linear-gradient(to right, #f7f9fc, #e3edf7)',
    '&:before': {
      display: 'none',
    },
  },
  accordionSummary: {
    '&.Mui-expanded': {
      bgcolor: 'grey.200',
    },
  },
  processButton: {
    mt: 2,
    py: 1,
    px: 4,
    fontWeight: 'bold',
    background: 'linear-gradient(135deg, #3b82f6, #2563eb)',
    color: '#fff',
    borderRadius: 4,
    boxShadow: '0px 4px 10px rgba(59, 130, 246, 0.3)',
    transition: 'all 0.2s ease',
    '&:hover': {
      background: 'linear-gradient(135deg, #2563eb, #1e40af)',
      transform: 'scale(1.05)',
    },
  },
};
