/* eslint-disable max-lines */
import {
  collection,
  query,
  where,
  getDocs,
  orderBy,
  doc,
  addDoc,
  getDoc,
  onSnapshot,
  writeBatch,
} from 'firebase/firestore';
import _ from 'lodash';

import { collections, orderStatus } from '../constants/defines';
import { itemsChecked } from '../utils/updateItemIfSaysMissingArt';
import { getBySku } from './products';
import { getOldestOrderDate } from './helpers';

const getCustomOrders = (db, fetchData, storage) => {
  const q = query(
    collection(db, collections.CUSTOM_PRODUCTS_QUEUE),
    where('isReadyToPrint', '==', true),
    where('isPrinting', '==', false),
    where('isCanceled', '==', false),
    where('fulfill', '==', 'unfulfilled'),
    orderBy('created_at'),
  );

  onSnapshot(q, async (querySnapshot) => {
    const apiQueuePromises = querySnapshot.docs
      .filter((doc) => doc.id !== 'CountForOrderStatusType')
      .map(async (doc) => {
        const data = doc.data();

        const firstInitial = data.customer.address.first_name
          ? data.customer.address.first_name.charAt(0) + '.'
          : '';
        const lastName = data.customer.address.last_name || '';
        const customerName = _.trim(`${firstInitial} ${lastName}`);
        const shipping = data.shipping ? data.shipping : '';
        let cntMasks = 0,
          cntSocks = 0,
          cntUnderwear = 0;
        let numCustomItems = 0,
          numItems = 0;

        const updatedItemsPromises = data.items.map(async (_item) => {
          if (_item.type === 'Underwear') {
            cntUnderwear++;
          } else if (_item.isMask) {
            cntMasks++;
          } else {
            cntSocks++;
          }

          if (_item.isCustomProduct) {
            numCustomItems += parseInt(_item.quantity);
          }

          numItems += parseInt(_item.quantity);

          // Here we call itemsChecked with the item structured as needed
          const updatedItemResults = await itemsChecked(
            { hits: [{ document: { items: [_item] } }] },
            db,
            storage,
          );
          const updatedItem = updatedItemResults[0].items[0]; // Extract the updated item
          let format = _item.format || '';

          if (format === '' || format === true) {
            // fetch format from storage
            const fetchedObject = await getBySku(db, _item.sku);
            format = fetchedObject.format;
            _item.size = fetchedObject.size;
            _item.style = fetchedObject.style;
            _item.type = fetchedObject.type;
          }

          // Construct and return the new item object
          return {
            id: _item.id,
            type: _item?.type || updatedItem.type || '',
            qty: parseInt(_item.quantity),
            source: _item.source,
            sku: _item.sku,
            format: format,
            boxset: _item.isBoxSet,
            custom: _item.isCustomProduct,
            error: updatedItem.error, // Use the updated error
            exist: updatedItem.exists,
            items: updatedItem?.items || _item.items || [],
            frontPath: _item.print?.frontPath || '',
            backPath: _item.print?.backPath || '',
            size: (_item.print?.frontSize || 0) + (_item.print?.backSize || 0),
            style: updatedItem.style ?? '',
            type: updatedItem.type ?? '',
            available: updatedItem.available,
          };
        });

        const _items = await Promise.all(updatedItemsPromises);

        let type = 'multiple';
        if (cntUnderwear === data.items.length) {
          type = 'underwear';
        } else if (cntMasks === data.items.length) {
          type = 'masks';
        } else if (cntSocks === data.items.length) {
          type = 'socks';
        }

        let orderType = 'OTHER'; // Adjusted from orderTypes.OTHER for simplicity
        if (numItems === 1 && numCustomItems === 1) {
          orderType = 'SINGLE';
        } else if (numItems === numCustomItems) {
          orderType = 'MULTIPLE';
        }
        return {
          date: data.created_at,
          order: data.orderNumber,
          restored: !!data.restoreDate,
          customerName,
          type,
          shipping,
          orderType,
          numItems,
          numCustomItems,
          source: data.source || 'shopify',
          items: _items,
          batchDate: data?.batchDate ?? null,
        };
      });

    const apiQueue = await Promise.all(apiQueuePromises);
    fetchData(apiQueue);
  });
};

const markAsRevision = async (db, orders) => {
  const batch = writeBatch(db);

  // Add deletes to batch
  orders.forEach((order) => {
    console.log('Mark Order as Revision: ', order.order);
    const docRef = doc(
      db,
      collections.CUSTOM_PRODUCTS_QUEUE,
      order.order.toString(),
    );
    const currentUser = localStorage.getItem('currentuser');

    batch.set(
      docRef,
      {
        isApproved: false,
        isReadyToPrint: false,
        isPrinting: false,
        updatedBy: currentUser,
        updatedOn: new Date(),
        status: orderStatus.NEEDSREVISIONS,
      },
      { merge: true },
    );
  });

  batch
    .commit()
    .then(function () {
      console.log('Mark As NeedsRevision Successful');
    })
    .catch(function (error) {
      console.error(`Error comitting batch: ${error.message}`);
    });
};

const markOrdersAsPrinting = async (
  db,
  orders,
  printBatch,
  isDropShip = false,
  source = '',
) => {
  const batch = writeBatch(db);

  // Add deletes to batch
  orders.forEach((order) => {
    let orderNumber;

    if (isDropShip) {
      orderNumber = order.orderNumber;
    } else {
      if (source === 'web') {
        orderNumber = order.orderNumber.toString();
      } else {
        orderNumber = order?.order.toString();
      }
    }

    const useThisCollection = isDropShip
      ? collections.DS_CUSTOM_QUEUE
      : collections.CUSTOM_PRODUCTS_QUEUE;

    const docRef = doc(db, useThisCollection, orderNumber);

    batch.set(
      docRef,
      {
        isPrinting: true,
        batchId: printBatch.batch,
        printedBy: printBatch.email,
        printedOn: new Date(),
      },
      { merge: true },
    );
  });

  batch
    .commit()
    .then(function () {
      console.log('Mark As printed Successful');
    })
    .catch(function (error) {
      console.error(`Error comitting batch: ${error.message}`);
    });
};

const sendCustomPrintBatch = (
  db,
  orders,
  printBatch,
  callback,
  isDropShip = false,
) => {
  console.log('BATCH **CUSTOM : ', printBatch);
  addDoc(collection(db, collections.ORDERS), printBatch)
    .then((docRef) => {
      console.log('Document written with ID: ', docRef.id);
      markOrdersAsPrinting(db, orders, printBatch, isDropShip, 'web');
      callback(printBatch, '');
    })
    .catch((error) => {
      console.error('Error adding document: ', error);
      callback(printBatch, error);
    });
};

const sendInlinePrintBatch = (db, printBatch) => {
  console.log('inlinePrintBatch:', printBatch);
  addDoc(collection(db, collections.ORDERS), printBatch)
    .then((docRef) => {
      console.log('Inline Batch Created');
    })
    .catch((error) => {
      console.error('Error adding document: ', error);
    });
};

// Helper function to process mixed BoxSet items based on SKU suffix
const processMixedBoxSet = async (item, counts, checkers, sizes) => {
  let socksCount = 0;
  let boxersCount = 0;

  // Define suffixes for socks and underwear
  const sockSuffixes = ['LXL', 'SM', 'YTHL', 'TDLR24', 'TDLR12'];
  const underwearSuffixes = ['L', 'S', 'M', 'XL', '2XL', '3XL'];

  await Promise.all(
    item.data.items.map(async (subItemSku) => {
      // Extract size suffix from SKU
      const sizeSuffix = subItemSku.split('-').pop();

      // Determine if the SKU belongs to socks or underwear based on the suffix
      const isSock = sockSuffixes.some((suffix) =>
        subItemSku.endsWith(`-${suffix}`),
      );
      const isUnderwear = underwearSuffixes.some((suffix) =>
        subItemSku.endsWith(`-${suffix}`),
      );

      // Find the size based on SKU suffix in the sizes array
      const size =
        sizes.find((s) => s.sku === sizeSuffix)?.name ||
        item.sku.split('-').pop() ||
        'Unknown Size';

      if (isSock) {
        socksCount++;
        checkers.hasSocks = true;

        // Increment the counts for socks by size
        if (!counts['Socks']) {
          counts['Socks'] = {};
        }
        counts['Socks'][size] = (counts['Socks'][size] || 0) + item.quantity;
      } else if (isUnderwear) {
        boxersCount++;
        checkers.hasBoxers = true;

        // Increment the counts for underwear by size
        if (!counts['Underwear']) {
          counts['Underwear'] = {};
        }
        counts['Underwear'][size] =
          (counts['Underwear'][size] || 0) + item.quantity;
      }
    }),
  );

  // Log counts for debugging
  console.log(
    `BoxSet "${item.sku}" has ${socksCount} socks and ${boxersCount} underwear.`,
  );
};

const getCountsOfItems = (_orders, formats, sizes, excludeInline = false) => {
  const clonedOrders = _.cloneDeep(_orders);
  const orders = clonedOrders.reduce((acc, currentOrder) => {
    const orderExists = acc.find(
      (order) => order.orderNumber === currentOrder.orderNumber,
    );
    if (!orderExists) {
      acc.push(currentOrder);
    }
    return acc;
  }, []);

  const counts = {};

  const checkers = {
    hasSocks: false,
    hasBoxers: false,
    hasCustoms: false, // This will be set to true if a custom product is found
  };

  orders.forEach((order) => {
    order.items.forEach(async (item) => {
      // Check the style of the item, skip it if it's "Graphic" (case insensitive) or if the style is not empty and it's not a box set
      if (item.style?.toLowerCase() !== 'graphic' && !item.isBoxSet) {
        return; // Skip this item
      }

      // Handle Mixed BoxSets separately
      if (item.isBoxSet && item?.data?.type === 'Mixed') {
        await processMixedBoxSet(item, counts, checkers, sizes);
        return;
      }

      // If it's a box set, calculate the total quantity by multiplying the number of items in the box set
      let totalQuantity = item.quantity;
      if (item.isBoxSet && item.data?.items?.length) {
        totalQuantity *= item.data.items.length;
      }

      if (item.isBoxSet && item?.data) {
        item.format = item?.data?.format || null;
        item.size = item?.data?.size || null;
      }

      // **Check for custom products**: If `isCustomProduct` is true, set `hasCustoms` flag to true
      if (item.isCustomProduct) {
        checkers.hasCustoms = true;
      }

      // **First check**: Use the `type` property to detect socks or boxers
      if (item.type?.toLowerCase() === 'socks') {
        checkers.hasSocks = true;
      } else if (item.type?.toLowerCase() === 'underwear') {
        checkers.hasBoxers = true;
      } else {
        // **Fallback check**: If `type` is missing, use the format
        const format = formats.find((f) => f.id === item.format);
        if (format) {
          if (format.type.toLowerCase() === 'socks') {
            checkers.hasSocks = true;
          } else if (format.type.toLowerCase() === 'underwear') {
            checkers.hasBoxers = true;
          }
        }
      }

      // if the item is a custom, remove the true
      if (item.isCustomProduct) {
        checkers.hasSocks = false;
      }

      // Find the format name based on the format ID
      const format = formats.find((f) => f.id === item.format);

      // Find the size name based on the size ID and convert it to a string explicitly
      const size =
        sizes.find((s) => s.id === item.size)?.name ||
        item.sku.split('-').pop() ||
        'Unknown Size';

      if (format && size) {
        // Create a category for the format type if it doesn't exist
        if (!counts[format.name]) {
          counts[format.name] = {};
        }

        // Ensure the size is always treated as a string
        const sizeKey = String(size);

        // Create an entry for the size if it doesn't exist
        if (!counts[format.name][sizeKey]) {
          counts[format.name][sizeKey] = 0;
        }

        // Increment the count for the size under the format
        counts[format.name][sizeKey] += totalQuantity;
      }
    });
  });

  // Log the checkers object for debugging
  console.log('Product type checkers:', checkers);
  console.log('Final counts:', counts);

  return { counts, checkers };
};

const createCustomBatch = (
  db,
  batchName,
  secondaryBatchName,
  color,
  sheetType,
  barcode,
  orders,
  callback,
  isDropShip = false,
  source = '',
  formats,
  sizes,
  notes,
  sockFormat,
  underwearFormat,
  underwearBatchType,
  sockBatchType,
) => {
  const currentUser = localStorage.getItem('currentuser');
  const baseBatchId = Date.now();
  const unifiedBatchId = `${baseBatchId}`;
  const unifiedBatchName = `${batchName}`;

  const unifiedItems = [];
  const processedItems = new Set();

  orders.forEach((order) => {
    console.log('order', order);
    let orderNumber;

    if (!isDropShip) {
      orderNumber =
        source === 'web'
          ? order.orderNumber.toString()
          : order.order.toString();
    } else {
      orderNumber = order.orderNumber;
    }

    const customerName = order.customerName || order.customer;
    let indexItems = 0;

    order.items.forEach((item) => {
      const uniqueItemId = `${orderNumber}-${item.id}`; // Unique identifier for each item
      const quantity = item.qty || item.quantity || 1;

      if (!processedItems.has(uniqueItemId)) {
        let backPath = '';
        let frontPath = '';
        const orderName = customerName;
        if (item.custom || item.isCustomProduct) {
          // Custom item logic
          backPath = isDropShip
            ? (item.print?.backPath ?? '')
            : item.backPath || item.print?.backPath || '';
          frontPath = isDropShip
            ? (item.print?.frontPath ?? '')
            : item.frontPath || item.print?.frontPath || '';
          // patcher for the print file name
          if (frontPath === '' || backPath === '') {
            //  append item.id to the print file name
            frontPath = `orders/${orderNumber}/${item.id}_front_print.png`;
            backPath = `orders/${orderNumber}/${item.id}_back_print.png`;
          }
        }

        unifiedItems.push({
          id: item.id,
          sku: item.sku,
          quantity,
          frontPath,
          backPath,
          error: !item.error ? '' : item.error,
          orderNumber,
          orderName,
          numItems: order.numItems,
          orderType: order.orderType,
          isCustom: item.custom || item.isCustomProduct,
          properties: item.properties ?? false,
        });

        processedItems.add(uniqueItemId); // Mark the item as processed
      }

      indexItems += parseInt(quantity);
    });
  });

  // Build unified batch
  const unifiedBatch = {
    date: new Date(),
    baseName: batchName,
    name: unifiedBatchName,
    source: 'Order',
    batch: unifiedBatchId,
    secondary: secondaryBatchName,
    underwearFormat: '',
    color,
    sheetType,
    printed: false,
    email: currentUser,
    lineItems: unifiedItems,
    priority: 1,
    notes: notes || '',
    sockFormat,
    underwearFormat,
    sockBatchType,
    underwearBatchType,
  };

  const countsOfItems = getCountsOfItems(orders, formats, sizes, true);

  unifiedBatch.totalsForPrinting = countsOfItems.counts;
  unifiedBatch.productTypeCheckers = countsOfItems.checkers;
  unifiedBatch.oldestOrderDate = getOldestOrderDate(orders);

  console.log('unifiedBatch', unifiedBatch);

  // Send unified batch
  sendCustomPrintBatch(db, orders, unifiedBatch, callback, isDropShip);
};

const getCustomOrder = (db, orderNumber, callback) => {
  const q = query(
    collection(db, collections.CUSTOM_PRODUCTS_QUEUE),
    where('orderNumber', '==', parseInt(orderNumber)),
  );

  getDocs(q)
    .then((querySnapshot) => {
      const item = { id: '', exists: false, order: orderNumber, data: null };

      querySnapshot.forEach((doc) => {
        if (doc.exists) {
          const data = doc.data();
          item.id = doc.id;
          item.data = data;
          item.exists = true;
        }
      });
      callback(item);
    })
    .catch((error) => {
      console.log('getProductBySKU: ', error);
      const item = { id: '', exists: false, order: orderNumber, data: null };
      callback(item);
    });
};

const restoreCustomOrders = (db, orders) => {
  const batch = writeBatch(db);
  // Add deletes to batch
  orders.forEach((order) => {
    const ref = doc(db, collections.CUSTOM_PRODUCTS_QUEUE, order.toString());
    batch.set(
      ref,
      { isPrinting: false, fulfill: 'unfulfilled', restoreDate: new Date() },
      { merge: true },
    );
  });

  batch
    .commit()
    .then(function () {
      console.log('restoreCustomOrders Successful');
    })
    .catch(function (error) {
      console.error(`Error comitting batch: ${error.message}`);
    });
};

const restoreCustomOrderItems = (db, items) => {
  const batch = writeBatch(db);
  const requests = _.map(items, (item) =>
    getDoc(doc(db, collections.CUSTOM_PRODUCTS_QUEUE, item.order.toString())),
  );

  Promise.all(requests).then(function (responses) {
    // Scrape resonses for errors
    responses.forEach(function (response, index) {
      if (response.exists) {
        const data = response.data();
        const _order = items[index];
        const orderItems = data.items
          .filter((item) => _order.skus.includes(item.sku))
          .map((item) => ({
            ...item,
            available: true,
          }));
        const ref = doc(
          db,
          collections.CUSTOM_PRODUCTS_QUEUE,
          _order.order.toString(),
        );
        batch.set(
          ref,
          {
            isPrinting: false,
            fulfill: 'unfulfilled',
            items: orderItems,
            restoreDate: new Date(),
          },
          { merge: true },
        );
      }
    });

    batch
      .commit()
      .then(function () {
        console.log('restoreCustomOrderItems Successful');
      })
      .catch(function (error) {
        console.error(`Error comitting batch: ${error.message}`);
      });
  });
};

const checkExistingCustomOrder = (db, name) =>
  new Promise((resolve, reject) => {
    const q = query(
      collection(db, collections.ORDERS),
      where('baseName', '==', name),
    );

    return getDocs(q)
      .then((querySnapshot) => {
        resolve(querySnapshot.docs.length > 0);
      })
      .catch((error) => {
        resolve(false);
      });
  });

export {
  getCustomOrder,
  getCustomOrders,
  markAsRevision,
  createCustomBatch,
  restoreCustomOrders,
  restoreCustomOrderItems,
  checkExistingCustomOrder,
};
