import { doc, runTransaction } from 'firebase/firestore';

import { collections } from '../constants/defines';
import { getRandomWord, saveBatchName } from '../db/randomWordsGen';

export const getUniqueBatchName = async (setUniqueName, setBatchName, db) => {
  try {
    const newBatchName = await getRandomWord(db);
    console.log('newBatchName', newBatchName);
    setUniqueName(newBatchName);
    setBatchName(newBatchName);
  } catch (error) {
    console.error('Error fetching unique batch name: ', error);
  }
};

export const saveNameIfUsed = async (name, db) => {
  try {
    const batchNamingRef = doc(db, collections.SETTINGS, 'batchNaming');

    const savedName = await runTransaction(db, async (transaction) => {
      const currentLetterDoc = await transaction.get(batchNamingRef);

      if (!currentLetterDoc.exists()) {
        throw new Error('batchNaming document does not exist');
      }

      const letter = currentLetterDoc.data().currentLetter;

      // Determine the next letter
      const nextLetter =
        letter === 'Z' ? 'A' : String.fromCharCode(letter.charCodeAt(0) + 1);

      // Update the letter in the transaction
      transaction.update(batchNamingRef, { currentLetter: nextLetter });

      // Save the batch name (if needed)
      const savedName = await saveBatchName(db, name);

      return savedName;
    });

    return savedName;
  } catch (error) {
    console.error('Error saving batch name in transaction: ', error);
    throw error; // Rethrow if you need to handle this upstream
  }
};
