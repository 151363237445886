import { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import { Autocomplete } from '@mui/material';

import { withAuthorization } from '../Session';
import ProductsSearch from '../Page/productsSearch';
import { addProducts } from '../../db/inventory';
import { EnhancedTableHead } from '../Page/table';
import RouteLeavingGuard from '../Page/routeLeavingGuard';

const headCells = [
  {
    id: 'first',
    numeric: true,
    align: 'left',
    disablePadding: true,
    label: '',
    width: '40px',
  },
  {
    id: 'qty',
    numeric: true,
    align: 'left',
    disablePadding: false,
    label: 'QTY',
    width: '80px',
  },
  {
    id: 'sku',
    numeric: false,
    align: 'left',
    disablePadding: false,
    label: 'SKU',
    width: 'auto',
  },
  {
    id: 'location',
    numeric: false,
    align: 'left',
    disablePadding: false,
    label: 'LOCATION',
    width: '120px',
  },
  {
    id: 'remove',
    numeric: false,
    align: 'left',
    disablePadding: false,
    label: '',
    width: '50px',
  },
  {
    id: 'last',
    numeric: false,
    align: 'left',
    disablePadding: true,
    label: '',
    width: '20px',
  },
];

function InventoryDrawer(props) {
  const { classes, onClose, onComplete, history, locations, setItems, items } =
    props;

  const [adding, setAdding] = useState(false);

  const handleChangeQty = (event, index) => {
    const qty = parseInt(event.target.value);
    const _products = [...items];
    if (qty === 0) {
      _products.splice(index, 1);
    } else {
      _products[index].quantity = qty;
    }
    setItems(_products);
  };

  const handleChangeLocation = (event, newValue, index) => {
    const _products = [...items];
    _products[index].location = newValue?.toUpperCase() || '';
    setItems(_products);
  };

  const handleComplete = () => {
    if (onComplete) {
      onComplete();
    }
    setAdding(false);
  };

  const handleAddProducts = () => {
    console.log('handleAddProducts');
    const blankItems = _.filter(items, (item) => item.location === '');
    if (blankItems.length > 0) {
      return;
    }

    setAdding(true);
    addProducts(db, items, handleComplete, locations);
  };

  const handleRemoveItem = (event, index) => {
    const _products = [...items];
    _products.splice(index, 1);

    setItems(_products);
  };

  const handleSelectProduct = async (newValue) => {
    const subProducts = [];
    if (newValue.isBoxset) {
      // no longer need to get sub products for boxsets

      const product = {
        sku: newValue.sku,
        name: newValue.name,
        quantity: 1,
        parent: '',
        location: '',
      };

      subProducts.push(product);
    } else {
      const product = {
        sku: newValue.sku,
        name: newValue.name,
        quantity: 1,
        parent: '',
        location: '',
      };
      subProducts.push(product);
    }

    const products = [...items];
    for (let i = 0; i < subProducts.length; i++) {
      const item = subProducts[i];
      const matchedItem = _.find(products, { sku: item.sku });
      if (matchedItem) {
        matchedItem.quantity = matchedItem.quantity + item.quantity;
        matchedItem.parent = item.parent;
      } else {
        products.push({ ...item });
      }
    }

    setItems(products);
  };

  const db = props.firebase.getdb();

  return (
    <Box>
      <Box className={classes.drawerHeader}>
        <Box className={classes.pageHeading}>
          <Typography variant="h1">Add Inventory</Typography>
        </Box>
        <Box className={classes.drawerClose} onClick={onClose}>
          <CloseIcon />
        </Box>

        <Box className={classes.searchBox}>
          <Typography className={classes.sectionHeading}>
            Add Product
          </Typography>
          <ProductsSearch
            db={db}
            onSelect={handleSelectProduct}
            singlesInventoryBypass={true}
          />
        </Box>
      </Box>

      <TableContainer sx={{ marginTop: '30px', marginBottom: '50px' }}>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size="medium"
          aria-label="enhanced table"
        >
          <EnhancedTableHead
            classes={classes}
            useCheckbox={false}
            cells={headCells}
          />
          <TableBody>
            {items.map((row, index) => {
              const keyId = `key-cart-${row.sku}-${index}`;
              const odd = index % 2 === 1;
              return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={keyId}
                  className={odd ? classes.tableRow : classes.tableRow2}
                >
                  <TableCell></TableCell>
                  <TableCell
                    align="left"
                    className={
                      row.exists ? classes.tableCell : classes.tableCellError
                    }
                  >
                    <TextField
                      type="number"
                      value={row.quantity}
                      variant="outlined"
                      className={classes.inputQty}
                      onChange={(event) => handleChangeQty(event, index)}
                    />
                  </TableCell>
                  <TableCell align="left" className={classes.tableCell}>
                    {row.sku}
                  </TableCell>
                  <TableCell align="left" className={classes.tableCell}>
                    <Autocomplete
                      freeSolo
                      options={[...locations].sort((a, b) =>
                        a
                          .replace(/\s/g, '')
                          .localeCompare(b.replace(/\s/g, '')),
                      )} // Sort while ignoring spaces
                      getOptionLabel={(option) => option}
                      value={row.location || ''}
                      filterOptions={(options, { inputValue }) => {
                        if (inputValue.length > 0) {
                          // Filter based on input after the 2nd character
                          return options.filter((option) =>
                            option
                              .toLowerCase()
                              .startsWith(inputValue.toLowerCase()),
                          );
                        }
                        return [];
                      }}
                      onInputChange={(event, newValue) =>
                        handleChangeLocation(event, newValue, index)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          className={classes.inputQty}
                        />
                      )}
                    />
                  </TableCell>
                  <TableCell align="right" className={classes.tableCell}>
                    <Button onClick={(event) => handleRemoveItem(event, index)}>
                      <DeleteOutlineIcon />
                    </Button>
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              );
            })}

            {items.length === 0 && (
              <TableRow>
                <TableCell align="center" colSpan={5}>
                  <Typography className={classes.tableCell}>
                    No Items{' '}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Box className={classes.drawerBottom}>
        <Box sx={{ marginTop: '40px' }}>
          <Button
            variant="outlined"
            classes={{
              root: classes.batchButton,
              disabled: classes.disabledBatchButton,
            }}
            onClick={handleAddProducts}
            disabled={items.length === 0 || adding}
          >
            Add Products
          </Button>
        </Box>
      </Box>

      <RouteLeavingGuard
        when={items.length}
        navigate={(path) => {
          history.push(path);
        }}
        shouldBlockNavigation={() => items.length > 0}
      />
    </Box>
  );
}

const condition = (authUser) => !!authUser;
export default withAuthorization(condition)(InventoryDrawer);
