import { convertToRows as dsQueueConvertToRows } from '../../components/DsQueue/helpers';
import { getCountsOfItems } from '../../components/SmartBatch/helpers';
import { convertToRows as webQueueConvertToRows } from '../../components/WebQueues/helpers';

const getMaxUnitsPerBatch = (isUnderwearOnly, isBelt, isMixed = false) => {
  if (isMixed) {
    return 60; // Mixed batches always have 60 units.
  }

  if (isBelt) {
    return isUnderwearOnly ? 9 : 42; // Belt-specific logic.
  }

  return isUnderwearOnly ? 9 : 60; // Non-belt logic.
};

/**
 * Sorts and batches orders into groups based on the maximum units per batch.
 * Converts these batches into rows suitable for different queue types.
 *
 * @param {Array} orders - List of order objects to be batched.
 * @param {boolean} isWebQueue - Flag to determine if the orders are for a web queue.
 * @param {boolean} isUnderwearOnly - Flag to determine if the orders are underwear only, affecting batch size.
 * @returns {Array} - Array of rows representing the batched orders.
 */
const sortAndBatchOrders = async (
  orders,
  isWebQueue = false,
  isUnderwearOnly = false,
  db,
  sizes,
  formats,
  type,
  isBelt,
  handleMixed = false,
  excludeFinishedGoods = false,
) => {
  if (handleMixed) {
    return sortAndBatchMixedOrders(
      orders,
      db,
      sizes,
      formats,
      type,
      isWebQueue,
    );
  }

  const maxUnitsPerBatch = getMaxUnitsPerBatch(isUnderwearOnly, isBelt);

  const batches = [];
  const seenOrders = new Set();

  const calculateOrderUnits = (order) =>
    order.items.reduce((sum, item) => {
      if (
        excludeFinishedGoods &&
        item?.style?.toLowerCase() !== 'graphic' &&
        item?.style?.toLowerCase() !== ''
      ) {
        return sum; // Skip non-graphic items if excludeFinishedGoods is true
      }
      const itemCount = item.quantity || 0;
      const boxSetMultiplier =
        item.isBoxSet && item.data?.items?.length ? item.data.items.length : 1;
      return sum + itemCount * boxSetMultiplier;
    }, 0);

  const sortOrders = (orders) =>
    orders.sort((a, b) => {
      const ageDifference = a.created_at.seconds - b.created_at.seconds;
      if (ageDifference !== 0) {
        return ageDifference;
      }
      return calculateOrderUnits(b) - calculateOrderUnits(a);
    });

  const multiItemOrders = sortOrders(
    orders.filter((order) => calculateOrderUnits(order) > 1),
  ).sort((a, b) => calculateOrderUnits(b) - calculateOrderUnits(a));
  const singleItemOrders = sortOrders(
    orders.filter((order) => calculateOrderUnits(order) === 1),
  );

  const findBestFit = (orders, target) => {
    for (let i = 0; i < orders.length; i++) {
      const order = orders[i];
      const orderUnits = calculateOrderUnits(order);
      if (orderUnits <= target && !seenOrders.has(order.orderNumber)) {
        seenOrders.add(order.orderNumber);
        return orders.splice(i, 1)[0];
      }
    }
    return null;
  };

  while (multiItemOrders.length > 0 || singleItemOrders.length > 0) {
    const currentBatch = [];
    let currentUnits = 0;
    let iterationCount = 0; // Add iteration counter

    // Fill with multi-item orders
    while (
      multiItemOrders.length > 0 &&
      currentUnits + calculateOrderUnits(multiItemOrders[0]) <= maxUnitsPerBatch
    ) {
      if (iterationCount++ > 100) {
        break;
      } // Limit to avoid infinite loops
      const order = multiItemOrders.shift();
      if (!seenOrders.has(order.orderNumber)) {
        currentBatch.push(order);
        seenOrders.add(order.orderNumber);
        currentUnits += calculateOrderUnits(order);
      }
    }

    // Fill remaining space with best-fit multi-item or single-item orders
    while (
      currentUnits < maxUnitsPerBatch &&
      (multiItemOrders.length > 0 || singleItemOrders.length > 0)
    ) {
      const neededUnits = maxUnitsPerBatch - currentUnits;
      let bestFitOrder = findBestFit(multiItemOrders, neededUnits);

      if (!bestFitOrder) {
        bestFitOrder = findBestFit(singleItemOrders, neededUnits);
      }

      if (bestFitOrder) {
        currentBatch.push(bestFitOrder);
        currentUnits += calculateOrderUnits(bestFitOrder);
      } else {
        break; // No suitable order, end the batch
      }

      // Exit condition if no progress is made in the current loop
      if (iterationCount++ > 100) {
        console.warn('Exiting loop due to no progress made.');
        break;
      }
    }

    // Add the completed batch if it has orders
    if (currentBatch.length > 0) {
      batches.push(currentBatch);
    } else {
      break; // Exit if no batch was formed, preventing infinite loops
    }
  }

  // Convert batches to rows based on the queue type
  const rows = await Promise.all(
    batches.map(async (batch) => {
      const metadata = await getCountsOfItems(
        batch,
        sizes,
        formats,
        db,
        isBelt,
        type,
        isWebQueue,
      );
      const batchRows = isWebQueue
        ? webQueueConvertToRows(batch, false)
        : dsQueueConvertToRows(batch, false);
      return {
        rows: batchRows,
        products: metadata.checkers,
        sheetCount: metadata.sheetCount,
        type,
        style: type === 'finishedGoods' ? 'Fulfillment' : 'Shuttle',
        oldestOrder: metadata.oldestOrderDate,
      };
    }),
  );

  return rows;
};

const sortAndBatchMixedOrders = async (
  _orders,
  isWebQueue = false,
  db,
  sizes,
  formats,
  type,
  isBelt,
) => {
  const orders = filterOutOrdersWithItemsThatAreNotGraphic(_orders);
  const maxUnderwearUnits = 6; // Target underwear units per batch
  const maxSockUnits = 14; // Target sock units per batch

  const ordersWithUnits = calculateOrderUnitsForOrders(orders);

  const batches = createBatches(
    ordersWithUnits,
    maxUnderwearUnits,
    maxSockUnits,
  );

  // for each batch replace the object with the matching orderNumber from _orders
  const batchesWithOrders = batches.map((batch) =>
    batch.map((order) =>
      orders.find((o) => o.orderNumber === order.orderNumber),
    ),
  );

  // Convert batches to rows based on the queue type
  const rows = await Promise.all(
    batchesWithOrders.map(async (batch) => {
      const metadata = await getCountsOfItems(
        batch,
        sizes,
        formats,
        db,
        isBelt,
        type,
        isWebQueue,
      );
      const batchRows = isWebQueue
        ? webQueueConvertToRows(batch, false)
        : dsQueueConvertToRows(batch, false);
      const returnableBatch = {
        rows: batchRows,
        products: metadata.checkers,
        sheetCount: metadata.sheetCount,
        type,
        style: type === 'finishedGoods' ? 'Fulfillment' : 'Shuttle',
        oldestOrder: metadata.oldestOrderDate,
      };
      return returnableBatch;
    }),
  );

  return rows;
};

const createBatches = (orders, maxUnderwearUnits = 6, maxSockUnits = 14) => {
  const batches = [];
  let remainingOrders = [...orders];

  while (remainingOrders.length > 0) {
    const currentBatch = [];
    let currentTotals = { underwear: 0, socks: 0 };
    const ordersToRemove = new Set();

    // Sort remaining orders by highest units first to try to fill batches faster
    remainingOrders.sort(
      (a, b) => b.underwear + b.socks - (a.underwear + a.socks),
    );

    // Try to build a batch
    for (let i = 0; i < remainingOrders.length; i++) {
      const order = remainingOrders[i];
      const newTotals = {
        underwear: currentTotals.underwear + order.underwear,
        socks: currentTotals.socks + order.socks,
      };

      // Check if adding this order would exceed our limits
      if (
        newTotals.underwear <= maxUnderwearUnits &&
        newTotals.socks <= maxSockUnits
      ) {
        currentBatch.push(order);
        currentTotals = newTotals;
        ordersToRemove.add(i);

        // If we've hit our targets exactly, we're done with this batch
        if (
          currentTotals.underwear === maxUnderwearUnits &&
          currentTotals.socks === maxSockUnits
        ) {
          break;
        }
      }
    }

    // If we found a valid batch, add it and remove used orders
    if (
      currentTotals.underwear === maxUnderwearUnits &&
      currentTotals.socks === maxSockUnits
    ) {
      batches.push(currentBatch);
      remainingOrders = remainingOrders.filter(
        (_, index) => !ordersToRemove.has(index),
      );
    } else {
      // If we couldn't make a valid batch, remove the largest order and try again
      remainingOrders.shift();
    }

    // Break if we can't make any more progress
    if (
      remainingOrders.length === 0 ||
      (currentBatch.length === 0 && remainingOrders.length > 0)
    ) {
      break;
    }
  }

  return batches;
};

const filterOutOrdersWithItemsThatAreNotGraphic = (orders) =>
  orders.filter((order) =>
    order.items.every((item) => item?.style?.toLowerCase() === 'graphic'),
  );

const calculateOrderUnitsForOrders = (orders) =>
  orders.map((order) => {
    let socks = 0;
    let underwear = 0;
    const skus = [];
    if (order.orderNumber === 'DS1022093468') {
      console.log('order', order);
    }
    order.items.forEach((item) => {
      const quantity = item.quantity || 1;
      const boxSetMultiplier =
        item.isBoxSet && item?.items?.length ? item?.items?.length : 1;

      if (item.type?.toLowerCase().includes('socks')) {
        socks += quantity * boxSetMultiplier;
      } else if (item.type?.toLowerCase().includes('underwear')) {
        underwear += quantity * boxSetMultiplier;
      } else if (item.type?.toLowerCase().includes('mixed')) {
        socks += quantity;
        underwear += quantity;
      }
      skus.push(item.sku);
    });

    return {
      orderNumber: order.orderNumber,
      underwear,
      socks,
      skus,
    };
  });

export default sortAndBatchOrders;
