/* eslint-disable max-lines */
/* eslint-disable max-params */
import {
  collection,
  query,
  orderBy,
  addDoc,
  onSnapshot,
  doc,
  updateDoc,
  arrayUnion,
  deleteDoc,
  getDoc,
} from 'firebase/firestore';

import { collections } from '../constants/defines';

const transformTextToFirstCharCapital = (text) => {
  if (!text) {
    return '';
  }
  if (text.toLowerCase().includes('hyperoptic')) {
    return 'HyperOptic';
  }
  return text
    .split('-')
    .map((segment) =>
      segment
        .split(' ')
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
        )
        .join(' '),
    )
    .join('-');
};

const getTypes = (db, limit, callback) => {
  const q = query(collection(db, collections.TYPES), orderBy('name'));

  onSnapshot(q, (querySnapshot) => {
    const items = [];
    querySnapshot.forEach((doc) => {
      items.push({ ...doc.data(), id: doc.id });
    });
    callback(items);
  });
};

const createType = (db, item) => {
  const newItem = { ...item };
  const currentTime = Date();
  newItem.created = currentTime;
  newItem.styles = [];
  // make name uppercase
  newItem.name = newItem.name;
  return new Promise((resolve, reject) => {
    addDoc(collection(db, collections.TYPES), newItem)
      .then((docRef) => {
        console.log('New Type created with ID: ', docRef.id);
        resolve({ id: docRef.id, ...newItem });
      })
      .catch((error) => {
        console.error('Error adding Type: ', error);
        reject(error);
      });
  });
};

const newTyleObject = (name) => ({
  styleName: name,
  formats: [],
});

const createStyle = (db, typeId, newStyle) =>
  new Promise((resolve, reject) => {
    console.log('creating style', typeId, newStyle);
    // this only pushes to the styles array in the type document
    const typeRef = doc(db, collections.TYPES, typeId);
    const newStyleObject = newTyleObject(newStyle);
    updateDoc(typeRef, {
      styles: arrayUnion(newStyleObject),
    });
    resolve();
    reject();
  });

const createNewFormatInFormatCollectionsByType = async (db, typeName, name) => {
  const objectToMake = {
    name: name,
    type: typeName,
  };

  return new Promise((resolve, reject) => {
    addDoc(collection(db, collections.FORMATS), objectToMake)
      .then((docRef) => {
        console.log('New Type created with ID: ', docRef.id);
        resolve({ id: docRef.id, ...objectToMake });
      })
      .catch((error) => {
        console.error('Error adding Type: ', error);
        reject(error);
      });
  });
};

const createFormat = async (
  db,
  selectedType,
  existingFormats,
  selectedStyle,
  newFormat,
) => {
  const formatIndex = existingFormats.findIndex(
    (format) => format.name === newFormat,
  );

  let buildFormatPushableObject;

  if (formatIndex !== -1) {
    console.log('format already exists, skipping...');
    buildFormatPushableObject = existingFormats[formatIndex];
  } else {
    console.log('format does NOT! exist, creating...');
    buildFormatPushableObject = await createNewFormatInFormatCollectionsByType(
      db,
      selectedType?.name,
      newFormat,
    );
  }

  // now proceed with adding into the type document
  const typeRef = doc(db, collections.TYPES, selectedType.id);
  const typeDoc = await getDoc(typeRef);
  const styles = typeDoc.data().styles;

  // frpm selectedStyle.name find the styles.name that matches
  const styleIndex = styles.findIndex(
    (style) => style.styleName === selectedStyle.styleName,
  );

  if (styleIndex !== -1) {
    // if no formats in the style, create an empty array
    if (!styles[styleIndex].formats) {
      styles[styleIndex].formats = [];
    }
    styles[styleIndex].formats.push(buildFormatPushableObject);
    await updateDoc(typeRef, { styles });
  }
  console.log('buildFormatPushableObject: ', buildFormatPushableObject);
};

const deleteType = async (db, typeId) => {
  try {
    console.log('deleting type', typeId);
    const typeRef = doc(db, collections.TYPES, typeId);
    return await deleteDoc(typeRef);
  } catch (error) {
    console.error('Error deleting Type: ', error);
    return false;
  }
};

const deleteStyle = async (db, typeId, { styleName }) => {
  try {
    console.log('deleting style', typeId, styleName);
    const typeRef = doc(db, collections.TYPES, typeId);
    const typeDoc = await getDoc(typeRef);
    const styles = typeDoc.data().styles;
    const styleIndex = styles.findIndex(
      (style) => style.styleName === styleName,
    );
    if (styleIndex !== -1) {
      styles.splice(styleIndex, 1);
    }
    await updateDoc(typeRef, { styles });
  } catch (error) {
    console.error('Error deleting Style: ', error);
    return false;
  }
};

const editStyle = async (db, typeId, oldStyle, newStyle) => {
  const typeRef = doc(db, collections.TYPES, typeId);
  const typeDoc = await getDoc(typeRef);
  const styles = typeDoc.data().styles;
  const styleIndex = styles.findIndex(
    (style) => style.styleName === oldStyle.styleName,
  );
  if (styleIndex !== -1) {
    styles[styleIndex] = {
      ...styles[styleIndex],
      styleName: transformTextToFirstCharCapital(newStyle),
    };
  }
  console.log('styles', styles);
  await updateDoc(typeRef, { styles });
};

const editType = async (db, typeId, newName) => {
  const typeRef = doc(db, collections.TYPES, typeId);
  await updateDoc(typeRef, { name: newName });
};

const createNewSizeInSizeCollectionsByType = async (db, typeName, name) => {
  const objectToMake = {
    name: name,
    type: typeName,
    sku: name,
  };
  return new Promise((resolve, reject) => {
    addDoc(collection(db, collections.SIZES), objectToMake)
      .then((docRef) => {
        console.log('New Size created with ID: ', docRef.id);
        resolve({ id: docRef.id, ...objectToMake });
      })
      .catch((error) => {
        console.error('Error adding Size: ', error);
        reject(error);
      });
  });
};

// Helper to find or create size
const findOrCreateSize = async (db, type, sizes, item) => {
  // only uppercase name/sku
  const upperItem = item.toUpperCase();
  const existingSize = sizes.find(
    (s) =>
      (s.name === upperItem || s.sku === upperItem) && s.type === type.name,
  );
  return (
    existingSize ||
    (await createNewSizeInSizeCollectionsByType(db, type.name, upperItem))
  );
};

const findOrCreateSizeInFormat = async (db, type, format, item) => {
  const upperItem = transformTextToFirstCharCapital(item);
  const existingSize = format.find(
    (s) => s.name === upperItem && s.type === type.name,
  );
  console.log('existingSize', existingSize?.name);
  return (
    existingSize ||
    (await createNewFormatInFormatCollectionsByType(db, type.name, upperItem))
  );
};

// Helper to update nested format sizes
const updateFormatSizes = async (db, typeId, style, format, size) => {
  const typeRef = doc(db, collections.TYPES, typeId);
  const typeDoc = await getDoc(typeRef);
  const styles = typeDoc.data().styles;

  const styleIndex = styles.findIndex((s) => s.styleName === style.styleName);
  if (styleIndex === -1) {
    return;
  }

  const formatIndex = styles[styleIndex].formats.findIndex(
    (f) => f.name === format.name,
  );
  if (formatIndex === -1) {
    return;
  }

  const targetFormat = styles[styleIndex].formats[formatIndex];
  targetFormat.sizes = targetFormat.sizes || [];
  targetFormat.sizes.push(size);

  await updateDoc(typeRef, { styles });
};

// Main function
const addSizeToFormat = async (
  db,
  selectedType,
  sizes,
  selectedStyle,
  selectedFormat,
  item,
) => {
  const size = await findOrCreateSize(db, selectedType, sizes, item);
  await updateFormatSizes(
    db,
    selectedType.id,
    selectedStyle,
    selectedFormat,
    size,
  );
};

const deleteSizeFromFormat = async (db, typeId, style, format, size) => {
  console.log('deleting size from format', typeId, size.id);
  const typeRef = doc(db, collections.TYPES, typeId);
  const typeDoc = await getDoc(typeRef);
  const styles = typeDoc.data().styles;

  // Find style
  const styleIndex = styles.findIndex((s) => s.styleName === style.styleName);
  if (styleIndex !== -1) {
    // Find format
    const formatIndex = styles[styleIndex].formats.findIndex(
      (f) => f.id === format.id,
    );
    if (formatIndex !== -1) {
      // Filter out the size
      styles[styleIndex].formats[formatIndex].sizes = styles[
        styleIndex
      ].formats[formatIndex].sizes.filter((s) => s.id !== size.id);
    }
  }
  await updateDoc(typeRef, { styles });
};

const editSize = async (
  db,
  typeId,
  selectedStyle,
  selectedFormat,
  item,
  newName,
  sizes,
) => {
  const typeRef = doc(db, collections.TYPES, typeId);
  const typeDoc = await getDoc(typeRef);
  const styles = typeDoc.data().styles;

  // Find style index
  const styleIndex = styles.findIndex(
    (s) => s.styleName === selectedStyle.styleName,
  );
  if (styleIndex !== -1) {
    // Find format index
    const formatIndex = styles[styleIndex].formats.findIndex(
      (f) => f.id === selectedFormat.id,
    );
    if (formatIndex !== -1) {
      // Find or create size
      const newSize = await findOrCreateSize(
        db,
        selectedFormat,
        sizes,
        newName,
      );

      // Update existing size with new data
      const sizeIndex = styles[styleIndex].formats[formatIndex].sizes.findIndex(
        (s) => s.id === item.id,
      );

      if (sizeIndex !== -1) {
        styles[styleIndex].formats[formatIndex].sizes[sizeIndex] = {
          ...newSize,
          id: newSize.id || item.id,
        };
        console.log(
          'Saving new size',
          styles[styleIndex].formats[formatIndex].sizes[sizeIndex],
        );
      }

      await updateDoc(typeRef, { styles });
    }
  }
};

const deleteFormatFromType = async (db, typeId, style, format) => {
  console.log('deleting format from type', typeId, format.id);
  const typeRef = doc(db, collections.TYPES, typeId);
  const typeDoc = await getDoc(typeRef);
  const styles = typeDoc.data().styles;

  // Find style
  const styleIndex = styles.findIndex((s) => s.styleName === style.styleName);
  if (styleIndex !== -1) {
    // Find format
    const formatIndex = styles[styleIndex].formats.findIndex(
      (f) => f.id === format.id,
    );
    if (formatIndex !== -1) {
      styles[styleIndex].formats.splice(formatIndex, 1);
    }
  }
  await updateDoc(typeRef, { styles });
};

const editFormat = async (
  db,
  typeId,
  style,
  format,
  newName,
  formats,
  selectedType,
) => {
  const typeRef = doc(db, collections.TYPES, typeId);
  const typeDoc = await getDoc(typeRef);
  const styles = typeDoc.data().styles;
  const styleIndex = styles.findIndex((s) => s.styleName === style.styleName);
  if (styleIndex !== -1) {
    // Find or create size
    const newFormat = await findOrCreateSizeInFormat(
      db,
      selectedType,
      formats,
      newName,
    );

    const formatIndex = styles[styleIndex].formats.findIndex(
      (f) => f.id === format.id,
    );
    if (formatIndex !== -1) {
      styles[styleIndex].formats[formatIndex] = {
        ...newFormat,
        id: newFormat.id || format.id,
      };

      console.log('Format pop: ', styles[styleIndex].formats[formatIndex]);
    }
  }

  await updateDoc(typeRef, { styles });
};

export {
  getTypes,
  createType,
  createStyle,
  deleteType,
  deleteStyle,
  editStyle,
  editType,
  createFormat,
  transformTextToFirstCharCapital,
  addSizeToFormat,
  deleteSizeFromFormat,
  editSize,
  deleteFormatFromType,
  editFormat,
};
