import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import axios from 'axios';
import { ref, getDownloadURL } from 'firebase/storage';
import Pica from 'pica';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

import {
  PRODUCT_ART_PREFIX,
  PRODUCT_MOCK_PREFIX,
  BOXSET_MOCK_PREFIX,
  PRODUCT_THUMB_PREFIX,
} from '../../constants/defines';
import { cropSoloImg } from '../../module/functions';
import {
  getMatchedCropSettings,
  getMatchedCropSettingByFront,
  getMatchedCropSettingByBack,
} from '../../db/cropSettings';
import transparentImg from '../../images/transparent.jpg';
import blankMockupImg from '../../images/blankMockup.png';

const styleForIsLowCut5Pack = {
  height: '18vh',
  width: '18vh',
};

function SoloArt(props) {
  const { file, path, cropSettings, isFront, classes, name, firebase } = props;
  const [url, setUrl] = useState(null);
  const [ratio, setRatio] = useState(1);
  useEffect(() => {
    if (file) {
      console.log('SoloArt file');
      const fr = new FileReader();
      fr.onload = function () {
        const img = new Image();
        img.onload = function () {
          processImage(img);
        };
        img.src = fr.result;
      };
      fr.readAsDataURL(file);
    }
  }, [file]);

  useEffect(() => {
    if (path !== '') {
      const storage = firebase.getstorage();
      getDownloadURL(ref(storage, `${PRODUCT_ART_PREFIX}${path}`))
        .then((url) => {
          axios.get(url, { responseType: 'arraybuffer' }).then((response) => {
            const base64 = btoa(
              new Uint8Array(response.data).reduce(
                (data, byte) => data + String.fromCharCode(byte),
                '',
              ),
            );

            const img = new Image();
            img.onload = function () {
              processImage(img);
            };
            img.src = `data:image/png;base64, ${base64}`;
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [path]);

  const processImage = (art) => {
    const soloCropSetting = isFront
      ? getMatchedCropSettingByFront(cropSettings, art.width, art.height)
      : getMatchedCropSettingByBack(cropSettings, art.width, art.height);
    if (soloCropSetting) {
      const canvas = cropSoloImg(
        art,
        isFront ? soloCropSetting.frontArea : soloCropSetting.backArea,
      );
      setRatio(
        isFront
          ? (soloCropSetting.frontArea.width * 2) /
              soloCropSetting.frontArea.height
          : (soloCropSetting.backArea.width * 2) /
              soloCropSetting.backArea.height,
      );
      setUrl(canvas.toDataURL('png'));
    }
  };

  return (
    <Box className={classes.imageBox}>
      <Box className={classes.coverImage}>
        {url && (
          <img
            key={name}
            src={url}
            className={ratio > 1 ? classes.hImage : classes.vImage}
          />
        )}
      </Box>
    </Box>
  );
}

const IMAGE_CACHE_SOLO_ART = {}; // Cache object to store fetched images

function SoloArtBundle(props) {
  const {
    path,
    cropSettings,
    isFront,
    classes,
    name,
    firebase,
    isMixedBoxSet,
    isUnderWearOnly,
    isLowAnd5Pck,
  } = props;
  const [url, setUrl] = useState(null);
  const [ratio, setRatio] = useState(1);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (path !== '' && !IMAGE_CACHE_SOLO_ART[path]) {
      const storage = firebase.getstorage();

      const pathForURL = `${PRODUCT_ART_PREFIX}${path}`;

      getDownloadURL(ref(storage, pathForURL))
        .then((url) => {
          axios.get(url, { responseType: 'arraybuffer' }).then((response) => {
            const base64 = btoa(
              new Uint8Array(response.data).reduce(
                (data, byte) => data + String.fromCharCode(byte),
                '',
              ),
            );

            const img = new Image();
            img.onload = function () {
              processImage(img);
            };
            img.src = `data:image/png;base64, ${base64}`;
            // Store fetched image in cache
            IMAGE_CACHE_SOLO_ART[path] = img;
            setLoading(false);
          });
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } else if (IMAGE_CACHE_SOLO_ART[path]) {
      // If image is available in cache, use it directly
      processImage(IMAGE_CACHE_SOLO_ART[path]);
    }
  }, [path]);

  const processImage = (art) => {
    const soloCropSetting = isFront
      ? getMatchedCropSettingByFront(cropSettings, art.width, art.height)
      : getMatchedCropSettingByBack(cropSettings, art.width, art.height);
    if (soloCropSetting) {
      const canvas = cropSoloImg(
        art,
        isFront ? soloCropSetting.frontArea : soloCropSetting.backArea,
      );
      setRatio(
        isFront
          ? (soloCropSetting.frontArea.width * 2) /
              soloCropSetting.frontArea.height
          : (soloCropSetting.backArea.width * 2) /
              soloCropSetting.backArea.height,
      );
      setUrl(canvas.toDataURL('png'));
    }
    setLoading(false);
  };

  if (!path || path === '') {
    return (
      <Box className={classes.imageBox}>
        <Box className={classes.coverImage}>
          <img src={transparentImg} className={classes.hImage} />
        </Box>
      </Box>
    );
  }

  return (
    <Box className={classes.imageBox}>
      {loading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '30vh',
            width: '8vw',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box className={classes.coverImage}>
          {url && !isMixedBoxSet ? (
            <img
              key={name}
              src={url}
              style={
                isLowAnd5Pck
                  ? styleForIsLowCut5Pack
                  : {
                      height: '30vh',
                    }
              }
            />
          ) : (
            <img
              key={name}
              src={url}
              style={{
                height: isMixedBoxSet ? '35vh' : 'auto',
                width: isMixedBoxSet ? '20vh' : 'auto',
              }}
            />
          )}
        </Box>
      )}
    </Box>
  );
}

/* Local Image Preview */
function FileArt(props) {
  const { file, classes, name, progress, setDimension } = props;
  const [img, setImg] = useState(null);
  const [ratio, setRatio] = useState(1);

  useEffect(() => {
    if (file) {
      const fr = new FileReader();
      fr.onload = function () {
        const img = new Image();
        img.onload = function () {
          setImg(img);
          setRatio(img.width / img.height);
          if (setDimension) {
            setDimension({ width: img.width, height: img.height });
          }
        };
        img.src = fr.result;
      };
      fr.readAsDataURL(file);
    } else {
      setImg(null);
    }
  }, [file]);

  const imgSrc = img ? img.src : '';
  return (
    <Box className={classes.imageBox}>
      {progress < 100 && (
        <LinearProgress
          variant="determinate"
          value={progress}
          color="primary"
          sx={{ width: '100%' }}
        />
      )}
      <Box className={classes.coverImage}>
        {img ? (
          <img
            key={name}
            src={imgSrc}
            className={ratio > 1 ? classes.hImage : classes.vImage}
          />
        ) : (
          <CircularProgress />
        )}
      </Box>
    </Box>
  );
}

/* Storage Image Preview */
function ProductArt(props) {
  const {
    firebase,
    path,
    progress,
    classes,
    name,
    setDoesHaveImage,
    doNotContainImage,
    isUnderwear,
  } = props;
  const [imagePath, setImagePath] = useState('');
  const [ratio, setRatio] = useState(1);
  const [load, setLoad] = useState(true);

  const isMockup = name === 'mockup';
  useEffect(() => {
    setLoad(true);

    if (path === '') {
      setLoad(false);
    } else {
      const prefix = isMockup ? PRODUCT_MOCK_PREFIX : PRODUCT_ART_PREFIX;
      const storage = firebase.getstorage();
      getDownloadURL(ref(storage, `${prefix}${path}`))
        .then((url) => {
          const img = new Image();
          img.onload = function () {
            setRatio(this.width / this.height);
            setLoad(false);
          };
          img.src = url;
          if (!img) {
            setDoesHaveImage(false);
          }
          setImagePath(url);
        })
        .catch((error) => {
          console.log('Error in ProductArt:', error);
          setLoad(false);
          setDoesHaveImage(false);
        });

      // if (props.dontShow) setDoesHaveImage(false);
    }
  }, [props.path]);

  return (
    <Box
      sx={{
        height: doNotContainImage ? 'auto' : '100%',
        width: '100%',
      }}
    >
      {progress < 100 && (
        <LinearProgress
          variant="determinate"
          value={progress}
          color="primary"
          sx={{ width: '100%' }}
        />
      )}
      <Box className={classes.coverImage}>
        {progress >= 100 && load ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: isUnderwear ? '30vh' : '100%',
              width: isUnderwear ? '30vh' : '100%',
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <img
            key={name}
            src={imagePath ? imagePath : blankMockupImg}
            loading="lazy"
            className={ratio > 1 ? classes.hImage : classes.vImage}
          />
        )}
      </Box>
    </Box>
  );
}

const IMAGE_CACHE_PRODUCT_ART = {}; // Cache object to store fetched images

function ProductArtBundle(props) {
  const {
    firebase,
    path,
    classes,
    name,
    isLowAnd5Pck,
    doNotContainImage,
    isUnderWearOnly,
    isNotFound,
    boxedPath,
  } = props;

  let _path = path;

  const isBoxedImage = boxedPath !== '';

  if (isBoxedImage) {
    _path = boxedPath;
  }

  const [imagePath, setImagePath] = useState('');
  const [load, setLoad] = useState(true);
  const pica = Pica({ features: ['js', 'wasm', 'ww'] });
  const [thumbnailPath, setThumbnailPath] = useState('');

  useEffect(() => {
    if (_path === '') {
      setLoad(false);
      return;
    }

    setLoad(true);
    const fullPrefix =
      name === 'mockup' ? PRODUCT_MOCK_PREFIX : PRODUCT_ART_PREFIX;
    const fullURL = `${fullPrefix}${_path}`;
    const storage = firebase.getstorage();

    if (isUnderWearOnly) {
      const thumbnailPrefix = PRODUCT_THUMB_PREFIX; // Define thumbnail prefix inside conditional
      const thumbnailURL = `${thumbnailPrefix}${_path}`;

      // Fetch thumbnail since isUnderWearOnly is true
      getDownloadURL(ref(storage, thumbnailURL))
        .then((thumbUrl) => {
          setThumbnailPath(thumbUrl);
          return getDownloadURL(ref(storage, fullURL));
        })
        .then((url) => processImage(url))
        .catch((error) => {
          console.error('Error loading images:', error);
          setLoad(false);
        });
    } else {
      // Directly fetch full image without thumbnail processing
      getDownloadURL(ref(storage, fullURL))
        .then((url) => {
          setImagePath(url); // Set the high-res image for non-underwear items
          setLoad(false);
        })
        .catch((error) => {
          console.error('Error loading images:', error);
          setLoad(false);
        });
    }
  }, [_path, isUnderWearOnly, firebase]);

  const processImage = async (url) => {
    const img = new Image();
    img.crossOrigin = 'anonymous';
    img.onload = async function () {
      const canvas = document.createElement('canvas');
      canvas.width = this.width * 0.3; // Reduce to 30% of original size
      canvas.height = this.height * 0.3;
      await pica
        .resize(img, canvas)
        .then((result) => pica.toBlob(result, 'image/webp', 0.05))
        .then((blob) => {
          const newUrl = URL.createObjectURL(blob);
          setImagePath(newUrl); // Set the final high-res image for underwear
          setLoad(false);
        });
    };
    img.src = url;
  };

  if (isNotFound && _path === '') {
    return (
      <Box sx={{ height: doNotContainImage ? 'auto' : '100%', width: '100%' }}>
        <Box className={classes.coverImage}>
          {!isUnderWearOnly ? (
            <img
              key="n/a"
              src={blankMockupImg}
              loading="lazy"
              style={{
                height: '30vh',
                width: '8vw',
              }}
            />
          ) : (
            <img
              key={name}
              src={load ? thumbnailPath : imagePath || blankMockupImg}
              loading="lazy"
              style={
                isLowAnd5Pck
                  ? styleForIsLowCut5Pack
                  : {
                      height: '30vh',
                      width: load
                        ? isUnderWearOnly
                          ? '30vh'
                          : '10vh'
                        : 'auto',
                      filter: load ? 'blur(1px)' : 'none',
                    }
              }
            />
          )}
        </Box>
      </Box>
    );
  }

  return (
    <Box sx={{ height: doNotContainImage ? 'auto' : '100%', width: '100%' }}>
      {!isUnderWearOnly ? (
        <>
          {load ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '30vh',
                width: '8vw',
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <img
              key={name}
              src={imagePath || blankMockupImg}
              loading="lazy"
              style={
                isLowAnd5Pck
                  ? styleForIsLowCut5Pack
                  : {
                      height: '30vh',
                      width: isUnderWearOnly ? '30vh' : 'auto',
                      filter: load ? 'blur(1px)' : 'none',
                    }
              }
            />
          )}
        </>
      ) : (
        <img
          key={name}
          src={load ? thumbnailPath : imagePath || blankMockupImg}
          loading="lazy"
          style={{
            height: '30vh',
            width: load ? (isUnderWearOnly ? '30vh' : '10vh') : 'auto',
            filter: load ? 'blur(1px)' : 'none',
          }}
        />
      )}
    </Box>
  );
}

function MockupImage(props) {
  const { firebase, path, reversePath, classes, name, reverse, product } =
    props;
  const [imageData, setImageData] = useState('');
  const [imageReverseData, setImageReverseData] = useState('');
  const [transparent, setTransparent] = useState(true);
  const [revert, setRevert] = useState(false);
  const [mockImage, setMockImage] = useState(null);
  const [mockImageReverse, setMockImageReverse] = useState(null);
  const [load, setLoad] = useState(true);
  // const isMockup = name === "mockup";
  const isBoxset = name === 'boxset';

  const toolbar = true;

  useEffect(() => {
    if (path === '') {
      setLoad(false);
      return;
    } else {
      setLoad(true);
      const prefix = isBoxset ? BOXSET_MOCK_PREFIX : PRODUCT_MOCK_PREFIX;
      const storage = firebase.getstorage();
      getDownloadURL(ref(storage, `${prefix}${path}`))
        .then((url) => {
          axios.get(url, { responseType: 'arraybuffer' }).then((rsp) => {
            const base64 = btoa(
              new Uint8Array(rsp.data).reduce(
                (data, byte) => data + String.fromCharCode(byte),
                '',
              ),
            );
            setImageData('data:image/png;base64,' + base64);
            setLoad(false);
          });
        })
        .catch((error) => {
          console.log(error);
          setLoad(false);
        });

      if (reverse) {
        getDownloadURL(ref(storage, `${prefix}${reversePath}`))
          .then((url) => {
            axios.get(url, { responseType: 'arraybuffer' }).then((rsp) => {
              const base64 = btoa(
                new Uint8Array(rsp.data).reduce(
                  (data, byte) => data + String.fromCharCode(byte),
                  '',
                ),
              );
              setImageReverseData('data:image/png;base64,' + base64);
              // setLoad(false);
            });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  }, [props.path]);

  useEffect(() => {
    if (imageData !== '') {
      const img = new Image();
      img.onload = function () {
        setMockImage(img);
      };
      img.src = imageData;
    }
  }, [imageData]);

  useEffect(() => {
    if (imageReverseData !== '') {
      const img = new Image();
      img.onload = function () {
        setMockImageReverse(img);
      };
      img.src = imageReverseData;
    }
  }, [imageReverseData]);

  const handleDownload = () => {
    const img = revert ? mockImageReverse : mockImage;
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    canvas.width = img.width;
    canvas.height = img.height;

    ctx.fillStyle = transparent ? '#ffffff' : '#ffffff00';
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(img, 0, 0);

    const base64 = canvas.toDataURL('image/png');
    const link = document.createElement('a');

    document.body.appendChild(link);
    link.href = base64;
    link.download = `${product.sku}.png`; // Filename as SKU
    link.click();

    document.body.removeChild(link);
  };
  const handleClickTransparent = () => {
    setTransparent(!transparent);
  };

  const handleClickRevert = (e) => {
    e.preventDefault();
    console.log('Click Revert');
    setRevert(!revert);
  };

  const imgData = revert ? imageReverseData : imageData;

  return (
    <Box className={classes.artCanvasBox}>
      {load ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: '100%',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box className={classes.imageAndToolbarContainer}>
          <Box
            sx={{
              marginRight: '15%',
              background: transparent ? 'white' : '#6BC7F6',
              border: '5px solid',
              borderColor: transparent ? '#6BC7F6' : 'transparent', // Change color instead of removing
              borderRadius: '10px',
              maxWidth: '90vw',
              maxHeight: '50vh',
              width: 'auto',
              height: 'auto',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              overflow: 'hidden',
              boxSizing: 'border-box', // Include the border in the element's dimensions
            }}
          >
            <img
              src={imgData ? imgData : blankMockupImg}
              className={classes.hImage}
              style={{ maxWidth: '100%', maxHeight: '100%' }}
            />
          </Box>

          {!load && toolbar && (
            <Box className={classes.artToolbar}>
              <Box
                className={classes.artButton}
                onClick={handleClickTransparent}
              >
                {transparent ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </Box>
              <Box className={classes.artButton} onClick={handleDownload}>
                <FileDownloadOutlinedIcon />
              </Box>
              {reverse && (
                <Box className={classes.artButton} onClick={handleClickRevert}>
                  <RestartAltIcon sx={{ opacity: revert ? 1 : 0.5 }} />
                </Box>
              )}
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
}

function MockupImageManualEntry(props) {
  const {
    firebase,
    path: pathPassed,
    reversePath,
    classes,
    name,
    reverse,
    item,
    isBoxed,
  } = props;
  const [imageData, setImageData] = useState('');
  const [imageReverseData, setImageReverseData] = useState('');
  const [transparent, setTransparent] = useState(true);
  const [revert, setRevert] = useState(false);
  const [mockImage, setMockImage] = useState(null);
  const [mockImageReverse, setMockImageReverse] = useState(null);
  const [load, setLoad] = useState(true);

  let path = pathPassed;
  // wont do bundle mockups for now, will rather render single item mocks
  const isBoxset = item?.mockup;

  if (isBoxset) {
    path = item?.mockups?.[3]?.path ?? '';
  }

  // if path contains -SM , -SM- . Replace with LXL
  if (path?.includes('-SM')) {
    path = path.replace('-SM', '-LXL');
  }

  if (path?.includes('-YTHM')) {
    path = path.replace('-YTHM', '-LXL');
  }

  useEffect(() => {
    if (path === '') {
      setLoad(false);
      return;
    } else {
      setLoad(true);
      let prefix = isBoxset ? BOXSET_MOCK_PREFIX : PRODUCT_MOCK_PREFIX;
      if (isBoxed) {
        prefix = '';
      }
      const fullPath = `${prefix}${path}`;
      const storage = firebase.getstorage();
      getDownloadURL(ref(storage, fullPath))
        .then((url) => {
          axios.get(url, { responseType: 'arraybuffer' }).then((rsp) => {
            const base64 = btoa(
              new Uint8Array(rsp.data).reduce(
                (data, byte) => data + String.fromCharCode(byte),
                '',
              ),
            );
            setImageData('data:image/png;base64,' + base64);
            setLoad(false);
          });
        })
        .catch((error) => {
          console.log(error);
          setLoad(false);
        });

      if (reverse) {
        getDownloadURL(ref(storage, `${prefix}${reversePath}`))
          .then((url) => {
            axios.get(url, { responseType: 'arraybuffer' }).then((rsp) => {
              const base64 = btoa(
                new Uint8Array(rsp.data).reduce(
                  (data, byte) => data + String.fromCharCode(byte),
                  '',
                ),
              );
              setImageReverseData('data:image/png;base64,' + base64);
              // setLoad(false);
            });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  }, [props.path]);

  useEffect(() => {
    if (imageData !== '') {
      const img = new Image();
      img.onload = function () {
        setMockImage(img);
      };
      img.src = imageData;
    }
  }, [imageData]);

  useEffect(() => {
    if (imageReverseData !== '') {
      const img = new Image();
      img.onload = function () {
        setMockImageReverse(img);
      };
      img.src = imageReverseData;
    }
  }, [imageReverseData]);

  const convertToBase64 = () => {
    const img = revert ? mockImageReverse : mockImage;
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    canvas.width = img.width;
    canvas.height = img.height;

    if (!transparent) {
      ctx.fillStyle = '#ffffff00';
    } else {
      ctx.fillStyle = '#ffffff';
    }

    ctx.fillRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(img, 0, 0);

    return canvas.toDataURL('image/png');
  };

  const handleDownload = () => {
    console.log('handleDownload');

    const base64 = convertToBase64();

    const link = document.createElement('a');
    document.body.appendChild(link);
    link.setAttribute('href', base64);
    link.setAttribute('download', path);
    link.click();
  };

  const handleClickTransparent = () => {
    setTransparent(!transparent);
  };

  const handleClickRevert = (e) => {
    e.preventDefault();
    console.log('Click Revert');
    setRevert(!revert);
  };

  const imgData = revert ? imageReverseData : imageData;

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
      }}
    >
      {load ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: '100%',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box
          sx={{
            background: transparent ? 'white' : '#6BC7F6',
            border: '5px solid',
            borderRadius: '10px',
            borderColor: transparent ? '#6BC7F6' : 'transparent',
            height: '10vw',
            width: '10vw',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img
            src={imgData ? imgData : blankMockupImg}
            className={classes.hImage}
            style={{ height: '200px', maxHeight: '100%' }}
          />
        </Box>
      )}
    </Box>
  );
}

/* Storage Image Preview */
function UploadMockImage(props) {
  const { firebase, path, transparent, classes } = props;
  const [imagePath, setImagePath] = useState('');
  const [load, setLoad] = useState(true);

  useEffect(() => {
    if (path === '') {
      setLoad(false);
    } else {
      setLoad(true);
      const prefix = PRODUCT_MOCK_PREFIX;

      const storage = firebase.getstorage();
      getDownloadURL(ref(storage, `${prefix}${path}`))
        .then((url) => {
          setImagePath(url);
          const img = new Image();
          img.onload = function () {
            setLoad(false);
          };
          img.src = url;
        })
        .catch((error) => {
          console.log(error);
          setLoad(false);
        });
    }
  }, [props.path]);

  return (
    <Box
      sx={{
        background: transparent ? '#6BC7F6 ' : 'white',
        border: '2px solid',
        borderColor: transparent ? 'transparent' : '#6BC7F6 ', // Change color instead of removing
        borderRadius: '10px',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
      }}
    >
      {load ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: '100%',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <img
          key={name}
          src={imagePath ? imagePath : blankMockupImg}
          className={classes.mockImage}
        />
      )}
    </Box>
  );
}

function StorageImage(props) {
  const { storage, path, name, classes } = props;
  const [imagePath, setImagePath] = useState('');
  const [load, setLoad] = useState(true);

  useEffect(() => {
    if (!path) {
      setLoad(false);
      return;
    }

    setLoad(true);

    // Create a reference to the file in Firebase Storage
    const storageRef = ref(storage, path);

    // Get the download URL
    getDownloadURL(storageRef)
      .then((url) => {
        setImagePath(url);
        setLoad(true);

        // Preload the image to handle load state
        const img = new Image();
        img.onload = () => {
          setLoad(false);
        };
        img.src = url;
      })
      .catch((error) => {
        console.error('Error fetching image URL:', error);
        setLoad(false);
      });
  }, [path, storage]);

  return (
    <Box className={classes.imagePreview}>
      {load ? (
        <LinearProgress className={classes.lineBox} />
      ) : (
        <img key={name} src={imagePath} alt={name} />
      )}
    </Box>
  );
}

function MockupImageBundles(props) {
  const { firebase, path, reversePath, classes, name, reverse, data } = props;
  const [imageData, setImageData] = useState('');
  const [imageReverseData, setImageReverseData] = useState('');
  const [transparent, setTransparent] = useState(true);
  const [revert, setRevert] = useState(false);
  const [mockImage, setMockImage] = useState(null);
  const [mockImageReverse, setMockImageReverse] = useState(null);
  const [load, setLoad] = useState(true);
  const [showToolbar, setShowToolbar] = useState(false);
  const [loadingForDownload, setLoadingForDownload] = useState(false);
  const [imageError, setImageError] = useState(false);
  const isBoxset = name === 'boxset';
  const toolbar = true;

  useEffect(() => {
    setLoad(true);
    if (path === '') {
      setLoad(false);
      return;
    }

    const prefix = isBoxset ? BOXSET_MOCK_PREFIX : PRODUCT_MOCK_PREFIX;

    const storage = firebase.getstorage();
    const truePath = `${prefix}${path}`;
    getDownloadURL(ref(storage, truePath))
      .then((url) => {
        axios.get(url, { responseType: 'arraybuffer' }).then((rsp) => {
          const base64 = btoa(
            new Uint8Array(rsp.data).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              '',
            ),
          );
          setImageData('data:image/png;base64,' + base64);
          setLoad(false);
          setImageError(false);
        });
      })
      .catch((error) => {
        setImageError(true);
        console.log('Error getting image: ', error);
        setLoad(false);
      });

    if (reverse) {
      getDownloadURL(ref(storage, `${prefix}${reversePath}`))
        .then((url) => {
          axios.get(url, { responseType: 'arraybuffer' }).then((rsp) => {
            const base64 = btoa(
              new Uint8Array(rsp.data).reduce(
                (data, byte) => data + String.fromCharCode(byte),
                '',
              ),
            );
            setImageReverseData('data:image/png;base64,' + base64);
            // setLoad(false);
            setImageError(false);
          });
        })
        .catch((error) => {
          setImageError(true);
          console.log(error);
        });
    }
  }, [props.path]);

  useEffect(() => {
    if (imageData !== '') {
      const img = new Image();
      img.onload = function () {
        setMockImage(img);
      };
      img.src = imageData;
    }
  }, [imageData]);

  useEffect(() => {
    if (imageReverseData !== '') {
      const img = new Image();
      img.onload = function () {
        setMockImageReverse(img);
      };
      img.src = imageReverseData;
    }
  }, [imageReverseData]);

  const handleDownload = () => {
    if (!data || !data.sku) {
      console.error('No SKU found in data.');
      return;
    }

    setLoadingForDownload(true);

    const img = revert ? mockImageReverse : mockImage;
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    canvas.width = img.width;
    canvas.height = img.height;

    ctx.fillStyle = transparent ? '#ffffff' : '#ffffff00';
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(img, 0, 0);

    const base64 = canvas.toDataURL('image/png');
    const link = document.createElement('a');

    document.body.appendChild(link);
    link.href = base64;
    link.download = `${data.originalSku}.png`; // Filename as SKU
    link.click();

    document.body.removeChild(link);
    setLoadingForDownload(false);
  };

  const handleClickTransparent = () => {
    setTransparent(!transparent);
  };

  const handleClickRevert = (e) => {
    e.preventDefault();
    console.log('Click Revert');
    setRevert(!revert);
  };

  const imgData = revert ? imageReverseData : imageData;

  return (
    <Box sx={styles.artCanvasBox}>
      <Box
        sx={styles.mainBundleBox}
        onMouseEnter={() => setShowToolbar(true)}
        onMouseLeave={() => setShowToolbar(false)}
      >
        <Box
          sx={{
            ...styles.bundleBox,
            background: transparent ? 'white' : '#6BC7F6',
            borderColor: transparent ? '#6BC7F6' : 'transparent',
          }}
        >
          {load ? (
            <Box
              sx={{
                height: '40vh',
                width: '20vw',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <img
              src={imageError || !imgData ? blankMockupImg : imgData}
              alt="Mockup Image"
              style={{
                maxWidth: '100%',
                maxHeight: '100%',
                width: '100%', // Ensure it takes full width of the container
                height: '100%', // Ensure it takes full height of the container
                objectFit: 'contain', // Keeps the aspect ratio intact
              }}
            />
          )}
        </Box>

        {!load && toolbar && (
          <Box
            sx={{
              ...styles.artToolbar,
              opacity: showToolbar ? 1 : 0,
            }}
          >
            <Box sx={styles.actionButton} onClick={handleClickTransparent}>
              {transparent ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </Box>
            <Box sx={styles.actionButton} onClick={handleDownload}>
              {loadingForDownload ? (
                <CircularProgress size={20} />
              ) : (
                <FileDownloadOutlinedIcon />
              )}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}

const styles = {
  artCanvasBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '75%',
    width: '75%',
    boxSizing: 'border-box', // Include padding in width/height calculations
  },
  mainBundleBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative', // Added to position children absolutely relative to this
  },
  bundleBox: {
    borderRadius: '10px',
    display: 'flex',
    border: '8px solid',
    position: 'relative', // Needed to position the toolbar
    '&:hover $artToolbar': {
      // Moved hover effect here
      opacity: 1,
    },
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
  },
  artToolbar: {
    position: 'absolute',
    bottom: 0,
    width: '20%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px',
    zIndex: 999,
    transition: 'opacity 0.3s',
    gap: '10px',
    paddingBottom: '20px',
  },
  actionButton: {
    cursor: 'pointer',
    background: 'rgba(227, 227, 228, 0.7)',
    borderRadius: '100px',
    width: '40px',
    height: '35px',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
  },
};

export {
  MockupImageBundles,
  SoloArt,
  FileArt,
  ProductArt,
  MockupImage,
  UploadMockImage,
  StorageImage,
  ProductArtBundle,
  SoloArtBundle,
  MockupImageManualEntry,
};
