import { useState } from 'react';
import { makeStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Brightness1Icon from '@mui/icons-material/Brightness1';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { getDownloadURL, ref } from 'firebase/storage';
import axios from 'axios';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { ProductArtBundle, SoloArtBundle } from '../Products/components';
import { PRODUCT_MOCK_PREFIX } from '../../constants/defines';

const useHeaderStyles = makeStyles(() => ({
  boxControl: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  boxMockup: {
    width: 'fit-content',
    borderRadius: '6px',
    border: '3px solid #EEEEEE',
    padding: '10px',
    boxSizing: 'border-box',
  },
  boxDots: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  imageBox: {
    position: 'relative',
  },
  coverImage: {},
  hImage: {
    height: 'auto',
    width: '100%',
  },
  vImage: {
    height: 'auto',
    width: '100%',
  },
}));

const MockupItem = (props) => {
  const {
    product,
    cropSettings,
    index,
    toggle,
    enableToggle,
    onToggle,
    adjustForYouthL,
    isMixedBoxSet,
    isAdjustedForOtherTypes,
    isUnderWearOnly,
    isLowAnd5Pck,
    firebase,
  } = props;
  const classes = useHeaderStyles();
  const [isTransparent, setIsTransparent] = useState(true);

  const handleToggle = (val) => {
    onToggle(index, val);
  };

  const isUnderwear = product?.type === 'Underwear';

  const handleDownload = () => {
    const prefix = PRODUCT_MOCK_PREFIX;

    const storage = firebase.getstorage();

    const truePath = `${prefix}${product.pathFront}`;

    getDownloadURL(ref(storage, truePath))
      .then((url) => {
        axios.get(url, { responseType: 'arraybuffer' }).then((rsp) => {
          const base64 = `data:image/png;base64,${btoa(
            new Uint8Array(rsp.data).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              '',
            ),
          )}`;

          const img = new Image();
          img.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            // Set canvas dimensions to match image
            canvas.width = img.width;
            canvas.height = img.height;

            // Fill the canvas with a white background
            ctx.fillStyle = isTransparent ? 'white' : 'transparent';
            ctx.fillRect(0, 0, canvas.width, canvas.height);

            // Draw the image on top
            ctx.drawImage(img, 0, 0);

            // Create download link
            const link = document.createElement('a');
            document.body.appendChild(link);
            link.href = canvas.toDataURL('image/png'); // Convert canvas to PNG
            link.download = `${product.sku}.png`; // Filename as SKU
            link.click();

            document.body.removeChild(link);
          };

          img.src = base64; // Load base64 image
        });
      })
      .catch((error) => {
        console.log('Error getting image: ', error);
      });
  };

  const handleClickTransparent = () => {
    setIsTransparent(!isTransparent);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        paddingTop: adjustForYouthL || isMixedBoxSet ? '40%' : '10%',
      }}
    >
      {!adjustForYouthL && !isMixedBoxSet && (
        <Box style={styles.draggerBox}>
          <DragIndicatorIcon
            sx={{
              fontSize: '40px',
              color: '#808080',
            }}
          />
        </Box>
      )}
      <Box className={classes.boxMockup}>
        {!product ? (
          <ProductArtBundle
            name="fullImage"
            key="fullImage"
            firebase={props.firebase.getFirebase()}
            classes={classes}
            progress={100}
            path=""
            isUnderwearBundleComponent={isUnderwear}
            isAdjustedForOtherTypes={isAdjustedForOtherTypes}
            isUnderWearOnly={isUnderWearOnly}
            isNotFound={true}
            isLowAnd5Pck={isLowAnd5Pck}
          />
        ) : (
          <>
            {product.identical ? (
              <SoloArtBundle
                name="SoloImage"
                key="SoloImage"
                cropSettings={cropSettings}
                isFront={true}
                firebase={props.firebase.getFirebase()}
                classes={classes}
                file={null}
                path={product.pathFront}
                isMixedBoxSet={isMixedBoxSet}
                isAdjustedForOtherTypes={isAdjustedForOtherTypes}
                isUnderWearOnly={isUnderWearOnly}
                isLowAnd5Pck={isLowAnd5Pck}
              />
            ) : (
              <ProductArtBundle
                name="fullImage"
                key="fullImage"
                firebase={props.firebase.getFirebase()}
                classes={classes}
                progress={100}
                path={product.pathFront}
                boxedPath={product?.isBoxedImagePath ?? ''}
                isUnderwearBundleComponent={isUnderwear}
                isAdjustedForOtherTypes={isAdjustedForOtherTypes}
                isUnderWearOnly={isUnderWearOnly}
                isLowAnd5Pck={isLowAnd5Pck}
              />
            )}
          </>
        )}
      </Box>
      {!isMixedBoxSet && enableToggle && !isUnderWearOnly && (
        <Box className={classes.boxDots}>
          <IconButton
            aria-label="close"
            onClick={(e) => handleToggle(false)}
            sx={!toggle ? styles.activeButton : styles.inactiveButton}
          >
            <Brightness1Icon fontSize="medium" />
          </IconButton>
          <IconButton
            aria-label="close"
            sx={toggle ? styles.activeButton : styles.inactiveButton}
            onClick={(e) => handleToggle(true)}
          >
            <Brightness1Icon fontSize="medium" />
          </IconButton>
          <Box sx={styles.actionButton} onClick={handleDownload}>
            <FileDownloadOutlinedIcon />
          </Box>
          <Box sx={styles.actionButton} onClick={handleClickTransparent}>
            {isTransparent ? <VisibilityIcon /> : <VisibilityOffIcon />}
          </Box>
        </Box>
      )}
    </Box>
  );
};

const styles = {
  draggerBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: '20px',
  },
  activeButton: {
    padding: '5px',
    color: '#1F7CF2',
  },
  inactiveButton: {
    padding: '5px',
    color: '#ccc',
  },
  actionButton: {
    cursor: 'pointer',
    marginLeft: '5px',
    background: 'rgba(227, 227, 228, 0.7)',
    borderRadius: '100px',
    width: '25px',
    height: '25px',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
  },
};

export default MockupItem;
