/* eslint-disable max-lines */
/* eslint-disable complexity */
/* eslint-disable max-statements */
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { makeStyles, useTheme } from '@mui/styles';
import {
  AppBar,
  IconButton,
  Button,
  InputBase,
  useMediaQuery,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CropOriginalIcon from '@mui/icons-material/CropOriginal';
import EditIcon from '@mui/icons-material/Edit';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import BallotIcon from '@mui/icons-material/Ballot';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import MenuIcon from '@mui/icons-material/Menu';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

import logo from '../../images/Rock Em - Website Logo - Mobile.svg';
import { environmentName } from '../../constants/defines';
import DevModal from '../devModal';
import { ToastCopyCLip } from '../Toast/copyToClip';
import * as ROUTES from '../../constants/routes';
import SignOutButton from '../SignOut';
import UpdateAlert from '../Updates';

function Navigation(props) {
  const [openDevModal, setOpenDevModal] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const page = props.page;
  const user = props.user;
  let isSecondaryMenuOpen = false,
    isProductsSecondaryMenuOpen = false,
    isOrderSecondaryMenuOpen = false,
    isAnalyticsSecondaryMenuOpen = false;

  // Define arrays to group pages
  const secondaryMenuPages = [
    'custom',
    'wholesale',
    '',
    'manual',
    'dsinline',
    'dscustom',
    'batch',
    'dsqueues',
    'smartbatch',
  ];
  const productsSecondaryMenuPages = [
    'products',
    'bundles',
    'formats',
    'templates',
    'tags',
    'uploads',
    'product-parameters',
  ];
  const orderSecondaryMenuPages = ['report', 'timestamp'];
  const analyticsSecondaryMenuPages = ['analytics'];

  // Check which menu should be open
  if (secondaryMenuPages.includes(page)) {
    isSecondaryMenuOpen = true;
  } else if (productsSecondaryMenuPages.includes(page)) {
    isProductsSecondaryMenuOpen = true;
  } else if (orderSecondaryMenuPages.includes(page)) {
    isOrderSecondaryMenuOpen = true;
  } else if (analyticsSecondaryMenuPages.includes(page)) {
  }

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const renderSecondaryMenu = (
    <div className={classes.secondaryMenu}>
      <div className="Container">
        <div className={classes.toolBar}>
          <NavLink
            to={ROUTES.WEB_QUEUES}
            exact
            className={classes.navSubLink}
            activeClassName="current"
          >
            <IconButton
              aria-label=""
              aria-controls="menu-appbar"
              aria-haspopup="true"
              color="inherit"
            >
              <LoyaltyIcon />
              Web
            </IconButton>
          </NavLink>

          <NavLink
            to={ROUTES.DS_QUEUES}
            className={classes.navSubLink}
            activeClassName="current"
          >
            <IconButton
              aria-label="DS Queues"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              color="inherit"
            >
              <LoyaltyIcon />
              DS
            </IconButton>
          </NavLink>

          <NavLink
            to={ROUTES.WHOLESALE}
            className={classes.navSubLink}
            activeClassName="current"
          >
            <IconButton
              aria-label="Wholesale Order Queue"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              color="inherit"
            >
              <ViewInArIcon />
              Wholesale
            </IconButton>
          </NavLink>
          <NavLink
            to={ROUTES.MANUAL_ENTRY}
            className={classes.navSubLink}
            activeClassName="current"
          >
            <IconButton
              aria-label="Manual Entry"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              color="inherit"
            >
              <EditIcon />
              Manual
            </IconButton>
          </NavLink>
          <NavLink
            to={ROUTES.BATCH}
            className={classes.navSubLink}
            activeClassName="current"
          >
            <IconButton
              aria-label="Batch"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              color="inherit"
            >
              <WorkOutlineIcon />
              Batch
            </IconButton>
          </NavLink>
        </div>
      </div>
    </div>
  );

  const renderProductSecondaryMenu = (
    <div className={classes.secondaryMenu}>
      <div className="Container">
        <div className={classes.toolBar}>
          <NavLink
            to={ROUTES.PRODUCTS}
            className={classes.navSubLink}
            activeClassName="current"
          >
            <IconButton
              aria-label="Products"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              color="inherit"
            >
              <LocalMallIcon />
              Products
            </IconButton>
          </NavLink>
          <NavLink
            to={ROUTES.BUNDLES}
            className={classes.navSubLink}
            activeClassName="current"
          >
            <IconButton
              aria-label="Box Sets"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              color="inherit"
            >
              <AspectRatioIcon />
              Bundles
            </IconButton>
          </NavLink>
          <NavLink
            to={ROUTES.PRODUCT_PARAMETERS}
            className={classes.navSubLink}
            activeClassName="current"
          >
            <IconButton
              aria-label="Formats and Sizes"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              color="inherit"
            >
              <FormatColorFillIcon />
              Product Parameters
            </IconButton>
          </NavLink>
          <NavLink
            to={ROUTES.TEMPLATES}
            className={classes.navSubLink}
            activeClassName="current"
          >
            <IconButton
              aria-label="Templates"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              color="inherit"
            >
              <WorkOutlineIcon />
              Custom Templates
            </IconButton>
          </NavLink>
          <NavLink
            to={ROUTES.UPLOADS}
            className={classes.navSubLink}
            activeClassName="current"
          >
            <IconButton
              aria-label="Uploads"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              color="inherit"
            >
              <UploadFileIcon />
              Uploads
            </IconButton>
          </NavLink>
          <NavLink
            to={ROUTES.TAGS}
            className={classes.navSubLink}
            activeClassName="current"
          >
            <IconButton
              aria-label="Tags"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              color="inherit"
            >
              <LoyaltyIcon />
              Tags
            </IconButton>
          </NavLink>
        </div>
      </div>
    </div>
  );

  const renderOrderSecondaryMenu = (
    <div className={classes.secondaryMenu}>
      <div className="Container">
        <div className={classes.toolBar}>
          <NavLink
            to={ROUTES.TIMESTAMP}
            className={classes.navSubLink}
            activeClassName="current"
          >
            <IconButton
              aria-label="Timestamp"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              color="inherit"
            >
              <AccessTimeIcon />
              Order Timestamps
            </IconButton>
          </NavLink>
          <NavLink
            to={ROUTES.REPORT}
            className={classes.navSubLink}
            activeClassName="current"
          >
            <IconButton
              aria-label="Report"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              color="inherit"
            >
              <BallotIcon />
              Print Reports
            </IconButton>
          </NavLink>
        </div>
      </div>
    </div>
  );

  const mobileMenu = (
    <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
      <List>
        {/* home list item */}
        <ListItem
          button
          component={NavLink}
          to={ROUTES.ANALYTICS}
          onClick={toggleDrawer(false)}
        >
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItem>

        <ListItem
          button
          component={NavLink}
          to={ROUTES.WHOLESALE}
          onClick={toggleDrawer(false)}
        >
          <ListItemIcon>
            <ViewInArIcon />
          </ListItemIcon>
          <ListItemText primary="Wholesale" />
        </ListItem>
        <ListItem
          button
          component={NavLink}
          to={ROUTES.CUSTOM_ORDER}
          onClick={toggleDrawer(false)}
        >
          <ListItemIcon>
            <CropOriginalIcon />
          </ListItemIcon>
          <ListItemText primary="Custom" />
        </ListItem>
        <ListItem
          button
          component={NavLink}
          to={ROUTES.MANUAL_ENTRY}
          onClick={toggleDrawer(false)}
        >
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <ListItemText primary="Manual Entry" />
        </ListItem>
        <ListItem
          button
          component={NavLink}
          to={ROUTES.RUNNING}
          onClick={toggleDrawer(false)}
        >
          <ListItemIcon>
            <ShowChartIcon />
          </ListItemIcon>
          <ListItemText primary="Running" />
        </ListItem>
        <ListItem
          button
          component={NavLink}
          to={ROUTES.HISTORY}
          onClick={toggleDrawer(false)}
        >
          <ListItemIcon>
            <AccessTimeIcon />
          </ListItemIcon>
          <ListItemText primary="History" />
        </ListItem>
        <ListItem
          button
          component={NavLink}
          to={ROUTES.PRODUCTS}
          onClick={toggleDrawer(false)}
        >
          <ListItemIcon>
            <LocalMallIcon />
          </ListItemIcon>
          <ListItemText primary="Products" />
        </ListItem>
        <ListItem
          button
          component={NavLink}
          to={ROUTES.BUNDLES}
          onClick={toggleDrawer(false)}
        >
          <ListItemIcon>
            <AspectRatioIcon />
          </ListItemIcon>
          <ListItemText primary="Bundles" />
        </ListItem>
        <ListItem
          button
          component={NavLink}
          to={ROUTES.FORMATS}
          onClick={toggleDrawer(false)}
        >
          <ListItemIcon>
            <FormatColorFillIcon />
          </ListItemIcon>
          <ListItemText primary="Formats & Sizes" />
        </ListItem>
        <ListItem
          button
          component={NavLink}
          to={ROUTES.TEMPLATES}
          onClick={toggleDrawer(false)}
        >
          <ListItemIcon>
            <WorkOutlineIcon />
          </ListItemIcon>
          <ListItemText primary="Custom Templates" />
        </ListItem>
        <ListItem
          button
          component={NavLink}
          to={ROUTES.UPLOADS}
          onClick={toggleDrawer(false)}
        >
          <ListItemIcon>
            <UploadFileIcon />
          </ListItemIcon>
          <ListItemText primary="Uploads" />
        </ListItem>
        <ListItem
          button
          component={NavLink}
          to={ROUTES.TAGS}
          onClick={toggleDrawer(false)}
        >
          <ListItemIcon>
            <LoyaltyIcon />
          </ListItemIcon>
          <ListItemText primary="Tags" />
        </ListItem>
        <ListItem
          button
          component={NavLink}
          to={ROUTES.TIMESTAMP}
          onClick={toggleDrawer(false)}
        >
          <ListItemIcon>
            <AccessTimeIcon />
          </ListItemIcon>
          <ListItemText primary="Order Timestamps" />
        </ListItem>
        <ListItem
          button
          component={NavLink}
          to={ROUTES.REPORT}
          onClick={toggleDrawer(false)}
        >
          <ListItemIcon>
            <BallotIcon />
          </ListItemIcon>
          <ListItemText primary="Print Reports" />
        </ListItem>
      </List>
      <Box sx={{ padding: '5px', textAlign: 'center' }}>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => setOpenDevModal(true)}
        >
          Re-Sync Orders
        </Button>
      </Box>
    </Drawer>
  );

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <div className={classes.primaryMenu}>
          <div className="Container">
            <div className={!isMobile ? classes.toolBar : classes.mobileView}>
              {isMobile && (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    onClick={toggleDrawer(true)}
                  >
                    <MenuIcon />
                  </IconButton>
                  {mobileMenu}
                </Box>
              )}

              <div
                className={
                  !isMobile
                    ? classes.logoWrapper
                    : {
                        ...classes.logoWrapper,
                        marginRight: '0px',
                      }
                }
              >
                <img src={logo} alt="Rock Em" className={classes.mainLogo} />
                {environmentName !== 'production' && (
                  <div className={classes.logoSubtext}>{environmentName}</div>
                )}
              </div>

              {!isMobile && (
                <div className="main-menu">
                  <NavLink
                    to={ROUTES.ANALYTICS}
                    className={classes.navLink}
                    exact
                    activeClassName="current"
                  >
                    Home
                  </NavLink>
                  <NavLink
                    to={ROUTES.WEB_QUEUES}
                    className={`${classes.navLink} ${isSecondaryMenuOpen ? 'current' : ''}`}
                  >
                    Print
                  </NavLink>
                  <NavLink
                    to={ROUTES.PRODUCTS}
                    className={`${classes.navLink} ${isProductsSecondaryMenuOpen ? 'current' : ''}`}
                  >
                    Products
                  </NavLink>
                  <NavLink
                    to={ROUTES.TIMESTAMP}
                    className={`${classes.navLink} ${isOrderSecondaryMenuOpen ? 'current' : ''}`}
                  >
                    Orders
                  </NavLink>
                  <NavLink
                    to={ROUTES.INVENTORY}
                    className={classes.navLink}
                    exact
                    activeClassName="current"
                  >
                    Inventory
                  </NavLink>
                  <NavLink
                    to={ROUTES.BARCODES}
                    className={classes.navLink}
                    exact
                    activeClassName="current"
                  >
                    Barcodes
                  </NavLink>
                  {(user?.view?.includes('users') ||
                    user?.edit?.includes('users')) && (
                    <NavLink
                      to={ROUTES.USERS}
                      className={classes.navLink}
                      activeClassName="current"
                    >
                      Users
                    </NavLink>
                  )}
                  <NavLink
                    to={ROUTES.MIX_MATCH_COLLECTIONS}
                    className={classes.navLink}
                    activeClassName="current"
                  >
                    Mix & Match
                  </NavLink>
                </div>
              )}
              {!isMobile && <div className={classes.grow} />}
              <UpdateAlert userId={user?.uid} />
              {!isMobile && <div className={classes.grow} />}

              {!isMobile && (
                <Button
                  variant="outlined"
                  color="primary"
                  sx={{ color: 'white' }}
                  onClick={() => setOpenDevModal(true)}
                >
                  Re-Sync Orders
                </Button>
              )}

              <SignOutButton />
            </div>
          </div>
        </div>

        {isSecondaryMenuOpen && renderSecondaryMenu}
        {isProductsSecondaryMenuOpen && renderProductSecondaryMenu}
        {isOrderSecondaryMenuOpen && renderOrderSecondaryMenu}
        {/* {isAnalyticsSecondaryMenuOpen && <AnalyticsSubNavBar classes={classes} />} */}
      </AppBar>
      {openDevModal && (
        <DevModal
          isOpen={openDevModal}
          setOpenDevModal={setOpenDevModal}
          setShowToast={setShowToast}
        />
      )}
      <ToastCopyCLip show={showToast} message="Success! Refresh your page." />
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  logoWrapper: {
    display: 'block',
    height: '39px',
    width: '105px',
    marginRight: '60px',
  },
  logoSubtext: {
    position: 'relative',
    top: '-5px',
    paddingLeft: '7%',
    color: '#F00',
    letterSpacing: '10px',
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: 'rgba(255, 255, 255, 0.15)',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.25)',
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    right: '0px',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: '10px 16px!important',
    color: '#ffffff!important',
    paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
  },
  navLink: {
    transition: 'all 200ms',
    fontSize: '16px',
    lineHeight: 1.25,
    color: 'white',
    textDecoration: 'none',
    textTransform: 'initial',
    padding: '30px 0px',
    borderBottom: '4px solid transparent',
    margin: '0 24px',
    opacity: '0.8',
    display: 'inline-block',
    cursor: 'Pointer',
    '&:hover': {
      borderBottom: '4px solid #1f7cf2',
      opacity: '1',
    },
    '&.current': {
      borderBottom: '4px solid #1f7cf2',
      opacity: '1',
    },
  },
  navSubLink: {
    transition: 'all 200ms',
    textDecoration: 'none',
    textTransform: 'initial',
    color: 'white',
    margin: '0 20px',
    '&:hover': {
      borderRadius: '8px',
      backgroundColor: 'rgba(242, 244, 246, 0.12)',
      '& span': {
        opacity: '1',
      },
    },
    '&.current': {
      borderRadius: '8px',
      backgroundColor: 'rgba(242, 244, 246, 0.12)',
      '& span': {
        opacity: '1',
      },
    },
    '& button': {
      fontSize: '16px',
      padding: '12px 20px',
    },
    '& span': {
      opacity: '0.8',
      fontSize: '14px',
      fontWeight: '500',
      lineHeight: 1.25,
    },
    '& svg': {
      marginRight: '10px',
    },
  },
  primaryMenu: {
    padding: '0px 0px',
    background: '#222222',
  },
  secondaryMenu: {
    padding: '18px 0px',
    background: '#3d3d3d',
  },
  toolBar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  mobileView: {
    display: 'flex',
    alignItems: 'center',
    height: '10vh',
    justifyContent: 'space-between',
  },
  mobileMainMenu: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
}));

const icons = [
  { component: ExitToAppIcon, name: 'ExitToAppIcon' },
  // { component: ViewInArIcon, name: 'view_in_ar_icon' },
  // { component: ShowChartIcon, name: 'ShowChartIcon' },
  // { component: CropOriginalIcon, name: 'CropOriginalIcon' },
  // { component: EditIcon, name: 'EditIcon' },
  // { component: AccessTimeIcon, name: 'AccessTimeIcon' },
  // { component: AspectRatioIcon, name: 'AspectRatioIcon' },
  // { component: FormatColorFillIcon, name: 'FormatColorFillIcon' },
  // { component: LoyaltyIcon, name: 'LoyaltyIcon' },
  // { component: LocalMallIcon, name: 'LocalMallIcon' },
  // { component: UploadFileIcon, name: 'UploadFileIcon' },
  // { component: WorkOutlineIcon, name: 'WorkOutlineIcon' },
  // { component: BallotIcon, name: 'BallotIcon' },
];

// function downloadSVG(iconComponent, iconName) {
//   const iconString = ReactDOMServer.renderToString(React.createElement(iconComponent));
//   const parser = new DOMParser();
//   const svgDoc = parser.parseFromString(iconString, 'image/svg+xml');
//   const svgElement = svgDoc.querySelector('svg');

//   if (svgElement) {
//     // Adjust the SVG element's properties if necessary
//     svgElement.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
//     svgElement.setAttribute('width', '24');
//     svgElement.setAttribute('height', '24');

//     const svgData = new XMLSerializer().serializeToString(svgElement);
//     const svgBlob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' });
//     const svgUrl = URL.createObjectURL(svgBlob);

//     const downloadLink = document.createElement('a');
//     downloadLink.href = svgUrl;
//     downloadLink.download = `${iconName}.svg`;
//     document.body.appendChild(downloadLink);
//     downloadLink.click();
//     document.body.removeChild(downloadLink);

//     URL.revokeObjectURL(svgUrl);
//   } else {
//     console.error(`Failed to find SVG element for ${iconName}`);
//   }
// }

// icons.forEach(icon => {
//   downloadSVG(icon.component, icon.name);
// });

export default Navigation;
