import { finishedGoodsStyle } from './constants';
import sortAndBatchOrders from './sortingFunction';

const findAndProcessSocksOnly = async (
  orders,
  isWebQueue = false,
  db,
  sizes,
  formats,
  type,
  isBelt,
) => {
  const socksOnlyOrdersWithBoxSets = orders
    .filter((order) =>
      order.items.every(
        (item) =>
          item?.isBoxSet === true &&
          item?.data?.type?.toLowerCase() === 'socks',
      ),
    )
    .filter((order) => order.items.every((item) => item?.error === ''))
    .filter((order) => order.items.every((item) => !item.isCustomProduct));

  // in those boxsets orders, make sure there are only socks in that order...

  // Step 1: Filter socks-only orders
  const socksOnlyOrders = orders
    .filter(
      (order) =>
        order.items &&
        order.items.every((item) => item?.type?.toLowerCase() === 'socks'),
    )
    // also filter out any items that have an error !== ""
    .filter((order) =>
      order.items.every(
        (item) => item.type === 'Socks' && !item.isCustomProduct,
      ),
    )
    .filter((order) => order.items.some((item) => item?.error === ''));
  const includeFinishedGoods = orders
    .filter((order) =>
      order.items.some((item) =>
        finishedGoodsStyle.includes(item?.style?.toLowerCase()),
      ),
    )
    .filter((order) =>
      order.items.some((item) => item?.style?.toLowerCase() !== ''),
    )
    .filter(
      (order) =>
        order.items.every(
          (item) => !item?.type?.toLowerCase().includes('underwear'),
        ), // Exclude orders with underwear
    )
    .filter((order) => order.items.some((item) => item?.error === ''));

  const combinedOrders = [
    ...socksOnlyOrdersWithBoxSets,
    ...socksOnlyOrders,
    ...includeFinishedGoods,
  ];

  const rows = sortAndBatchOrders(
    combinedOrders,
    isWebQueue,
    false,
    db,
    sizes,
    formats,
    type,
    isBelt,
    false,
    true,
  );

  return rows;
};

export default findAndProcessSocksOnly;
