import { makeStyles } from '@mui/styles';
import AsyncSelect from 'react-select/async';
import _ from 'lodash';

import { filterNonCustomProducts } from '../../module/typesense';
import useHotkeyToggle from '../../hooks/useHotkeyToggle';
import { lookForAnyOrder } from '../../module/lookForAnyOrder';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    marginTop: '0px',
  },
  searchInput: {
    width: '100%',

    'label + &': {
      marginTop: '15px',
      width: '100%',
    },
    label: {
      fontSize: '16px',
      fontWeight: '500',
      color: '#000000',
    },
    '& input': {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: 'rgba(0,0,0,0.04)',
      border: '1px solid #ced4da',

      fontSize: 16,
      padding: '11px 10px 11px 12px !important',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
  },
}));

let data = [];

export default function ProductsDropdown({
  onSelect,
  onChange,
  setShowTooltip,
  searchOrderMode,
  setSearchOrderMode,
  db,
  formats,
  sizes,
  singlesInventoryBypass,
}) {
  const classes = useStyles();

  // custom hook to toggle SKU search mode
  useHotkeyToggle({
    setFunction: setSearchOrderMode,
    variable: searchOrderMode,
    extraFunction: setShowTooltip,
    updateStorageKey: 'searchOrderMode',
    allowHook: !singlesInventoryBypass,
  });

  const promiseOptions = (inputValue) => {
    // trim the input value
    inputValue = inputValue.trim();
    if (onChange) {
      onChange(inputValue);
    }

    if (searchOrderMode) {
      return new Promise((resolve) => {
        setTimeout(() => {
          lookForAnyOrder(inputValue, db).then((result) => {
            // get the data from the result and pass in value and label
            // label will be item.orderNumber
            let _items = _.map(result, (item) => ({
              value: item,
              label: `#${item.orderNumber}`,
            }));

            // remove labels that are #undefined
            _items = _items.filter((item) => item.label !== '#undefined');

            resolve(_items);
          });
        }, 500);
      });
    }

    return new Promise((resolve) => {
      setTimeout(() => {
        filterNonCustomProducts(
          inputValue,
          null,
          100,
          searchOrderMode,
          db,
          formats,
          sizes,
          singlesInventoryBypass,
        ).then((results) => {
          data = [...results];
          const _items = _.map(results, (item) => ({
            value: item.sku,
            label: `${item.sku} - ${item.name}`,
          }));
          resolve(_items);
        });
      }, 500);
    });
  };

  const handleChange = (newValue) => {
    if (searchOrderMode) {
      if (newValue) {
        onSelect(newValue);
      }
    }

    if (newValue) {
      const item = _.find(data, (item) => item.sku === newValue.value);
      if (item) {
        onSelect(item);
      }
    }
  };

  return (
    <div className={classes.root}>
      <AsyncSelect
        closeMenuOnSelect={true}
        isClearable
        loadOptions={promiseOptions}
        onChange={handleChange}
        noOptionsMessage={() => 'No results found'}
        loadingMessage={() => 'searching...'}
        placeholder="Search SKU"
      />
    </div>
  );
}
