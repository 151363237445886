import { useState, useEffect } from 'react';

import { getTotalsForSpecificProduct } from '../db/getTotals';
import { getTotalsForSpecificProductDropship } from '../db/getDsTotals';
import { transformConsumerData } from '../components/Analytics/helpers';

const useProductCounts = (db) => {
  const [specificProductCounts, setSpecificProductCounts] = useState({
    DTC: null,
    DROPSHIP: null,
  });

  useEffect(() => {
    const unsubDTC = getTotalsForSpecificProduct(db, (regularData) => {
      setSpecificProductCounts((prev) => ({
        ...prev,
        DTC: transformConsumerData(regularData),
      }));
    });

    const unsubDropship = getTotalsForSpecificProductDropship(
      db,
      (dropshipData) => {
        setSpecificProductCounts((prev) => ({
          ...prev,
          DROPSHIP: transformConsumerData(dropshipData),
        }));
      },
    );

    return () => {
      unsubDTC();
      unsubDropship();
    };
  }, [db]); // Depend on `db` to reinitialize if it changes

  return specificProductCounts;
};

export default useProductCounts;
