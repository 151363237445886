import sortAndBatchOrders from './sortingFunction';

const findAndProcessUnderwearAndSocks = async (
  orders,
  isWebQueue = false,
  socksOnlyBatches,
  db,
  sizes,
  formats,
  isBelt,
) => {
  // Filter for orders that contain both socks and underwear items
  const socksAndUnderwearOrders = orders.filter((order) => {
    const hasSocks = order.items.some(
      (item) => item?.type?.toLowerCase() === 'socks',
    );
    const hasUnderwear = order.items.some(
      (item) => item?.type?.toLowerCase() === 'underwear',
    );
    // or has mixed
    const hasMixed = order.items.some(
      (item) => item?.type?.toLowerCase() === 'mixed',
    );
    return (hasSocks && hasUnderwear) || hasMixed;
  });

  const groupedOrdersFromLeftOvers = [
    ...socksAndUnderwearOrders,
    ...socksOnlyBatches,
  ];

  const rows = sortAndBatchOrders(
    groupedOrdersFromLeftOvers,
    isWebQueue,
    true,
    db,
    sizes,
    formats,
    'underwearAndSocks',
    isBelt,
    true,
  );

  return rows;
};

export default findAndProcessUnderwearAndSocks;
