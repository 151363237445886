import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import PrintIcon from '@mui/icons-material/Print';

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    padding: '50px 0 50px 16px',
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid',
    borderColor: 'rgba(217, 223, 233, 0.4)',
  },
  batchBox: {
    display: 'flex',
    position: 'relative',
  },
  button: {
    padding: '9px 32px',
    fontSize: '16px',
    fontWeight: '500',
    color: '#ffffff',
    textTransform: 'initial',
    marginRight: '16px',
    '&.Mui-disabled': {
      background: 'rgba(0, 0, 0, 0.12)!important',
      color: 'rgba(0, 0, 0, 0.26)',
    },
  },
  formLabel: {
    color: '#000000',
    fontSize: '14px',
    fontWeight: '500',
    letterSpacing: '1.5px',
  },
  batchButton: {
    fontSize: '16px',
    fontWeight: '500',
    color: '#FFFFFF',
    width: '148px',
    textTransform: 'initial',
    '&.Mui-disabled': {
      background: 'rgba(0, 0, 0, 0.12)!important',
      color: 'rgba(0, 0, 0, 0.26)',
    },
  },
  cartNum: {
    fontSize: '12px',
    fontWeight: '600',
    color: '#FFFFFF',
    position: 'absolute',
    top: '-11px',
    right: '-11px',
    width: '25px',
    height: '25px',
    background: '#000000',
    borderRadius: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const PageToolbar = (props) => {
  const classes = useToolbarStyles();
  const {
    numSelected,
    allSelected,
    onSelectAllClick,
    handleSelect,
    handlePrint,
    handleBatch,
    count,
  } = props;

  return (
    <div className="pageToolbar">
      <div className={classes.buttonBox}>
        <FormControl
          component="fieldset"
          sx={{ marginRight: '40px', height: '46px', width: '165px' }}
        >
          <FormGroup aria-label="position" row>
            <FormControlLabel
              className={classes.formLabel}
              control={
                <Checkbox
                  color="primary"
                  indeterminate={numSelected > 0 && !allSelected}
                  checked={numSelected > 0 && allSelected}
                  onChange={onSelectAllClick}
                />
              }
              label={
                numSelected > 0 && allSelected ? 'SELECT NONE' : 'SELECT ALL'
              }
              labelPlacement="end"
            />
          </FormGroup>
        </FormControl>

        {numSelected > 0 && (
          <Button
            variant="blue"
            sx={{ width: '180px' }}
            onClick={handlePrint}
            disabled={!props.editOnly}
          >
            Print Selected
          </Button>
        )}
      </div>

      <div className={classes.batchBox}>
        <Button
          variant="blue"
          sx={{ width: '180px' }}
          onClick={handleBatch}
          disabled={!props.editOnly}
        >
          <PrintIcon />
          &nbsp;&nbsp;Batch
        </Button>
        {count > 0 && <div className={classes.cartNum}>{count}</div>}
      </div>
    </div>
  );
};

PageToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default PageToolbar;
