export const convertToCorrectTotals = (data) => {
  const totals = {};

  Object.keys(data).forEach((key) => {
    // Extract product type before the first `-`
    const productType = key.includes('-') ? key.split('-')[0] : key;
    const newKey = `${productType.toLowerCase()}Count`; // Rename format

    if (!totals[newKey]) {
      totals[newKey] = 0;
    }

    totals[newKey] += data[key];
  });

  return totals;
};

export const removeUnwantedCounts = (data) => {
  const filteredData = { ...data };

  // Delete specific keys
  delete filteredData['socksCount'];
  delete filteredData['underwearCount'];

  return filteredData;
};
