/* eslint-disable max-lines */
/* eslint-disable complexity */
import {
  TableRow,
  TableCell,
  IconButton,
  Box,
  Typography,
  Button,
  Tooltip,
  MenuItem,
  Menu,
} from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import DoneIcon from '@mui/icons-material/Done';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import AssignmentIcon from '@mui/icons-material/Assignment';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { withStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';

import UnderwearSvg from '../SVGS/underwear';
import SockSvg from '../SVGS/sock';
import ShortsSvg from '../SVGS/shorts';
import BrasSvg from '../SVGS/bras';
import {
  DEFAULT_PRODUCT_CHECKERS,
  UNDERWEAR_PRODUCT_CHECKERS,
} from '../../utils/defaultProductCheckers';
// Inline styles for ellipses button pop-up menu
const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getcontentanchorel={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
));

const CustomTableRow = ({
  data,
  classes,
  formatFirestoreTimestamp,
  setOpen,
  open,
  nonOrderRow,
  isDsOrder,
  props,
  renderModal,
  handlePrintButton,
  handleDownload,
  handleNotifyToShipstation,
  handleToggle,
  anchorEl,
  handleToggleClose,
  handleDownloadCSV,
  handleDownloadInventory,
  handleOpenDelete,
  setToastData,
  setShowToast,
  handleGetJobSheet,
}) => {
  const dateInfo = formatFirestoreTimestamp(data.completedAt);
  const dateInfoUpdated = dateInfo.replace(',', '');
  const dateParts = dateInfoUpdated.split(' ');

  const productTypeCheckers = data.productTypeCheckers;

  const displayProductTypes = (productTypeCheckers) => {
    if (!productTypeCheckers) {
      return '';
    }
    const result = [];

    // Check each flag and push the corresponding label into the result array
    if (productTypeCheckers.hasCustoms) {
      result.push('Custom');
    }
    if (productTypeCheckers.hasSocks) {
      result.push('In-Line');
    }

    // check if productTypeCheckers has underwear (hasBoxers, hasBoyShorts, hasSportsBras, hasYouthBoxers)
    if (
      Object.keys(UNDERWEAR_PRODUCT_CHECKERS).some(
        (key) => productTypeCheckers[key],
      )
    ) {
      result.push('UW');
    }

    // Join the array elements into a string, separated by " + "
    return result.length > 0 ? result.join(' + ') : '';
  };

  const {
    hasBoxers = false,
    hasCustoms = false,
    hasSocks = false,
    hasBoyShorts = false,
    hasSportsBras = false,
  } = data.productTypeCheckers || {};

  // Condition to determine what to show
  const showSock = hasSocks || hasCustoms;
  const showUnderwear = hasBoxers;
  const showShorts = hasBoyShorts;
  const showBras = hasSportsBras;

  const createdAt = new Date(data.createdAt.seconds * 1000); // Convert Firestore timestamp to JS Date
  const targetDate = new Date('2024-10-22T00:00:00'); // Date for 10/22/2024

  const wasCreatedAfterUpdate = createdAt > targetDate;

  const handleCopyBarcodeId = () => {
    console.log('Barcode ID: ', data.batchId);
    navigator.clipboard.writeText(data.batchId);
    setToastData({
      isError: false,
      message: `CopiedBarcode ID: ${data.batchId}`,
    });
    setShowToast(true);
  };

  return (
    <TableRow
      hover
      role="checkbox"
      tabIndex={-1}
      key={data.label}
      classes={{
        root: data?.odd ? classes.tableRow : classes.tableRow2,
        selected: classes.selectedTableRow,
      }}
      selected={open}
    >
      <TableCell>
        <IconButton
          aria-label="expand row"
          size="small"
          onClick={() => setOpen(!open)}
        >
          {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
        </IconButton>
      </TableCell>
      <TableCell
        component="th"
        scope="row"
        padding="none"
        className={classes.dateCell} // Use the new dateCell class
        onClick={() => setOpen(!open)}
      >
        <Typography variant="h4">{dateParts[0]}</Typography>
        <Typography variant="body1">
          {`${dateParts[1]} ${dateParts[2]}`} {/* Combine time and AM/PM */}
        </Typography>
      </TableCell>

      <TableCell
        align="center"
        className={classes.tableCell}
        onClick={() => setOpen(!open)}
      >
        <Box display="flex">
          {data.isError && (
            <ErrorOutlineIcon
              sx={{ color: '#E64A19', verticalAlign: 'middle' }}
            />
          )}
          {data.isWarning && (
            <AssignmentIcon
              sx={{ color: '#14B779', verticalAlign: 'middle' }}
            />
          )}
          {data.notes && data.notes !== '' && (
            <Tooltip
              title={
                <span
                  style={{
                    fontSize: '1.1rem',
                    lineHeight: '1.5',
                    color: '#FFFFFF',
                  }}
                >
                  {data.notes}
                </span>
              }
              arrow
              sx={{
                '& .MuiTooltip-tooltip': {
                  backgroundColor: 'black',
                  padding: '10px 15px',
                  borderRadius: '8px',
                },
              }}
            >
              <SpeakerNotesIcon
                sx={{
                  color: '#1F7CF9',
                  verticalAlign: 'middle',
                  cursor: 'pointer',
                }}
              />
            </Tooltip>
          )}
        </Box>
      </TableCell>
      <TableCell onClick={() => setOpen(!open)}>
        <Typography variant="tableText">{data.name}</Typography>
      </TableCell>
      <TableCell align="left" onClick={() => setOpen(!open)}>
        <Typography variant="tableText">
          {/* here if custom batch, show the name,*/}
          {data.source}
        </Typography>
      </TableCell>
      <TableCell align="left" onClick={() => setOpen(!open)}>
        <Typography variant="tableText">
          {displayProductTypes(productTypeCheckers)}
        </Typography>
      </TableCell>
      <TableCell align="left" onClick={() => setOpen(!open)}>
        <Typography variant="tableText">
          {data?.typeOfPress?.toUpperCase() || 'N/A'}
        </Typography>
      </TableCell>

      <TableCell align="center" onClick={() => setOpen(!open)}>
        <Typography variant="tableText">
          {Math.ceil(data?.numPrintSheetsTotal / 2) ?? 'Unknown'}
        </Typography>
      </TableCell>
      <TableCell align="center" onClick={() => setOpen(!open)}>
        <Typography variant="tableText">{data.oldestOrderDate}</Typography>
      </TableCell>
      <TableCell align="center" onClick={() => setOpen(!open)}>
        <Typography variant="tableText">
          {data?.wasPicked ? (
            <DoneIcon sx={{ color: 'green', fontSize: '40px' }} />
          ) : (
            <CloseIcon sx={{ color: 'red', fontSize: '40px' }} />
          )}
        </Typography>
      </TableCell>

      <TableCell align="center">
        {data.downloads > 0 ? (
          <Box display="flex" justifyContent="center" gap={2}>
            {wasCreatedAfterUpdate ? (
              <>
                {showSock && (
                  <Button
                    variant={data.didPrintSocks ? 'green' : 'blue'}
                    color="primary"
                    disabled={!props.editOnly}
                    onClick={() => handlePrintButton('socks')}
                    sx={{ borderRadius: '20%' }}
                  >
                    <SockSvg />
                  </Button>
                )}

                {/* Show underwear icon if the conditions match */}
                {showUnderwear && (
                  <Button
                    variant={data.didPrintUnderwear ? 'green' : 'blue'}
                    color="primary"
                    disabled={!props.editOnly}
                    onClick={() => handlePrintButton('underwear')}
                    sx={{
                      borderRadius: '20%',
                    }}
                  >
                    <UnderwearSvg />
                  </Button>
                )}
                {showShorts && (
                  <Button
                    variant={data.didPrintShorts ? 'green' : 'blue'}
                    color="primary"
                    disabled={!props.editOnly}
                    onClick={() => handlePrintButton('shorts')}
                    sx={{
                      borderRadius: '20%',
                    }}
                  >
                    <ShortsSvg />
                  </Button>
                )}
                {showBras && (
                  <Button
                    variant={data.didPrintBras ? 'green' : 'blue'}
                    color="primary"
                    disabled={!props.editOnly}
                    onClick={() => handlePrintButton('bras')}
                    sx={{
                      borderRadius: '20%',
                    }}
                  >
                    <BrasSvg />
                  </Button>
                )}
              </>
            ) : (
              <Button
                variant={data.printed ? 'green' : 'blue'}
                color="primary"
                onClick={() => handlePrintButton('printedFlag')}
              >
                <LocalPrintshopIcon />
              </Button>
            )}
          </Box>
        ) : (
          <Button
            variant="blue"
            disabled={!props.editOnly}
            onClick={(e) => handleDownload(false)}
            sx={{ width: '150px' }}
          >
            Download
          </Button>
        )}
      </TableCell>
      <TableCell align="center" className={classes.tableCell}>
        <Button
          aria-controls="customized-menu"
          aria-haspopup="true"
          variant="black"
          disabled={!props.editOnly}
          onClick={handleToggle}
        >
          <MoreHorizIcon />
        </Button>

        <StyledMenu
          id="customized-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleToggleClose}
        >
          {/* {data.source === "Order" && (
            <MenuItem onClick={handleOpenBackToQueue}>
              &nbsp;&nbsp;Back to Queue
            </MenuItem>
          )} */}
          <MenuItem onClick={() => handleDownload(false)}>
            &nbsp;&nbsp;Download
          </MenuItem>
          <MenuItem onClick={handleGetJobSheet}>&nbsp;&nbsp;Job Sheet</MenuItem>
          <MenuItem onClick={handleCopyBarcodeId}>
            &nbsp;&nbsp;Copy Barcode ID
          </MenuItem>
          <MenuItem onClick={handleDownloadCSV}>&nbsp;&nbsp;CSV</MenuItem>
          <MenuItem onClick={handleDownloadInventory}>
            &nbsp;&nbsp;Inventory
          </MenuItem>
          <MenuItem onClick={handleOpenDelete}>&nbsp;&nbsp;Delete</MenuItem>
          {nonOrderRow && !isDsOrder && (
            <MenuItem onClick={() => handleNotifyToShipstation(false)}>
              &nbsp;&nbsp;Tag in ShipStation
            </MenuItem>
          )}
        </StyledMenu>
        {renderModal()}
      </TableCell>
    </TableRow>
  );
};

export default CustomTableRow;
