export const getOldestOrderDate = (dates) => {
  if (!dates || dates.length === 0) {
    return null;
  }

  // Find the minimum timestamp in the array
  const oldestTimestamp = Math.min(...dates);

  // Format the date as MM/DD/YYYY
  const formattedDate = formatFirestoreTimestamp(oldestTimestamp);

  return formattedDate;
};
const formatFirestoreTimestamp = (timestamp) => {
  const date = new Date(timestamp * 1000);
  // Extract date parts
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const year = date.getFullYear().toString().slice(-2);

  // Extract time parts
  let hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12 || 12; // Convert to 12-hour format

  // Construct the formatted date string
  const formattedDate = `${month}/${day}/${year}`;
  const formattedTime = `${hours}:${minutes} ${ampm}`;

  return `${formattedDate} ${formattedTime}`;
};

const finishedGoods = ['knitted', 'hyperoptic', 'graphic tee', 'hat'];

export const getCountsOfItems = async (
  _orders,
  formats,
  sizes,
  db,
  isBelt,
  type,
  isWebQueue,
) => {
  const orders = _orders.reduce((acc, currentOrder) => {
    const orderExists = acc.find(
      (order) => order.orderNumber === currentOrder.orderNumber,
    );
    if (!orderExists) {
      acc.push(currentOrder);
    }
    return acc;
  }, []);

  const checkers = {
    hasSocks: false,
    hasBoxers: false,
    hasCustoms: false,
  };

  const sheetCounts = {
    socks: 0,
    underwear: 0,
  };

  const dates = [];
  // Process orders asynchronously using map
  const processOrders = orders.map(async (order) => {
    dates.push(order.created_at.seconds);
    const processItems = order.items.map(async (item) => {
      // Check the style of the item, skip it if it's "Graphic" (case insensitive) or if the style is not empty and it's not a box set
      if (item.style?.toLowerCase() !== 'graphic' && !item.isBoxSet) {
        // if knitted or hyperoptic: typwe is socsk so add it as hasSocks
        if (finishedGoods.includes(item.style?.toLowerCase())) {
          checkers.hasSocks = true;
        }
        // if cut & sew or graphic tee or hat: type is underwear so add it as hasBoxers
        if (['cut & sew'].includes(item.style?.toLowerCase())) {
          checkers.hasBoxers = true;
        }

        return;
      }

      // Handle Mixed BoxSets separately
      if (item.isBoxSet && item?.data?.type === 'Mixed') {
        return;
      }

      // If it's a box set, calculate the total quantity by multiplying the number of items in the box set
      let totalQuantity = item.quantity;

      if (item.isBoxSet && item?.data && isWebQueue) {
        item.format = item?.data?.format || null;
        item.size = item?.data?.size || null;
      } else if (item.isBoxSet && item?.data && !isWebQueue) {
        item.format = item?.data?.format || null;
        item.size = item?.data?.size || null;
      }

      if (item.isBoxSet && item.data?.items?.length && isWebQueue) {
        totalQuantity *= item.data.items.length; // Multiply by the number of items in the box set
      } else if (item.isBoxSet && item.items?.length && !isWebQueue) {
        totalQuantity *= item.items.length;
      }

      // **Check for custom products**: If `isCustomProduct` is true, set `hasCustoms` flag to true
      if (item.isCustomProduct) {
        checkers.hasCustoms = true;
      }

      // **First check**: Use the `type` property to detect socks or boxers
      if (item.type?.toLowerCase() === 'socks') {
        checkers.hasSocks = true;
        if (!item.isBoxSet) {
          sheetCounts.socks += item.quantity;
        } else {
          const bundleQuantity =
            item.items?.length || item.data.items.length || 1 * item.quantity;
          sheetCounts.socks += bundleQuantity;
        }
      } else if (item.type?.toLowerCase() === 'underwear') {
        checkers.hasBoxers = true;
        if (!item.isBoxSet) {
          sheetCounts.underwear += item.quantity;
        } else {
          const bundleQuantity =
            item.items?.length || item.data.items.length || 1 * item.quantity;
          console.log('UND: bundleQuantity: ', bundleQuantity);
          sheetCounts.underwear += bundleQuantity;
        }
      } else {
        // **Fallback check**: If `type` is missing, use the format
        const format = formats.find((f) => f.id === item.format);
        if (format) {
          if (format.type.toLowerCase() === 'socks') {
            checkers.hasSocks = true;
            sheetCounts.socks += item.quantity;
          } else if (format.type.toLowerCase() === 'underwear') {
            checkers.hasBoxers = true;
            sheetCounts.underwear += item.quantity;
          }
        }
      }
    });

    // Wait for all items to be processed
    await Promise.all(processItems);
  });

  // Wait for all orders to be processed
  await Promise.all(processOrders);
  const sheetCount = getSheetCount(sheetCounts, isBelt);
  const oldestOrderDate = getOldestOrderDate(dates);

  return { checkers, oldestOrderDate, sheetCount };
};

const getSheetCount = (sheetCounts, isBelt) => {
  const unitsPerBatchSocks = isBelt ? 14 : 5;
  const unitsPerBatchUnderwear = isBelt ? 3 : 2;
  const socks = Math.ceil(sheetCounts.socks / unitsPerBatchSocks);
  const underwear = Math.ceil(sheetCounts.underwear / unitsPerBatchUnderwear);
  return socks + underwear;
};
