import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import { Typography } from '@mui/material';

import PaulYester from '../../images/noResults.png';

function LoadingMicroBatch() {
  const [text] = useState('Loading batches ');
  const [dotCount, setDotCount] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setDotCount((prevCount) => (prevCount + 1) % 4);
    }, 500);

    return () => clearInterval(interval);
  }, []);

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      flexDirection="column"
      gap="20px"
    >
      <img src={PaulYester} alt="Paul Yester" width="225px" height="225px" />
      <Typography variant="h6">{text + '.'.repeat(dotCount)}</Typography>
    </Box>
  );
}

export default LoadingMicroBatch;
