import { doc, onSnapshot } from 'firebase/firestore';

import { collections } from '../constants/defines';

const getTotals = (db, callback) => {
  const docRef = doc(db, collections.COUNTS, 'data'); // Reference the specific document

  return onSnapshot(docRef, (docSnap) => {
    if (docSnap.exists()) {
      const data = docSnap.data();
      callback(data);
    } else {
      console.log('No such document!');
    }
  });
};

const getTotalsForSpecificProduct = (db, callback) => {
  const docRef = doc(db, collections.COUNTS, collections.COUNTS_SPECIFICS);

  return onSnapshot(docRef, (docSnap) => {
    if (docSnap.exists()) {
      const data = docSnap.data();
      callback(data);
    } else {
      console.log('No such document!');
    }
  });
};

export { getTotals, getTotalsForSpecificProduct };
