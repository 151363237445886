// src/store/themeSlice.ts
import { createSlice } from '@reduxjs/toolkit';

// Modify themeSlice.ts to initialize the mode from localStorage
const initialState = {
  microbatchedOrders: [],
  microbatchPageName: '',
  isLoading: true,
  populatedRolls: [],
};

const microbatchSlice = createSlice({
  name: 'microbatch',
  initialState,
  reducers: {
    populateMicrobatchedOrders: (state, action) => {
      // apply an id to each batch
      state.microbatchedOrders = action.payload.map((batch, index) => ({
        ...batch,
        id: index,
      }));
    },
    populateMicrobatchPageName: (state, action) => {
      state.microbatchPageName = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    removeBatch: (state, action) => {
      state.microbatchedOrders = state.microbatchedOrders.filter(
        (batch) => batch.id !== action.payload,
      );
    },
    populatePopulatedRolls: (state, action) => {
      state.populatedRolls = action.payload;
    },
  },
});

export const {
  populateMicrobatchedOrders,
  populateMicrobatchPageName,
  removeBatch,
  setIsLoading,
  populatePopulatedRolls,
} = microbatchSlice.actions;
export default microbatchSlice.reducer;
