import { useState } from 'react';
import {
  Box,
  Button,
  Typography,
  TextField,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { styles } from './styles';
import { transformTextToFirstCharCapital } from '../../db/types';

const ParamBox = ({
  label,
  items,
  onSelect,
  onAdd,
  onEdit,
  onDelete,
  isSelected,
}) => {
  const [isAdding, setIsAdding] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editValue, setEditValue] = useState('');
  const [confirmDelete, setConfirmDelete] = useState(false);

  const handleAddClick = () => setIsAdding(true);
  const handleInputChange = (e) =>
    setInputValue(transformTextToFirstCharCapital(e.target.value));

  const handleInputDone = () => {
    if (inputValue.trim()) {
      onAdd(transformTextToFirstCharCapital(inputValue.trim()));
    }
    setInputValue('');
    setIsAdding(false);
  };

  const handleDotClick = (e, item) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
    setSelectedItem(item);
  };

  const handleEdit = () => {
    setAnchorEl(null);
    setIsEditing(true);
    setEditValue(
      transformTextToFirstCharCapital(
        selectedItem?.name || selectedItem?.styleName || selectedItem,
      ),
    );
  };

  const handleDelete = () => {
    setAnchorEl(null);
    setConfirmDelete(true);
  };

  return (
    <Box sx={styles.paramBox}>
      <Typography variant="h6">{label}</Typography>
      <Box sx={styles.itemsContainer}>
        {items.map((item) => (
          <Button
            key={item.id || item.styleName || item}
            onClick={() => onSelect(item)}
            sx={{
              ...styles.itemButton,
              // Add highlight styles when selected
              ...(isSelected === item.id ||
                isSelected === item.styleName ||
                isSelected === item.name ||
                (isSelected === item && {
                  backgroundColor: '#bbdefb',
                })),
            }}
          >
            {item.name || item.styleName || item}
            <Box sx={styles.itemButtonIcon}>
              <MoreVertIcon onClick={(e) => handleDotClick(e, item)} />
              <ArrowForwardIosIcon />
            </Box>
          </Button>
        ))}
        {isAdding ? (
          <TextField
            value={inputValue}
            onChange={handleInputChange}
            onBlur={handleInputDone}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleInputDone();
              }
            }}
            placeholder={`Enter new ${label}`}
            variant="standard"
            sx={styles.inputField}
          />
        ) : (
          <Button endIcon={<AddCircleIcon />} onClick={handleAddClick}>
            <Typography variant="h6">Add Type</Typography>
          </Button>
        )}
      </Box>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem onClick={handleEdit}>Edit</MenuItem>
        <MenuItem onClick={handleDelete}>Delete</MenuItem>
      </Menu>

      <Dialog open={isEditing}>
        <DialogTitle>Edit {label}</DialogTitle>
        <DialogContent>
          <TextField
            value={editValue}
            onChange={(e) => setEditValue(e.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsEditing(false)}>Cancel</Button>
          <Button
            onClick={() => {
              onEdit(selectedItem, editValue);
              setIsEditing(false);
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={confirmDelete}>
        <DialogTitle>
          Delete {selectedItem?.name || selectedItem?.styleName || selectedItem}
          ?
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => setConfirmDelete(false)}>Cancel</Button>
          <Button
            onClick={() => {
              onDelete(selectedItem, label);
              setConfirmDelete(false);
            }}
            color="error"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ParamBox;
