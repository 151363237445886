import {
  populateMicrobatchedOrders,
  populatePopulatedRolls,
} from '../../redux/smartbatch/slice';
import { createRolls } from './createRolls';
import findAndProcessCustomsAndUnderwear from './findAndProcessCustomsAndUnderwear';
import findAndProcessCustomSocksAndInLineSocks from './findAndProcessCustomSocksAndInLineSocks';
import findAndProcessUnderwearAndSocks from './findAndProcessUnderwearAndSocks';
import findAndProcessUnderwearOnly from './findAndProcessUnderwearOnly';

import findAndProcessFinishedGoods from './finishedGoods';

import findAndProcessSocksOnly from './socksOnly';

const microbatchingModule = async (
  orders,
  isWebQueue = false,
  dispatch,
  db,
  sizes,
  formats,
  isBelt,
) => {
  let remainingOrders = [...orders]; // Start with all orders
  // remove any orders with Product not found errors
  remainingOrders = remainingOrders.filter(
    (order) =>
      !order.items.some((item) =>
        item.error.toLowerCase().includes('product not found'),
      ),
  );

  // Step 1: Process finished goods first
  const finishedGoodsBatch = await findAndProcessFinishedGoods(
    remainingOrders,
    isWebQueue,
    db,
    sizes,
    formats,
    'finishedGoods',
    isBelt,
  );

  // Flatten finishedGoodsBatch if it contains nested arrays
  const flattenedFinishedGoods = finishedGoodsBatch.flat();

  // Remove orders that are in finishedGoodsBatch from remainingOrders
  remainingOrders = remainingOrders.filter(
    (order) =>
      !flattenedFinishedGoods.some(
        (batchedOrder) => batchedOrder.orderNumber === order.orderNumber,
      ),
  );

  // Step 2: Process socks only
  const socksOnlyBatch = await findAndProcessSocksOnly(
    remainingOrders,
    isWebQueue,
    db,
    sizes,
    formats,
    'sockOnly',
    isBelt,
  );

  // only process objects that .sheetCount !== 3 and are lower than 3
  const filteredSocksOnlyBatches = socksOnlyBatch.filter(
    (batch) => batch.sheetCount !== 3 && batch.sheetCount < 3,
  );

  const ordersArray = [];
  filteredSocksOnlyBatches.forEach((batch) => {
    batch.rows.forEach((row) => {
      ordersArray.push(row); // Push each order into the ordersArray
    });
  });

  // Output the results
  // from the remainingOrders, find the orders that match the ordersArray.
  const remainingOrdersArray = remainingOrders.filter((order) =>
    ordersArray.some(
      (orderArray) => orderArray.orderNumber === order.orderNumber,
    ),
  );

  // // const customsAndUnderwearBatch = await findAndProcessCustomsAndUnderwear(remainingOrders, isWebQueue);
  const underwearOnlyBatch = await findAndProcessUnderwearOnly(
    remainingOrders,
    isWebQueue,
    db,
    sizes,
    formats,
    'underwearOnly',
    isBelt,
  );
  const underwearAndSocksBatch = await findAndProcessUnderwearAndSocks(
    remainingOrders,
    isWebQueue,
    remainingOrdersArray,
    db,
    sizes,
    formats,
    isBelt,
  );
  // const customSocksAndInLineSocksBatch = await findAndProcessCustomSocksAndInLineSocks(remainingOrders, isWebQueue);
  // // Step 2: Process by order type complexity (to be done in next steps)

  // Step 3: Compile categorized batches for prioritized display
  const prioritizedBatches = [
    ...finishedGoodsBatch,
    ...underwearOnlyBatch,
    ...socksOnlyBatch,
    ...underwearAndSocksBatch,
  ];

  const rolls = await createRolls(prioritizedBatches, isBelt);

  dispatch(populatePopulatedRolls(rolls));

  const clonedPrioritizedBatches = JSON.parse(
    JSON.stringify(prioritizedBatches),
  );

  dispatch(populateMicrobatchedOrders(clonedPrioritizedBatches));

  return prioritizedBatches;
};

export default microbatchingModule;
