import { doc, onSnapshot } from 'firebase/firestore';

import { collections } from '../constants/defines';

const getDsTotals = (db, callback) => {
  const docRef = doc(db, collections.DROPSHIP_COUNTS, 'data');

  return onSnapshot(docRef, (docSnap) => {
    if (docSnap.exists()) {
      const data = docSnap.data();
      callback(data);
    } else {
      console.log('No such document!');
    }
  });
};

const getTotalsForSpecificProductDropship = (db, callback) => {
  const docRef = doc(
    db,
    collections.DROPSHIP_COUNTS,
    collections.COUNTS_SPECIFICS,
  );

  return onSnapshot(docRef, (docSnap) => {
    if (docSnap.exists()) {
      const data = docSnap.data();
      callback(data);
    } else {
      console.log('No such document!');
    }
  });
};

export { getDsTotals, getTotalsForSpecificProductDropship };
