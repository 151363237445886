import { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import Button from '@mui/material/Button';
import _ from 'lodash';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import FilterDropdown from '../Page/filterDropdown';
import FilterContext from '../../context/filterContext';

const DEFAULT_OPTIONS = [{ id: 1, name: 'All', val: '' }];

import { _filterTemplates } from './Products.constants';

const useToolbarStyles = makeStyles(() => ({
  root: {
    padding: '50px 0 50px 16px',
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid',
    borderColor: 'rgba(217, 223, 233, 0.4)',
  },
  toolBox: {
    display: 'flex',
    width: 'calc(100% - 200px)',
    justifyContent: 'space-between',
  },
  filterBox: {
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    padding: '9px 32px!important',
    fontSize: '16px',
    fontWeight: '500!important',
    color: '#ffffff',
    textTransform: 'initial',
    marginRight: '16px',
    '&.Mui-disabled': {
      background: 'rgba(0, 0, 0, 0.12)!important',
      color: 'rgba(0, 0, 0, 0.26)',
    },
  },
  formLabel: {
    color: '#000000',
    fontSize: '14px',
    fontWeight: '500',
    letterSpacing: '1.5px',
  },
  iconBox: {
    textAlign: 'right',
    paddingRight: '30px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  viewIconBox: {
    padding: '0 5px',
    cursor: 'pointer',
  },
  viewIcon: {
    fontSize: '30px',
    color: '#e5e5e5',
  },
  viewActiveIcon: {
    fontSize: '30px',
    color: '#bebebe',
  },
}));

const constructorForType = (attributeName, nameOfFilter) => {
  let name = nameOfFilter;
  if (nameOfFilter === "Men's Boxer Briefs") {
    name = 'Boxer';
  }
  return `${attributeName}:="${name}"`;
};

const PageToolbar = (props) => {
  const { onChangeView, setFiltersPushed, selectedRows, handleDeleteAction } =
    props;
  const data = useContext(FilterContext);
  const classes = useToolbarStyles();
  const { handleNew } = props;
  const [styles, setStyles] = useState([...DEFAULT_OPTIONS]);
  const [formats, setFormats] = useState([...DEFAULT_OPTIONS]);
  const [sizes, setSizes] = useState([...DEFAULT_OPTIONS]);
  const [templates, setTemplates] = useState([..._filterTemplates]);
  const [hideStyleFilter, setHideStyleFilter] = useState(true);
  const [hideFormatFilter, setHideFormatFilter] = useState(true);
  const [hideSizeFilter, setHideSizeFilter] = useState(true);
  const [onStep, setOnStep] = useState(1);
  const editOnly = props.editOnly;

  useEffect(() => {
    setTemplates([...data.templates]);
  }, [data.templates]);

  const updateFilters = (type, value, isRemoving = false) => {
    setFiltersPushed((prevFilters) => {
      // When selecting 'All' or removing, remove this type and all dependent filters
      if (value === 'All' || isRemoving) {
        switch (type) {
          case 'style':
            // Remove style, format, and size filters
            return prevFilters.filter(
              (filter) =>
                !filter.startsWith('style:') &&
                !filter.startsWith('format:') &&
                !filter.startsWith('size:'),
            );
          case 'format':
            // Remove format and size filters
            return prevFilters.filter(
              (filter) =>
                !filter.startsWith('format:') && !filter.startsWith('size:'),
            );
          default:
            return prevFilters.filter(
              (filter) => !filter.startsWith(`${type}:`),
            );
        }
      }

      // Normal add case
      const filteredArray = prevFilters.filter(
        (filter) => !filter.startsWith(`${type}:`),
      );
      return [...filteredArray, constructorForType(type, value)];
    });
  };

  useEffect(() => {
    const itemType = _.find(data.types, { id: data.filterType });

    if (itemType.name !== 'All') {
      setOnStep(1);
      const stylesToPush = [...DEFAULT_OPTIONS];
      stylesToPush.push(...itemType.styles);
      setStyles([...stylesToPush]);
      setHideStyleFilter(false);
      updateFilters('type', itemType.name);
    } else {
      setOnStep(1);
      setHideStyleFilter(true);
      setFormats([...DEFAULT_OPTIONS]);
      setHideFormatFilter(true);
      setSizes([...DEFAULT_OPTIONS]);
      setHideSizeFilter(true);
      setStyles([...DEFAULT_OPTIONS]);
      setHideStyleFilter(true);
      updateFilters('type', 'All');
    }
  }, [data.filterType]);

  useEffect(() => {
    if (!hideStyleFilter) {
      const selectedStyleForFormats = _.find(styles, { id: data.filterStyle });
      const formatsToPush = [...DEFAULT_OPTIONS];

      if (selectedStyleForFormats.name === 'All') {
        setOnStep(1);
        setFormats([...DEFAULT_OPTIONS]);
        setHideFormatFilter(true);
        setSizes([...DEFAULT_OPTIONS]);
        setHideSizeFilter(true);
        updateFilters('style', 'All');
        return;
      }
      setOnStep(2);
      formatsToPush.push(...selectedStyleForFormats.formats);
      setFormats([...formatsToPush]);
      setHideFormatFilter(false);
      updateFilters('style', selectedStyleForFormats.name);
    }

    if (!hideFormatFilter) {
      const selectedFormatForSizes = _.find(formats, { id: data.filterFormat });
      const sizesToPush = [...DEFAULT_OPTIONS];

      if (selectedFormatForSizes.name === 'All') {
        setOnStep(2);
        setSizes([...DEFAULT_OPTIONS]);
        setHideSizeFilter(true);
        updateFilters('format', 'All');
        return;
      }

      setOnStep(3);
      sizesToPush.push(...selectedFormatForSizes.sizes);
      setSizes([...sizesToPush]);
      setHideSizeFilter(false);
      updateFilters('format', selectedFormatForSizes.name);
    }

    if (!hideSizeFilter) {
      const selectedSizeForTemplates = _.find(sizes, { id: data.filterSize });

      if (selectedSizeForTemplates.name === 'All') {
        setOnStep(3);
        updateFilters('size', 'All');
        return;
      }

      setOnStep(4);
      console.log('selectedSizeForTemplates: ', selectedSizeForTemplates);

      updateFilters('size', selectedSizeForTemplates.name);
    }
  }, [props.sizes, data.filterStyle, data.filterFormat, data.filterSize]);

  const onClickViewMode = (mode) => {
    console.log('onClickViewMode');
    if (onChangeView) {
      onChangeView(mode);
    }
  };

  const removeSpecificFormats = (formats, exclusions) =>
    formats.filter((format) => !exclusions.includes(format.name));

  return (
    <div className="pageToolbar">
      <div className={classes.buttonBox}>
        {selectedRows.length === 0 ? (
          <Button
            variant="blue"
            onClick={handleNew}
            disabled={!editOnly}
            sx={{ width: '170px' }}
          >
            Add Product
          </Button>
        ) : (
          <Button
            variant="red"
            onClick={handleDeleteAction}
            sx={{ width: '180px' }}
            // trash icon at the right
            endIcon={<DeleteOutlineIcon />}
          >
            Delete
          </Button>
        )}
      </div>

      <div className={classes.toolBox}>
        <div className={classes.filterBox}>
          <div sx={{ marginRight: '30px' }}>
            <FilterDropdown
              title="Type"
              items={data.types}
              active={data.filterType}
              onSelect={data.setFilterType}
            />
          </div>
          <div sx={{ marginRight: '30px' }}>
            <FilterDropdown
              title="Style"
              items={styles}
              active={data.filterStyle}
              onSelect={data.setFilterStyle}
              disabled={hideStyleFilter}
            />
          </div>
          <div sx={{ marginRight: '30px' }}>
            <FilterDropdown
              title="Format"
              items={removeSpecificFormats(formats, ['Mask'])}
              active={data.filterFormat}
              onSelect={data.setFilterFormat}
              isFormat={true}
              disabled={hideFormatFilter}
            />
          </div>

          <div sx={{ marginRight: '30px' }}>
            <FilterDropdown
              title="Size"
              items={sizes.map((size) => ({
                ...size,
                name: size.name === 'YTHL' ? 'Youth L' : size.name,
              }))}
              active={data.filterSize}
              onSelect={data.setFilterSize}
              disabled={hideSizeFilter}
            />
          </div>

          <div sx={{ marginRight: '30px' }}>
            <FilterDropdown
              title="Template"
              items={templates}
              active={data.filterTemplate}
              onSelect={data.setFilterTemplate}
            />
          </div>
        </div>
        <div className={classes.sortBox}>
          <div className={classes.iconBox}>
            <div className={classes.viewIconBox}>
              <ViewListIcon
                onClick={() => onClickViewMode('list')}
                className={
                  props.view === 'list'
                    ? classes.viewActiveIcon
                    : classes.viewIcon
                }
              />
            </div>
            <div className={classes.viewIconBox}>
              <ViewModuleIcon
                onClick={() => onClickViewMode('grid')}
                className={
                  props.view === 'grid'
                    ? classes.viewActiveIcon
                    : classes.viewIcon
                }
              />
            </div>
          </div>
          <FilterDropdown
            title="Show"
            items={data.shows}
            active={data.filterShow}
            onSelect={data.setFilterShow}
          />
        </div>
      </div>
    </div>
  );
};

PageToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default PageToolbar;
