import { collection, query, where, getDocs } from 'firebase/firestore';

import { collections } from '../constants/defines';

const collectionsToLookIn = [
  collections.INLINE_PRODUCTS_QUEUE,
  collections.CUSTOM_PRODUCTS_QUEUE,
  collections.DS_INLINE_QUEUE,
  collections.DS_CUSTOM_QUEUE,
];

/**
 * Handles errors and logs appropriate messages.
 * @param {string} message - The error message to log.
 * @param {Function} callback - A function to call with the error.
 * @param {Error} [errorInstance] - Optional error instance to pass to the callback.
 */
const handleError = (message, callback, errorInstance = null) => {
  console.error(message);
  callback(null, errorInstance || new Error(message));
};

/**
 * Fetches an order by orderNumber from the specified collections until found.
 * @param {Object} db - Firestore database instance.
 * @param {string} _orderNumber - The order number to search for.
 * @param {Function} callback - A function to call with the found document snapshot.
 */
export const fetchForAnyOrder = async (db, _orderNumber, callback) => {
  let orderNumber = _orderNumber;

  if (!orderNumber.startsWith('DS')) {
    orderNumber = Number(orderNumber);
  }

  try {
    for (const collectionName of collectionsToLookIn) {
      const queryRef = query(
        collection(db, collectionName),
        where('orderNumber', '==', orderNumber),
      );

      const querySnapshot = await getDocs(queryRef);

      if (!querySnapshot.empty) {
        console.log(`Order found in collection: ${collectionName}`);
        const data = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));

        if (data[0].orderNumber !== orderNumber) {
          handleError(
            'Order number mismatch in collection: ' + collectionName,
            callback,
          );
          return;
        }

        const neededData = sendBackNeededData(data[0]);

        const items = processDataToShow(neededData);

        callback(items);
        return; // Exit once the order is found
      }
    }

    handleError('Order not found in any collection.', callback);
  } catch (error) {
    handleError('Error fetching order: ' + error.message, callback, error);
  }
};

const sendBackNeededData = (data) => ({
  orderNumber: data?.orderNumber ?? 'N/A',
  createdAt: data?.created_at ?? data?.dsDate ?? null,
  labelPrinted: data?.labelPrinted ?? null,
  orderPrintedBy: data?.printedBy ?? null,
  orderPrintedOn: data?.printedOn ?? null,
  isDropShip: data?.isDropShip ?? false,
  packaged: data?.packaged ?? null,
});

// 1208436

// DS1023477533

const createEntry = (createdAt, user, status, orderNumber, type = 'Order') => ({
  createdAt,
  user,
  status,
  orderNumber,
  type,
});

const processDataToShow = (data) => {
  const result = [];

  const entryConfigs = [
    {
      condition: data?.createdAt,
      args: [data?.createdAt, 'Shopify', 'Order Placed', data?.orderNumber],
    },
    {
      condition: data?.orderPrintedOn,
      args: [
        data?.orderPrintedOn,
        data?.orderPrintedBy,
        'Print Sheet Files Made',
        data?.orderNumber,
      ],
    },
    {
      condition: data?.labelPrinted,
      args: [
        data?.labelPrinted?.printedAt,
        data?.labelPrinted?.email,
        'Label Printed',
        data?.orderNumber,
      ],
    },
    {
      condition: data?.packaged,
      args: [
        data?.packaged?.packagedAt,
        data?.packaged?.email,
        'Packaged',
        data?.orderNumber,
      ],
    },
  ];

  entryConfigs.forEach(({ condition, args }) => {
    if (condition) {
      result.push(createEntry(...args));
    }
  });

  return result;
};
