export const applySuffix = (product, formats, sizes) => {
  const format = formats?.find((format) => format?.id === product?.format);

  if (!format) {
    return product.sku;
  } // No format selected, return as is.

  // Define suffix based on format
  const suffixMap = {
    "Women's Boy Shorts": '-WBSH-L',
    "Women's Sports Bra": '-WBRA-L',
    'Youth Boxer Briefs': '-YBB-L',
    "Men's Boxer Briefs": '-MBB-L',
    Low: '-LOW-LXL',
    Crew: '-LXL',
  };

  let suffix = suffixMap[format.name] || '';

  // quickley check if the size is in the sizes array
  const size = sizes.find((size) => size.id === product.size);

  if (size) {
    if (size.sku === 'TDLR12') {
      suffix = '-TDLR12';
    }
    if (size.sku === 'TDLR24') {
      suffix = '-TDLR24';
    }
  }

  // If no suffix is needed, return the SKU as is
  if (!suffix) {
    return product.sku;
  }

  // Dynamically remove any existing suffix before applying a new one
  const suffixPattern = new RegExp(Object.values(suffixMap).join('|') + '$');
  const baseSku = product.sku.replace(suffixPattern, '');

  return baseSku.endsWith(suffix) ? baseSku : baseSku + suffix;
};

export const applySuffixToFraternal = (product, formats, sizes) => {
  const format = formats?.find((f) => f?.id === product?.format);
  if (!format) {
    return product.sku;
  }

  const size = sizes.find((s) => s.id === product.size);

  if (!size) {
    return product.sku;
  }

  const suffixMap = {
    Low: '-LOW-' + size.sku,
    Crew: '-' + size.sku,
  };

  const suffix = suffixMap[format.name] || '';

  // If no suffix is needed, return the SKU as is
  if (!suffix) {
    return product.sku;
  }

  // Dynamically remove any existing suffix before applying a new one
  const suffixPattern = new RegExp(Object.values(suffixMap).join('|') + '$');
  const baseSku = product.sku.replace(suffixPattern, '');

  return baseSku.endsWith(suffix) ? baseSku : baseSku + suffix;
};
