import { useState, useEffect } from 'react';
import {
  collection,
  query,
  where,
  getDocs,
  Timestamp,
  limit,
} from 'firebase/firestore';

import { collections } from '../constants/defines';

const useAveragesForOrders = (db) => {
  const debug = false;
  const daysToLookBack = 5;
  const queryLimit = 100;

  const orderCollections = [
    collections.INLINE_PRODUCTS_QUEUE,
    collections.DS_INLINE_QUEUE,
  ];

  const [timedAnalytics, setTimedAnalytics] = useState([
    {
      id: 1,
      title: 'Average Order to Print',
      data: { time: { days: 0, hours: 0, minutes: 0, seconds: 0 } },
      icon: 'printer',
    },
    {
      id: 2,
      title: 'Average Print to Ship',
      data: { time: { days: 0, hours: 0, minutes: 0, seconds: 0 } },
      icon: 'truck',
    },
    {
      id: 3,
      title: 'Average Order to Ship',
      data: { time: { days: 0, hours: 0, minutes: 0, seconds: 0 } },
      icon: 'mailbox',
    },
  ]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const convertHoursToTimeComponents = (hours) => {
    const totalSeconds = hours * 3600;
    return {
      days: Math.floor(totalSeconds / 86400),
      hours: Math.floor((totalSeconds % 86400) / 3600),
      minutes: Math.floor((totalSeconds % 3600) / 60),
      seconds: Math.floor(totalSeconds % 60),
    };
  };

  const calculateAverages = async () => {
    try {
      setLoading(true);

      const startDate = new Date();
      startDate.setDate(startDate.getDate() - daysToLookBack);
      startDate.setHours(0, 0, 0, 0);

      const queries = orderCollections.map(async (collectionName) => {
        const ordersRef = collection(db, collectionName);
        const q = query(
          ordersRef,
          where('created_at', '>=', Timestamp.fromDate(startDate)),
          where('labelPrinted', '!=', null),
          where('isPrinting', '==', true),
          limit(queryLimit),
        );

        const querySnapshot = await getDocs(q);
        let collectionCount = 0,
          collectionSkipped = 0;
        let totalOrderToPrint = 0,
          totalPrintToShip = 0,
          totalOrderToShip = 0;

        querySnapshot.forEach((doc) => {
          const data = doc.data();
          if (!data.labelPrinted?.printedAt || !data.printedOn) {
            collectionSkipped++;
            return;
          }

          try {
            const orderTime = data.created_at.toDate().getTime();
            const printTime = data.printedOn.toDate().getTime();
            const shipTime = data.labelPrinted.printedAt.toDate().getTime();

            totalOrderToPrint += (printTime - orderTime) / (1000 * 60 * 60);
            totalPrintToShip += (shipTime - printTime) / (1000 * 60 * 60);
            totalOrderToShip += (shipTime - orderTime) / (1000 * 60 * 60);
            collectionCount++;
          } catch (err) {
            if (debug) {
              console.error(
                `Error processing order ${doc.id} from ${collectionName}:`,
                err,
              );
            }
          }
        });

        return {
          collectionName,
          collectionCount,
          collectionSkipped,
          totalOrderToPrint,
          totalPrintToShip,
          totalOrderToShip,
        };
      });

      const results = await Promise.all(queries);
      let totalOrderToPrint = 0,
        totalPrintToShip = 0,
        totalOrderToShip = 0,
        totalCount = 0;

      const ordersByCollection = {};

      results.forEach(
        ({
          collectionName,
          collectionCount,
          collectionSkipped,
          totalOrderToPrint: colOrderToPrint,
          totalPrintToShip: colPrintToShip,
          totalOrderToShip: colOrderToShip,
        }) => {
          totalOrderToPrint += colOrderToPrint;
          totalPrintToShip += colPrintToShip;
          totalOrderToShip += colOrderToShip;
          totalCount += collectionCount;
          ordersByCollection[collectionName] = collectionCount;
          if (debug) {
            console.log(
              `${collectionName} - Orders processed: ${collectionCount}`,
            );
            console.log(
              `${collectionName} - Orders skipped: ${collectionSkipped}`,
            );
          }
        },
      );

      if (totalCount > 0) {
        setTimedAnalytics([
          {
            id: 1,
            title: 'Average Order to Print',
            data: {
              time: convertHoursToTimeComponents(
                totalOrderToPrint / totalCount,
              ),
            },
            icon: 'printer',
          },
          {
            id: 2,
            title: 'Average Print to Ship',
            data: {
              time: convertHoursToTimeComponents(totalPrintToShip / totalCount),
            },
            icon: 'truck',
          },
          {
            id: 3,
            title: 'Average Order to Ship',
            data: {
              time: convertHoursToTimeComponents(totalOrderToShip / totalCount),
            },
            icon: 'mailbox',
          },
        ]);
      }
      setLoading(false);
    } catch (err) {
      console.log('Error calculating averages:', err);
      setError(err.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    calculateAverages();
  }, [db]);

  return {
    timedAnalytics,
    loading,
    error,
  };
};

export default useAveragesForOrders;
