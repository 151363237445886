/* eslint-disable max-depth */
/* eslint-disable max-lines */
/* eslint-disable complexity */
/* eslint-disable max-statements */
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import FlipCameraAndroidIcon from '@mui/icons-material/FlipCameraAndroid';
import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  query,
  updateDoc,
  where,
} from 'firebase/firestore';

import { EnhancedTableHead } from '../Page/table';
import { handleCopyToBoard } from '../../utils/copyToClip';
import {
  formatSource,
  isDateExceedingFiveDays,
  dsRowStyle,
  mixMatchRowStyle,
} from '../../utils/fiveDaysExceederHelper';
import { ToastCopyCLip } from '../Toast/copyToClip';
import { collections } from '../../constants/defines';
import Loading from '../Loading';

const headCells = [
  {
    id: 'date',
    numeric: false,
    align: 'left',
    enableSort: true,
    disablePadding: true,
    label: 'DATE',
    width: '140px',
  },
  {
    id: 'order',
    numeric: true,
    align: 'left',
    enableSort: true,
    disablePadding: false,
    label: 'ORDER',
    width: '140px',
  },
  {
    id: 'type',
    numeric: false,
    align: 'center',
    enableSort: false,
    disablePadding: false,
    label: 'TYPE',
    width: '110px',
  },
  {
    id: 'sku',
    numeric: false,
    align: 'left',
    enableSort: false,
    disablePadding: false,
    label: 'SKU',
    width: 'auto',
  },
  {
    id: 'editSku',
    numeric: false,
    align: 'left',
    enableSort: false,
    disablePadding: false,
    label: '',
    width: 'auto',
  },
  {
    id: 'shipping',
    numeric: false,
    align: 'left',
    enableSort: false,
    disablePadding: false,
    label: 'Shipping',
    width: 'auto',
  },
  {
    id: 'qty',
    numeric: true,
    align: 'center',
    enableSort: false,
    disablePadding: false,
    label: 'QTY',
    width: '60px',
  },
  {
    id: 'source',
    numeric: false,
    align: 'center',
    enableSort: false,
    disablePadding: false,
    label: 'SOURCE',
    width: '100px',
  },
];

export const WebOrderTable = ({
  orders,
  classes,
  onSelectOrder,
  selectedOrders,
  tableOrder,
  orderBy,
  handleRequestSort,
  loading,
  db,
}) => {
  const [toastMessage, setToastMessage] = useState('');
  const [showToast, setShowToast] = useState(false);

  const [editIndex, setEditIndex] = useState(null); // Tracks the row being edited
  const [editSku, setEditSku] = useState(''); // Tracks the edited SKU

  const handleEditClick = (index, sku) => {
    setEditIndex(index);
    setEditSku(sku);
  };

  const handleSaveClick = async (data) => {
    try {
      let foundBoxset = false;
      const oldSku = data.sku;
      const newSku = editSku;
      const isCustomProduct = data.containsCustomProduct;
      const collectionToUse = isCustomProduct
        ? collections.CUSTOM_PRODUCTS_QUEUE
        : collections.INLINE_PRODUCTS_QUEUE;

      const docRef = doc(db, collectionToUse, data.orderNumber);
      // look in the collection and find the doc with the same sku and update it. Sku will be in items array
      const querySnapshot = await getDoc(docRef);
      const items = querySnapshot.data().items;
      const itemToUpdate = items.find((item) => item.sku === oldSku);

      const queryConstraint = query(
        collection(db, collections.PRODUCTS),
        where('sku', '==', newSku),
        limit(1),
      );
      const snapshot = await getDocs(queryConstraint);
      let product = snapshot.docs[0]?.data();

      if (!product) {
        console.log('no product found, looking for variant');
        const variantQuery = query(
          collection(db, collections.PRODUCTS),
          where('variants', 'array-contains', newSku),
        );
        const variantSnapshot = await getDocs(variantQuery);
        product = variantSnapshot.docs[0]?.data();
        if (!product) {
          console.log('no variant found, looking for boxset');
          // look in boxsets collection for a boxset that includes the newSku
          const boxsetQuery = query(
            collection(db, collections.BOXSETS),
            where('sku', '==', newSku),
          );
          const boxsetSnapshot = await getDocs(boxsetQuery);
          product = boxsetSnapshot.docs[0]?.data();
          if (!product) {
            console.log('no boxset found, looking for variant in boxsets');
            // look in the products collection for a sku that includes the newSku
            const productQuery = query(
              collection(db, collections.BOXSETS),
              where('variants', 'array-contains', newSku),
            );
            const productSnapshot = await getDocs(productQuery);
            product = productSnapshot.docs[0]?.data();
            if (!product) {
              alert('Product not found: ' + newSku);
              return;
            } else {
              foundBoxset = true;
            }
          } else {
            foundBoxset = true;
          }
        }
      }

      itemToUpdate.sku = newSku;
      itemToUpdate.size = product?.size || '';
      itemToUpdate.exists = true;
      itemToUpdate.error = '';
      itemToUpdate.available = true;
      itemToUpdate.style = product?.style || '';
      itemToUpdate.type = product?.type || '';
      itemToUpdate.isBoxSet = foundBoxset || false;
      itemToUpdate.format = product?.format || '';

      if (foundBoxset) {
        itemToUpdate.data = product;
      }

      await updateDoc(docRef, { items: items });

      setEditIndex(null); // Exit edit mode
      setEditSku(''); // Clear input
    } catch (error) {
      console.error('Error updating order:', error);
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Table
        className={classes.table}
        aria-labelledby="tableTitle"
        size="medium"
        aria-label="enhanced table"
      >
        <EnhancedTableHead
          useCheckbox={true}
          cells={headCells}
          order={tableOrder}
          orderBy={orderBy}
          rowCount={orders.length}
          onRequestSort={handleRequestSort}
        />

        {orders.length > 0 && !loading ? (
          <TableBody>
            {orders.map((row, index) => {
              const hasBeenRestored = row.restoreDate ? true : false;
              const labelId = `enhanced-table-checkbox-${index}`;
              const keyId = `key-${row.orderNumber}-${index}`;
              const isItemSelected = selectedOrders.some(
                (order) =>
                  order.orderNumber.toString() === row.orderNumber.toString(),
              );

              if (row.isMixAndMatch) {
                if (!row.properties[1].value) {
                  row.sku = "Mix N' Match";
                }
                row.sku = `Mix N' Match - ${row.properties[1].value}`;
                row.style = 'Finished Good';
                row.type = 'HYPEROPTIC';
              } else if (row.type === 'T-Shirt') {
                row.style = 'Finished Good';
              }

              const handleCheckboxClick = (event) => {
                event.stopPropagation();
                onSelectOrder(row.orderData);
              };
              const socks = row.isMixAndMatch
                ? row.properties
                    .find((prop) => prop.name === 'Socks')
                    .value.split(' and ')
                : [];

              const hasMoreToBeMade = row?.preInventory?.needsToMakeMore;
              const hasInventoryToUse =
                row?.preInventory &&
                row?.preInventory?.location !== '' &&
                !row?.preInventory?.needsToMakeMore;

              return (
                <React.Fragment>
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={keyId}
                    selected={isItemSelected}
                    classes={{
                      root: row.odd ? classes.tableRow : classes.tableRow2,
                      selected: classes.selectedTableRow,
                    }}
                    className="no-select"
                    role="checkbox"
                    aria-checked={isItemSelected}
                    onClick={() => onSelectOrder(row.orderData)}
                  >
                    <TableCell
                      padding="checkbox"
                      className={classes.tableCheckbox}
                    >
                      {row.index === 0 && (
                        <Checkbox
                          sx={{ paddingRight: '40px' }}
                          color="primary"
                          checked={isItemSelected}
                          onClick={handleCheckboxClick}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      )}
                    </TableCell>

                    <TableCell
                      padding="none"
                      onClick={() =>
                        handleCopyToBoard(
                          `Copied Date: ${row.createDate}`,
                          row.createDate,
                          setToastMessage,
                          setShowToast,
                        )
                      }
                      sx={{
                        cursor: 'pointer',
                      }}
                    >
                      <Typography
                        variant="tableText"
                        sx={{
                          fontWeight: isDateExceedingFiveDays(row.createDate)
                            ? 'bold'
                            : 'normal',
                          color: isDateExceedingFiveDays(row.createDate)
                            ? '#FF0000'
                            : 'inherit',
                        }}
                      >
                        {row.index === 0 ? row.createDate : ''}
                      </Typography>
                    </TableCell>

                    <TableCell
                      align="left"
                      onClick={() =>
                        handleCopyToBoard(
                          `Copied Order Number: ${row.orderNumber}`,
                          row.orderNumber,
                          setToastMessage,
                          setShowToast,
                        )
                      }
                      sx={{
                        cursor: 'pointer',
                      }}
                    >
                      <Typography
                        variant="tableText"
                        sx={dsRowStyle(row.createDate, row, hasInventoryToUse)}
                      >
                        {row.index === 0 ? row.orderNumber : ''}
                      </Typography>
                      {hasBeenRestored && (
                        <Box className={classes.restoreIcon}>
                          <FlipCameraAndroidIcon sx={{ color: 'blue' }} />
                        </Box>
                      )}
                    </TableCell>
                    <TableCell
                      align="center"
                      onClick={() =>
                        handleCopyToBoard(
                          `Copied type: ${row.type}`,
                          row.type,
                          setToastMessage,
                          setShowToast,
                        )
                      }
                      sx={{
                        cursor: 'pointer',
                      }}
                    >
                      <Typography
                        variant="tableText"
                        sx={dsRowStyle(row.createDate, row, hasInventoryToUse)}
                      >
                        {row.type}
                      </Typography>
                    </TableCell>

                    <TableCell>
                      {editIndex === index ? (
                        <TextField
                          value={editSku}
                          onChange={(e) => setEditSku(e.target.value)}
                          sx={{ width: '100%' }}
                        />
                      ) : (
                        <Typography
                          variant={
                            row.containsCustomProduct
                              ? row.exists
                                ? 'tableText'
                                : 'tableErrorText'
                              : row.exists && row.available && !row.error
                                ? 'tableText'
                                : 'tableErrorText'
                          }
                          sx={dsRowStyle(
                            row.createDate,
                            row,
                            hasInventoryToUse,
                          )}
                        >
                          {row.sku}
                        </Typography>
                      )}
                      <Typography
                        variant="tableErrorText"
                        sx={{ marginLeft: '10px' }}
                      >
                        {row.error}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {row.error === 'Product Not Found' ? (
                        editIndex === index ? (
                          <Button
                            variant="contained"
                            color="primary"
                            sx={{
                              width: '100px',
                              height: '30px',
                              paddingRight: '10px',
                            }}
                            onClick={() => handleSaveClick(row)}
                          >
                            Save
                          </Button>
                        ) : (
                          <Button
                            variant="outlined"
                            color="secondary"
                            sx={{
                              width: '100px',
                              height: '30px',
                              marginRight: '10px',
                            }}
                            onClick={() => handleEditClick(index, row.sku)}
                          >
                            Edit
                          </Button>
                        )
                      ) : null}
                    </TableCell>

                    <TableCell align="left">
                      <Typography
                        variant="tableText"
                        sx={dsRowStyle(row.createDate, row, hasInventoryToUse)}
                      >
                        {row.shipping}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography
                        variant="tableText"
                        sx={dsRowStyle(row.createDate, row, hasInventoryToUse)}
                      >
                        {row.quantity}

                        {hasMoreToBeMade && (
                          <Tooltip
                            title={`Line item has partial count in inventory: ${row?.preInventory?.inventoryCountForItem}`}
                            arrow
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  fontSize: '1rem', // Increases tooltip text size
                                  padding: '10px', // Adds padding inside the tooltip
                                  backgroundColor: '#333', // Optional: Darker background for contrast
                                },
                              },
                            }}
                          >
                            <span
                              style={{
                                color: 'green',
                              }}
                            >
                              ({row?.preInventory?.inventoryCountForItem})
                            </span>
                          </Tooltip>
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography
                        variant="tableText"
                        sx={dsRowStyle(row.createDate, row, hasInventoryToUse)}
                      >
                        {formatSource(row.source)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  {row.isMixAndMatch &&
                    socks.map((sock, sockIndex) => (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={`${keyId}-sock-${sockIndex}`}
                        classes={{
                          root: row.odd ? classes.tableRow : classes.tableRow2,
                          selected: classes.selectedTableRow,
                        }}
                        className="no-select"
                        selected={isItemSelected}
                      >
                        <TableCell
                          padding="checkbox"
                          className={classes.tableCheckbox}
                        >
                          {row.index === 0 && (
                            <Checkbox
                              sx={{
                                display: 'none',
                              }}
                              onClick={(event) => handleRowClick(event, row)}
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{ 'aria-labelledby': labelId }}
                            />
                          )}
                        </TableCell>
                        <TableCell padding="none">
                          <Typography
                            variant="tableText"
                            sx={{ display: 'none' }}
                          >
                            {row.index === 0 ? row.createDate : ''}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography
                            variant="tableText"
                            sx={{ display: 'none' }}
                          ></Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            variant="tableText"
                            sx={{ display: 'none' }}
                          ></Typography>
                        </TableCell>
                        <TableCell
                          align="left"
                          onClick={() =>
                            handleCopyToBoard(
                              `Copied SKU: ${sock}`,
                              sock,
                              setToastMessage,
                              setShowToast,
                            )
                          }
                          sx={{ position: 'relative', paddingLeft: '30px' }}
                        >
                          <Typography
                            variant={
                              row.exists && row.available
                                ? 'tableText'
                                : 'tableErrorText'
                            }
                            sx={mixMatchRowStyle(row.createDate, row, true)}
                          >
                            {sock}
                          </Typography>
                          <Typography
                            variant="tableErrorText"
                            sx={{ marginLeft: '10px' }}
                          ></Typography>

                          <Box
                            sx={{
                              position: 'absolute',
                              top: 0,
                              left: '-10px', // Adjust to position the vertical line
                              width: '20px', // Width of the horizontal connector line
                              height: '100%',
                              '&::before': {
                                content: '""',
                                position: 'absolute',
                                top: 0,
                                left: '50%',
                                width: '2px',
                                height:
                                  sockIndex === socks.length - 1
                                    ? '50%'
                                    : '100%', // Conditional height
                                backgroundColor: '#9714EA',
                              },
                              '&::after': {
                                content: '""',
                                position: 'absolute',
                                top: '50%',
                                left: '90%',
                                width: '15px', // Length of the horizontal line, adjust as needed
                                height: '2px',
                                backgroundColor: '#9714EA',
                                transform: 'translateX(-50%)',
                              },
                            }}
                          ></Box>
                        </TableCell>
                        <TableCell align="left">
                          <Typography
                            variant="tableText"
                            sx={{ display: 'none' }}
                          ></Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            variant="tableText"
                            sx={{ display: 'none' }}
                          ></Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            variant="tableText"
                            sx={mixMatchRowStyle(row.createDate, row, true)}
                          >
                            {formatSource(row.source)}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                </React.Fragment>
              );
            })}
          </TableBody>
        ) : (
          <TableBody>
            <TableRow>
              <TableCell colSpan={7} align="center">
                <Typography>No orders found</Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        )}
      </Table>
      <ToastCopyCLip show={showToast} message={toastMessage} />
    </>
  );
};
