export const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
  paramBox: {
    display: 'flex',
    flexDirection: 'column',
    width: '25%',
    height: '40vh',
    padding: '10px',
    borderRight: '1px solid black',
  },
  itemsContainer: {
    padding: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '10px',
    width: '100%',
  },
  itemButton: {
    width: '100%',
    alignSelf: 'flex-start',
    textAlign: 'left',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    height: '50px',
    '&:hover': {
      backgroundColor: '#F0F0F0',
      borderRadius: '0px',
    },
  },
  itemButtonIcon: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '20px',
    paddingRight: '10px',
  },
  inputField: {
    width: '70%',
    height: '50px',
    borderRadius: '5px',
  },
};
