import { useEffect, useState } from 'react';
import {
  Typography,
  Box,
  Button,
  Modal,
  Paper,
  Divider,
  Tabs,
  Tab,
  ListItem,
  ListItemText,
  List,
  Collapse,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { doc, getDoc, onSnapshot, setDoc } from 'firebase/firestore';
import ReactMarkdown from 'react-markdown';

import { collections } from '../../constants/defines';
import Firebase from '../Firebase';

const firebaseInstance = new Firebase();

const styles = {
  modalOverlay: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalContent: {
    backgroundColor: 'white',
    padding: '24px',
    borderRadius: '12px',
    color: 'black',
    maxWidth: '800px',
    width: '90%',
    maxHeight: '85vh',
    overflowY: 'auto',
    position: 'relative',
    boxShadow: '0 12px 35px rgba(0, 0, 0, 0.15)',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '20px',
    textAlign: 'center',
  },
  title: {
    fontSize: '28px',
    fontWeight: '700',
    color: '#1a1a1a',
    margin: '0',
  },
  version: {
    fontSize: '18px',
    fontWeight: '500',
    color: '#666',
    margin: '8px 0 0',
  },
  tabs: {
    marginBottom: '16px',
    borderBottom: '1px solid #e0e0e0',
  },
  card: {
    marginBottom: '16px',
    border: '1px solid #e0e0e0',
    boxShadow: 'none',
  },
  cardTitle: {
    fontSize: '18px',
    fontWeight: '600',
    color: '#333',
    marginBottom: '8px',
  },
  changeDetail: {
    fontSize: '16px',
    color: '#555',
    marginBottom: '16px',
  },
  expandButton: {
    color: '#3f51b5',
    fontWeight: '500',
    textTransform: 'none',
    padding: '4px 0',
  },
  mdContent: {
    '& ul': {
      paddingLeft: '20px',
      margin: '8px 0',
    },
    '& p': {
      margin: '8px 0',
    },
    '& h3': {
      marginTop: '16px',
      marginBottom: '8px',
      fontSize: '18px',
      fontWeight: '600',
    },
    '& code': {
      backgroundColor: '#f5f5f5',
      padding: '2px 4px',
      borderRadius: '4px',
      fontFamily: 'monospace',
    },
    '& pre': {
      backgroundColor: '#f5f5f5',
      padding: '12px',
      borderRadius: '4px',
      overflowX: 'auto',
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '24px',
  },
  acceptButton: {
    padding: '10px 32px',
    borderRadius: '8px',
    fontSize: '16px',
    fontWeight: '600',
  },
  changeItem: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: '8px',
    paddingBottom: '8px',
  },
  changeItemPrimary: {
    fontWeight: '500',
    fontSize: '18px',
  },
  extendedContent: {
    backgroundColor: '#f9f9f9',
    padding: '16px',
    borderRadius: '8px',
    marginTop: '8px',
    marginBottom: '8px',
  },
};

// This will parse and structure our release notes
const parseReleaseNotes = (releaseData) => {
  // If we already have structured data, use it
  if (releaseData.categories && Array.isArray(releaseData.categories)) {
    return releaseData.categories;
  }

  // Otherwise, try to parse from markdown notes
  const notes = releaseData.notes || '';
  const categories = [];

  // First, split by major categories (## headings)
  const categoryRegex = /##\s+(.*?)(?=##|$)/gs;
  const categoryMatches = [...notes.matchAll(categoryRegex)];

  if (categoryMatches.length > 0) {
    categoryMatches.forEach((match) => {
      const categoryTitle = match[1].trim();
      const categoryContent = match[0].substring(match[1].length + 3).trim();

      // Now find individual changes (may be marked with - or as ### subheadings)
      const changes = [];

      // Look for ### subheadings first
      const subheadingRegex = /###\s+(.*?)(?=###|$)/gs;
      const subheadingMatches = [...categoryContent.matchAll(subheadingRegex)];

      if (subheadingMatches.length > 0) {
        subheadingMatches.forEach((subMatch) => {
          const title = subMatch[1].trim();
          const content = subMatch[0].substring(subMatch[1].length + 4).trim();
          changes.push({ title, description: content, extended: true });
        });
      } else {
        // If no subheadings, look for bullet points
        const bulletRegex = /[-*]\s+(.*?)(?=([-*]|$))/gs;
        const bulletMatches = [...categoryContent.matchAll(bulletRegex)];

        if (bulletMatches.length > 0) {
          bulletMatches.forEach((bulletMatch) => {
            const bulletText = bulletMatch[1].trim();

            // Check if bullet contains a colon - could indicate title:description format
            if (bulletText.includes(':')) {
              const [title, ...descParts] = bulletText.split(':');
              const description = descParts.join(':').trim();
              changes.push({
                title: title.trim(),
                description,
                extended: description.length > 100,
              });
            } else {
              changes.push({
                title: bulletText,
                description: '',
                extended: false,
              });
            }
          });
        } else {
          // If no bullet points either, use the whole content as one change
          changes.push({
            title: 'Changes',
            description: categoryContent,
            extended: true,
          });
        }
      }

      categories.push({
        name: categoryTitle,
        changes,
      });
    });
  } else {
    // If no categories found, just create a single "Updates" category
    categories.push({
      name: 'Updates',
      changes: [
        {
          title: 'All Changes',
          description: notes,
          extended: true,
        },
      ],
    });
  }

  return categories;
};

const UpdateAlert = ({ userId }) => {
  const [showAlert, setShowAlert] = useState(false);
  const [releaseVersion, setReleaseVersion] = useState('');
  const [releaseDate, setReleaseDate] = useState('');
  const [categories, setCategories] = useState([]);
  const [currentTab, setCurrentTab] = useState(0);
  const [expandedStates, setExpandedStates] = useState({});

  const hardcodedReleaseTimestamp = '2025-03-25';

  useEffect(() => {
    if (location.pathname !== '/') {
      return;
    }

    const latestUpdateRef = doc(
      firebaseInstance.db,
      collections.UPDATES,
      'latestRelease',
    );

    const userUpdateRef = doc(firebaseInstance.db, collections.USERS, userId);

    const unsubscribe = onSnapshot(latestUpdateRef, async (snapshot) => {
      if (snapshot.exists()) {
        const latestReleaseData = snapshot.data();
        const latestReleaseTimestamp = latestReleaseData.timestamp;

        setReleaseVersion(latestReleaseData.version || '');
        setReleaseDate(
          latestReleaseData.date ||
            new Date(latestReleaseTimestamp).toLocaleDateString(),
        );

        // Parse or use structured categories
        const parsedCategories = parseReleaseNotes(latestReleaseData);
        setCategories(parsedCategories);

        // Initialize expanded states
        const initialExpandedStates = {};
        parsedCategories.forEach((category, catIndex) => {
          category.changes.forEach((change, changeIndex) => {
            initialExpandedStates[`${catIndex}-${changeIndex}`] = false;
          });
        });
        setExpandedStates(initialExpandedStates);

        try {
          const userDoc = await getDoc(userUpdateRef);
          const userLastUpdateReceived =
            userDoc?.data()?.lastUpdateReceived || '';

          if (userLastUpdateReceived !== latestReleaseTimestamp) {
            setShowAlert(true);
          }
        } catch (error) {
          console.error("Error checking user's last update:", error);
        }
      }
    });

    return () => unsubscribe();
  }, [userId]);

  const handleAcknowledgeUpdate = async () => {
    const userUpdateRef = doc(firebaseInstance.db, collections.USERS, userId);
    try {
      await setDoc(
        userUpdateRef,
        { lastUpdateReceived: hardcodedReleaseTimestamp },
        { merge: true },
      );
      setShowAlert(false);
      window.location.reload();
    } catch (error) {
      console.error('Error acknowledging update:', error);
    }
  };

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const toggleExpandedState = (categoryIndex, changeIndex) => {
    const key = `${categoryIndex}-${changeIndex}`;
    setExpandedStates((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  return (
    <Modal open={showAlert} sx={styles.modalOverlay}>
      <Paper sx={styles.modalContent}>
        {/* Header */}
        <Box sx={styles.header}>
          <Typography sx={styles.title}>Update Available</Typography>
          <Typography sx={styles.version}>
            Version {releaseVersion} • {releaseDate}
          </Typography>
        </Box>

        <Divider />

        {/* Category Tabs */}
        {categories.length > 1 && (
          <Tabs
            value={currentTab}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
            sx={styles.tabs}
          >
            {categories.map((category, index) => {
              if (category.changes.length === 0) {
                return null;
              }
              return <Tab key={index} label={category.name} />;
            })}
          </Tabs>
        )}

        {/* Content */}
        {categories.length > 0 && currentTab < categories.length && (
          <Box sx={{ mt: 2 }}>
            <List disablePadding>
              {categories[currentTab].changes.map((change, changeIndex) => (
                <ListItem
                  key={changeIndex}
                  sx={styles.changeItem}
                  disableGutters
                >
                  <Box sx={{ width: '100%' }}>
                    <ListItemText
                      primary={change.title}
                      primaryTypographyProps={{ sx: styles.changeItemPrimary }}
                      secondary={
                        change.description && !change.extended
                          ? change.description
                          : null
                      }
                    />

                    {change.extended && change.description && (
                      <>
                        <Button
                          sx={styles.expandButton}
                          onClick={() =>
                            toggleExpandedState(currentTab, changeIndex)
                          }
                          endIcon={
                            expandedStates[`${currentTab}-${changeIndex}`] ? (
                              <ExpandLessIcon />
                            ) : (
                              <ExpandMoreIcon />
                            )
                          }
                        >
                          {expandedStates[`${currentTab}-${changeIndex}`]
                            ? 'Show Less'
                            : 'Show Details'}
                        </Button>

                        <Collapse
                          in={expandedStates[`${currentTab}-${changeIndex}`]}
                        >
                          <Box sx={styles.extendedContent}>
                            <Box sx={styles.mdContent}>
                              <ReactMarkdown>
                                {change.description}
                              </ReactMarkdown>
                            </Box>
                          </Box>
                        </Collapse>
                      </>
                    )}

                    <Divider sx={{ mt: 2 }} />
                  </Box>
                </ListItem>
              ))}
            </List>
          </Box>
        )}

        {/* Button */}
        <Box sx={styles.buttonContainer}>
          <Button
            variant="outlined"
            onClick={handleAcknowledgeUpdate}
            sx={styles.acceptButton}
          >
            Update Now
          </Button>
        </Box>
      </Paper>
    </Modal>
  );
};

export default UpdateAlert;
