export const transformConsumerData = (items) => {
  const result = {};

  // Process each product entry
  Object.entries(items).forEach(([key, count]) => {
    // Split by hyphen to get parts
    const parts = key.split('-');
    if (parts.length < 3) {
      return;
    }

    const type = parts[0].toLowerCase();
    const format = parts[1].toLowerCase();
    const size = parts[2];

    // Create nested structure
    if (!result[type]) {
      result[type] = {};
    }

    if (!result[type][format]) {
      result[type][format] = {};
    }

    result[type][format][size] = count;
  });

  return result;
};

export const getFormatTotalsForBoxGraph = (product) => {
  const formatTotals = {};

  // Calculate total across all formats
  Object.entries(product.formats).forEach(([formatName, sizeData]) => {
    // Initialize format total
    formatTotals[formatName] = 0;

    // Sum all sizes within this format
    Object.values(sizeData).forEach((count) => {
      formatTotals[formatName] += count;
    });
  });

  // using the product.formats, create an array of objects with the format name, and the sizes with the count
  const formatedSizeAndFormatData = Object.entries(product.formats).map(
    ([format, sizeData]) => ({
      format,
      sizes: Object.entries(sizeData).map(([size, count]) => ({
        size,
        count,
      })),
    }),
  );

  return {
    formatTotalsForBigBoxGraph: Object.values(formatTotals),
    miniBoxGraphData: formatTotalsArray(formatTotals),
    formatedSizeAndFormatData,
  };
};

const formatTotalsArray = (data) =>
  Object.entries(data).map(([format, value]) => ({
    format,
    value,
  }));
