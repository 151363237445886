import { finishedGoodsStyle } from './constants';
import sortAndBatchOrders from './sortingFunction';

const findAndProcessFinishedGoods = async (
  orders,
  isWebQueue = false,
  db,
  sizes,
  formats,
  type,
  isBelt,
) => {
  // Remove orders with empty items directly from the orders array
  orders = orders.filter((order) => order.items && order.items.length > 0);

  // Filter orders based on specific criteria within each item
  const ordersFiltered = orders.filter((row) =>
    row.items.every((item) => {
      const style = item.style.toLowerCase();
      const didFind = finishedGoodsStyle.includes(style);
      return didFind || item.isMixAndMatch;
    }),
  );

  const rows = sortAndBatchOrders(
    ordersFiltered,
    isWebQueue,
    false,
    db,
    sizes,
    formats,
    type,
    isBelt,
  );

  return rows;
};

export default findAndProcessFinishedGoods;
