import * as React from 'react';

const ShortsSvg = (props) => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width={45} // Decrease this value
    height={45} // Decrease this value
    viewBox="0 0 150 150" // Keep this the same for proper scaling
    enableBackground="new 0 0 141.732 141.732"
    xmlSpace="preserve"
    {...props}
  >
    <g>
      <g id="Layer_2">
        <path
          d="M109.9,34.6H31.8s-17.6,57.8-17.6,57.8l52.3,14.7,4.4-11.2,4.4,11.2,52.3-14.7-17.6-57.8ZM74.7,86.6h-7.7l-4.8,12.1-39.3-11.1,11.8-38.5h72.4s11.8,38.5,11.8,38.5l-39.3,11.1-4.8-12.1Z"
          fill={props.color ? props.color : 'white'}
        />
      </g>
    </g>
  </svg>
);
export default ShortsSvg;
