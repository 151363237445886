import React, { useState } from 'react';
import {
  Box,
  Typography,
  Divider,
  Grid,
  Paper,
  Button,
  Collapse,
} from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

export const RollsToSee = ({ rolls, createRoll }) => {
  const [expandedRoll, setExpandedRoll] = useState({});

  // Toggles the visibility of the batches for a specific roll
  const handleToggleRoll = (rollIndex) => {
    setExpandedRoll((prev) => ({
      ...prev,
      [rollIndex]: !prev[rollIndex],
    }));
  };

  return (
    <Box>
      {/* Iterate through each roll */}
      {Object.keys(rolls).map((rollKey) => {
        // Determine roll name based on key
        let name = rollKey;
        if (rollKey === 'socksOnlyRolls') {
          name = 'Socks Only';
        } else if (rollKey === 'boxersOnlyRolls') {
          name = 'Boxers Only';
        } else if (rollKey === 'mixedRolls') {
          name = 'Mixed';
        }

        // Get roll data
        const rollData = rolls[rollKey];

        return (
          <Box key={rollKey} mb={4}>
            {/* Roll header */}
            <Typography variant="h5" mb={2}>
              {name} ({rollData.length} Roll{rollData.length > 1 ? 's' : ''})
            </Typography>

            {/* Iterate through each roll */}
            {rollData.map((roll, rollIndex) => {
              const isExpanded = expandedRoll[`${rollKey}-${rollIndex}`];

              return (
                <Box key={rollIndex} mb={3}>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      sx={{
                        cursor: 'pointer',
                        width: '100%',
                        borderBottom: '1px solid #e0e0e0',
                        justifyContent: 'space-between',
                      }}
                      onClick={() =>
                        handleToggleRoll(`${rollKey}-${rollIndex}`)
                      }
                    >
                      {/* add an arrow icon for open/close */}
                      <KeyboardArrowRightIcon />
                      <Typography variant="h6">
                        Roll {rollIndex + 1} ({roll.length} Batches)
                      </Typography>
                      <Button
                        variant="outlined"
                        color="primary"
                        sx={{ width: '10%', mb: 1 }}
                        onClick={(e) => {
                          e.stopPropagation();
                          createRoll(roll);
                        }}
                      >
                        Make Roll
                      </Button>
                    </Box>
                  </Box>

                  <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                    <Divider sx={{ mb: 2 }} />

                    {/* Display orders in the roll */}
                    <Grid container spacing={2}>
                      {roll.map((order, orderIndex) => (
                        <Grid item xs={12} md={6} lg={4} key={orderIndex}>
                          <Paper elevation={3} sx={{ p: 2 }}>
                            <Typography variant="subtitle1" fontWeight="bold">
                              Oldest order:
                              <Typography variant="body1">
                                {order.oldestOrder}
                              </Typography>
                            </Typography>
                            <Typography variant="subtitle1" fontWeight="bold">
                              Total Products in batch:
                              <Typography variant="body1">
                                {order.productCounts}
                              </Typography>
                            </Typography>
                            <Typography variant="subtitle1" fontWeight="bold">
                              Total Sheets:
                              <Typography variant="body1">
                                {order.sheetCount}
                              </Typography>
                            </Typography>
                          </Paper>
                        </Grid>
                      ))}
                    </Grid>
                  </Collapse>
                </Box>
              );
            })}
          </Box>
        );
      })}
    </Box>
  );
};
