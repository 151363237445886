/* eslint-disable complexity */
/* eslint-disable max-statements */
/* eslint-disable max-lines */
import { useState, useEffect, useContext, createRef } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import LinearProgress from '@mui/material/LinearProgress';
import CheckIcon from '@mui/icons-material/Check';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import Dropzone from 'react-dropzone';
import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';
import SyncIcon from '@mui/icons-material/Sync';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CircularProgress from '@mui/material/CircularProgress';

import { BootstrapInput } from '../Page/input';
import {
  getMatchedCropSettingByFront,
  getMatchedCropSettingByBack,
  getMatchedCropSettingsByHyperOptic,
} from '../../db/cropSettings';
import {
  formatBytes,
  updateImageName,
  updateSKU,
  cropSoloImg,
  resizeImg,
} from '../../module/functions';
import UploadContext from '../../context/uploadContext';
import { filterStyles } from './Products.constants';
import {
  checkIfItsFrontOrBack,
  destructureName,
  extractProductDetailsForBoxedImages,
} from '../../utils/extractProductDetails';
import { HYPEROPTIC_PREFIX, SIZES } from '../../constants/defines';
import { getSizeAndColor } from '../../utils/getSizeAndColorFileMb';
import { applySuffix, applySuffixToFraternal } from '../../utils/applySuffix';

const sizeYthL = _.find(SIZES, { sku: 'YTHL' }).id;
const sizeYth = _.find(SIZES, { sku: 'YTHL' }).id;

const SoloImage = (props) => {
  const { classes, front, back, leftFraternal, rightFraternal } = props;
  const [frontArt, setFrontArt] = useState(null);
  const [backArt, setBackArt] = useState(null);
  const [leftFraternalArt, setLeftFraternalArt] = useState(null);
  const [rightFraternalArt, setRightFraternalArt] = useState(null);
  const [smFrontArt, setSmFrontArt] = useState(null);
  const [smBackArt, setSmBackArt] = useState(null);
  const [smLeftFraternalArt, setSmLeftFraternalArt] = useState(null);
  const [smRightFraternalArt, setSmRightFraternalArt] = useState(null);

  useEffect(() => {
    if (front) {
      front.toBlob((blob) => {
        setFrontArt(blob);
      }, 'image/png');

      const canvas = resizeImg(front, 152);
      setSmFrontArt(canvas.toDataURL('png'));
    }
  }, [front]);

  useEffect(() => {
    if (rightFraternal) {
      rightFraternal.toBlob((blob) => {
        setRightFraternalArt(blob);
      }, 'image/png');

      const canvas = resizeImg(rightFraternal, 152);
      setSmRightFraternalArt(canvas.toDataURL('png'));
    }
  }, [rightFraternal]);

  useEffect(() => {
    if (leftFraternal) {
      leftFraternal.toBlob((blob) => {
        setLeftFraternalArt(blob);
      }, 'image/png');

      const canvas = resizeImg(leftFraternal, 152);
      setSmLeftFraternalArt(canvas.toDataURL('png'));
    }
  }, [leftFraternal]);

  useEffect(() => {
    if (back) {
      back.toBlob((blob) => {
        setBackArt(blob);
      }, 'image/png');

      const canvas = resizeImg(back, 152);
      setSmBackArt(canvas.toDataURL('png'));
    }
  }, [back]);

  const renderFrontArt = () => {
    const sizeMB = formatBytes(frontArt.size, 1);
    let frontSizeColor;
    const frontSize = sizeMB.value;
    const frontUnit = sizeMB.unit;

    if (frontSize >= 15.0 && frontSize < 25.0 && frontUnit === 'MB') {
      frontSizeColor = 'yellow';
    } else if (frontSize >= 25.0 && frontUnit === 'MB') {
      frontSizeColor = 'red';
    } else {
      frontSizeColor = 'rgb(20, 183, 121)';
    }

    return (
      <Box>
        <Typography className={classes.ImageBoxHeader}>Print Art(F)</Typography>
        <Box className={classes.SoloImageWrapper}>
          <img className={classes.fullImage} src={smFrontArt} />
        </Box>

        <Typography
          className={classes.ImageBoxFooter}
          sx={{ color: frontSizeColor }}
        >
          {frontSize} {frontUnit}
        </Typography>
      </Box>
    );
  };

  const renderBackArt = () => {
    const sizeMB = formatBytes(backArt.size, 1);
    let frontSizeColor;
    const frontSize = sizeMB.value;
    const frontUnit = sizeMB.unit;

    if (frontSize >= 15.0 && frontSize < 25.0 && frontUnit === 'MB') {
      frontSizeColor = 'yellow';
    } else if (frontSize >= 25.0 && frontUnit === 'MB') {
      frontSizeColor = 'red';
    } else {
      frontSizeColor = 'rgb(20, 183, 121)';
    }

    return (
      <Box>
        <Typography className={classes.ImageBoxHeader}>Print Art(B)</Typography>
        <Box className={classes.SoloImageWrapper}>
          <img className={classes.fullImage} src={smBackArt} />
        </Box>

        <Typography
          className={classes.ImageBoxFooter}
          sx={{ color: frontSizeColor }}
        >
          {frontSize} {frontUnit}
        </Typography>
      </Box>
    );
  };

  const renderLeftFraternalArt = () => {
    const sizeMB = formatBytes(leftFraternalArt.size, 1);
    let frontSizeColor;
    const frontSize = sizeMB.value;
    const frontUnit = sizeMB.unit;

    if (frontSize >= 15.0 && frontSize < 25.0 && frontUnit === 'MB') {
      frontSizeColor = 'yellow';
    } else if (frontSize >= 25.0 && frontUnit === 'MB') {
      frontSizeColor = 'red';
    } else {
      frontSizeColor = 'rgb(20, 183, 121)';
    }

    return (
      <Box>
        <Typography className={classes.ImageBoxHeader}>
          Print Art(LF)
        </Typography>
        <Box className={classes.SoloImageWrapper}>
          <img className={classes.fullImage} src={smLeftFraternalArt} />
        </Box>

        <Typography
          className={classes.ImageBoxFooter}
          sx={{ color: frontSizeColor }}
        >
          {frontSize} {frontUnit}
        </Typography>
      </Box>
    );
  };

  const renderRightFraternalArt = () => {
    const sizeMB = formatBytes(rightFraternalArt.size, 1);
    let frontSizeColor;
    const frontSize = sizeMB.value;
    const frontUnit = sizeMB.unit;

    if (frontSize >= 15.0 && frontSize < 25.0 && frontUnit === 'MB') {
      frontSizeColor = 'yellow';
    } else if (frontSize >= 25.0 && frontUnit === 'MB') {
      frontSizeColor = 'red';
    } else {
      frontSizeColor = 'rgb(20, 183, 121)';
    }

    return (
      <Box>
        <Typography className={classes.ImageBoxHeader}>
          Print Art(RF)
        </Typography>
        <Box className={classes.SoloImageWrapper}>
          <img className={classes.fullImage} src={smRightFraternalArt} />
        </Box>

        <Typography
          className={classes.ImageBoxFooter}
          sx={{ color: frontSizeColor }}
        >
          {frontSize} {frontUnit}
        </Typography>
      </Box>
    );
  };

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      sx={{ position: 'relative', padding: '0 0 0 40px' }}
    >
      <Box className={classes.ImageBox5}>
        {frontArt && smFrontArt && renderFrontArt()}
      </Box>
      <Box className={classes.ImageBox6}>
        {backArt && smBackArt && renderBackArt()}
      </Box>
      <Box className={classes.ImageBox7}>
        {leftFraternalArt && smLeftFraternalArt && renderLeftFraternalArt()}
      </Box>
      <Box className={classes.ImageBox8}>
        {rightFraternalArt && smRightFraternalArt && renderRightFraternalArt()}
      </Box>
    </Box>
  );
};

const BoxerSubProduct = (props) => {
  const { classes, product, size, formats, sizes } = props;

  const renderSpecs = () => {
    const formatItem = _.find(formats, { id: product.format });
    const sizeItem = _.find(sizes, { id: product.size });
    return (
      <Box sx={{ padding: '20px' }}>
        <Grid container spacing={2}>
          <Grid item xs={9}>
            <BootstrapInput
              name="sku"
              placeholder="Product SKU"
              value={product.sku.toUpperCase()}
            />
          </Grid>
        </Grid>

        {!!product.variants &&
          product.variants.map((variant) => (
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <BootstrapInput placeholder="Variant SKU" value={variant} />
              </Grid>
            </Grid>
          ))}

        <Grid container spacing={2} sx={{ marginTop: '20px' }}>
          <Grid item xs={9}>
            <BootstrapInput
              name="name"
              placeholder="Product Name"
              value={product.name}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} sx={{ marginTop: '15px' }}>
          <Grid item xs={3}>
            <InputLabel>Type</InputLabel>
            <BootstrapInput
              name="type"
              placeholder="Product Type"
              value={product.type}
            />
          </Grid>

          <Grid item xs={3}>
            <InputLabel>Style</InputLabel>
            <BootstrapInput
              name="style"
              placeholder="Product Style"
              value={product.style}
            />
          </Grid>

          <Grid item xs={3}>
            <InputLabel>Format</InputLabel>
            <BootstrapInput
              name="format"
              placeholder="Product Format"
              value={formatItem ? formatItem.name : ''}
            />
          </Grid>

          <Grid item xs={3}>
            <InputLabel>Size</InputLabel>
            <BootstrapInput
              name="size"
              placeholder="Product Size"
              value={sizeItem ? sizeItem.name : ''}
            />
          </Grid>
        </Grid>
      </Box>
    );
  };

  const renderSize = () => (
    <Box sx={{ padding: '50px 0 0', textAlign: 'center' }}>
      <Typography className={classes.sizeText}>{size}</Typography>
      <SubdirectoryArrowRightIcon className={classes.sizeIcon} />
    </Box>
  );

  return (
    <Box
      className={classes.autoRow}
      display="flex"
      alignItems="flex-start"
      sx={{ position: 'relative', padding: '20px 0' }}
    >
      {props.loading && <Box className={classes.itemBoxOverlay}></Box>}
      <Box className={classes.itemBox1}>{renderSize()}</Box>
      <Box className={classes.itemBox2}>{product && renderSpecs()}</Box>
      <Box className={classes.itemBox3}></Box>
    </Box>
  );
};

const WomanUnderwearSubProduct = (props) => {
  const { classes, product, size, formats, sizes } = props;

  const renderSpecs = () => {
    const formatItem = _.find(formats, { id: product.format });
    const sizeItem = _.find(sizes, { id: product.size });
    return (
      <Box sx={{ padding: '20px' }}>
        <Grid container spacing={2}>
          <Grid item xs={9}>
            <BootstrapInput
              name="sku"
              placeholder="Product SKU"
              value={product.sku.toUpperCase()}
            />
          </Grid>
        </Grid>

        {!!product.variants &&
          product.variants.map((variant) => (
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <BootstrapInput placeholder="Variant SKU" value={variant} />
              </Grid>
            </Grid>
          ))}

        <Grid container spacing={2} sx={{ marginTop: '20px' }}>
          <Grid item xs={9}>
            <BootstrapInput
              name="name"
              placeholder="Product Name"
              value={product.name}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} sx={{ marginTop: '15px' }}>
          <Grid item xs={3}>
            <InputLabel>Type</InputLabel>
            <BootstrapInput
              name="type"
              placeholder="Product Type"
              value={product.type}
            />
          </Grid>

          <Grid item xs={3}>
            <InputLabel>Style</InputLabel>
            <BootstrapInput
              name="style"
              placeholder="Product Style"
              value={product.style}
            />
          </Grid>

          <Grid item xs={3}>
            <InputLabel>Format</InputLabel>
            <BootstrapInput
              name="format"
              placeholder="Product Format"
              value={formatItem ? formatItem.name : ''}
            />
          </Grid>

          <Grid item xs={3}>
            <InputLabel>Size</InputLabel>
            <BootstrapInput
              name="size"
              placeholder="Product Size"
              value={sizeItem ? sizeItem.name : ''}
            />
          </Grid>
        </Grid>
      </Box>
    );
  };

  const renderSize = () => (
    <Box sx={{ padding: '50px 0 0', textAlign: 'center' }}>
      <Typography className={classes.sizeText}>{size}</Typography>
      <SubdirectoryArrowRightIcon className={classes.sizeIcon} />
    </Box>
  );

  return (
    <Box
      className={classes.autoRow}
      display="flex"
      alignItems="flex-start"
      sx={{ position: 'relative', padding: '20px 0' }}
    >
      {props.loading && <Box className={classes.itemBoxOverlay}></Box>}
      <Box className={classes.itemBox1}>{renderSize()}</Box>
      <Box className={classes.itemBox2}>{product && renderSpecs()}</Box>
      <Box className={classes.itemBox3}></Box>
    </Box>
  );
};

const SoloSubProduct = (props) => {
  const { classes, product, size, formats, sizes } = props;

  // find the product format from the array using product.format
  const formatItem = _.find(formats, { id: product.format })?.name || '';

  if (formatItem === 'Low' && size === 'Youth M') {
    return null;
  }

  const renderSpecs = () => {
    const formatItem = _.find(formats, { id: product.format });
    const sizeItem = _.find(sizes, { id: product.size });
    return (
      <Box sx={{ padding: '20px' }}>
        <Grid container spacing={2}>
          <Grid item xs={9}>
            <BootstrapInput
              name="sku"
              placeholder="Product SKU"
              value={product.sku.toUpperCase()}
            />
          </Grid>
        </Grid>

        {!!product.variants &&
          product.variants.map((variant) => (
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <BootstrapInput placeholder="Variant SKU" value={variant} />
              </Grid>
            </Grid>
          ))}

        <Grid container spacing={2} sx={{ marginTop: '20px' }}>
          <Grid item xs={9}>
            <BootstrapInput
              name="name"
              placeholder="Product Name"
              value={product.name}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} sx={{ marginTop: '15px' }}>
          <Grid item xs={3}>
            <InputLabel>Type</InputLabel>
            <BootstrapInput
              name="type"
              placeholder="Product Type"
              value={product.type}
            />
          </Grid>

          <Grid item xs={3}>
            <InputLabel>Style</InputLabel>
            <BootstrapInput
              name="style"
              placeholder="Product Style"
              value={product.style}
            />
          </Grid>

          <Grid item xs={3}>
            <InputLabel>Format</InputLabel>
            <BootstrapInput
              name="format"
              placeholder="Product Format"
              value={formatItem ? formatItem.name : ''}
            />
          </Grid>

          <Grid item xs={3}>
            <InputLabel>Size</InputLabel>
            <BootstrapInput
              name="size"
              placeholder="Product Size"
              value={sizeItem ? sizeItem.name : ''}
            />
          </Grid>
        </Grid>
      </Box>
    );
  };

  const renderSize = () => (
    <Box sx={{ padding: '50px 0 0', textAlign: 'center' }}>
      <Typography className={classes.sizeText}>{size}</Typography>
      <SubdirectoryArrowRightIcon className={classes.sizeIcon} />
    </Box>
  );

  return (
    <Box
      className={classes.autoRow}
      display="flex"
      alignItems="flex-start"
      sx={{ position: 'relative', padding: '20px 0' }}
    >
      {props.loading && <Box className={classes.itemBoxOverlay}></Box>}
      <Box className={classes.itemBox4}>{renderSize()}</Box>
      <Box className={classes.itemBox5}></Box>
      <Box className={classes.itemBox6}>{product && renderSpecs()}</Box>
    </Box>
  );
};

const SoloSubProducts = (props) => {
  const { classes, index, items, sizes, formats, loading } = props;
  const sizeSM = _.find(sizes, { sku: 'SM' }).name;
  const sizeYTH = _.find(sizes, { sku: 'YTHL' }).name;
  const sizeYTHM = _.find(sizes, { sku: 'YTHM' }).name;
  // const sizeBaby = _.find(sizes, { sku: 'BABY' }).name;

  return (
    <Box>
      <SoloSubProduct
        key={`Auto_${index}-sm`}
        classes={classes}
        formats={formats}
        loading={loading}
        sizes={sizes}
        size={sizeSM}
        product={items[1].product}
      />

      <SoloSubProduct
        key={`Auto_${index}-ythl`}
        classes={classes}
        formats={formats}
        sizes={sizes}
        loading={loading}
        size={sizeYTH}
        product={items[2].product}
      />

      <SoloSubProduct
        key={`Auto_${index}-ythm`}
        classes={classes}
        formats={formats}
        sizes={sizes}
        loading={loading}
        size={sizeYTHM}
        product={items[3].product}
      />
    </Box>
  );
};

const SoloSubProductsTdlr = (props) => {
  const { classes, index, items, sizes, formats, loading } = props;
  const size12y = _.find(sizes, { sku: 'TDLR12' }).name;
  return (
    <Box>
      <SoloSubProduct
        key={`Auto_${index}-tdlr12`}
        classes={classes}
        formats={formats}
        loading={loading}
        sizes={sizes}
        size={size12y}
        product={items[1].product}
      />
    </Box>
  );
};

const BoxerSubProducts = (props) => {
  const { classes, index, products, sizes, formats, loading } = props;
  return (
    <Box>
      <BoxerSubProduct
        key={`BoxerAuto_${index}-xl`}
        classes={classes}
        formats={formats}
        loading={loading}
        sizes={sizes}
        size="XL"
        product={products[1]}
      />

      <BoxerSubProduct
        key={`BoxerAuto_${index}-m`}
        classes={classes}
        formats={formats}
        sizes={sizes}
        loading={loading}
        size="M"
        product={products[2]}
      />

      <BoxerSubProduct
        key={`BoxerAuto_${index}-s`}
        classes={classes}
        formats={formats}
        sizes={sizes}
        loading={loading}
        size="S"
        product={products[3]}
      />

      <BoxerSubProduct
        key={`BoxerAuto_${index}-2xl`}
        classes={classes}
        formats={formats}
        sizes={sizes}
        loading={loading}
        size="2XL"
        product={products[4]}
      />
    </Box>
  );
};

const AdultThirtSubProducts = (props) => {
  const { classes, index, products, sizes, formats, loading } = props;
  return (
    <Box>
      {/* add 3xl and 4xl */}
      <BoxerSubProduct
        key={`ThirtAuto_${index}-4xl`}
        classes={classes}
        formats={formats}
        sizes={sizes}
        loading={loading}
        size="4XL"
        product={products[6]}
      />
      <BoxerSubProduct
        key={`ThirtAuto_${index}-3xl`}
        classes={classes}
        formats={formats}
        sizes={sizes}
        loading={loading}
        size="3XL"
        product={products[5]}
      />
      <BoxerSubProduct
        key={`ThirtAuto_${index}-2xl`}
        classes={classes}
        formats={formats}
        sizes={sizes}
        loading={loading}
        size="2XL"
        product={products[1]}
      />
      <BoxerSubProduct
        key={`ThirtAuto_${index}-xl`}
        classes={classes}
        formats={formats}
        loading={loading}
        sizes={sizes}
        size="XL"
        product={products[2]}
      />

      <BoxerSubProduct
        key={`ThirtAuto_${index}-m`}
        classes={classes}
        formats={formats}
        sizes={sizes}
        loading={loading}
        size="M"
        product={products[3]}
      />

      <BoxerSubProduct
        key={`ThirtAuto_${index}-s`}
        classes={classes}
        formats={formats}
        sizes={sizes}
        loading={loading}
        size="S"
        product={products[4]}
      />
    </Box>
  );
};

const YouthTshirtSubProducts = (props) => {
  const { classes, index, products, sizes, formats, loading } = props;
  return (
    <Box>
      <BoxerSubProduct
        key={`ThirtAuto_${index}-S`}
        classes={classes}
        formats={formats}
        sizes={sizes}
        loading={loading}
        size="S"
        product={products[1]}
      />
      <BoxerSubProduct
        key={`ThirtAuto_${index}-M`}
        classes={classes}
        formats={formats}
        loading={loading}
        sizes={sizes}
        size="M"
        product={products[2]}
      />
    </Box>
  );
};

const HoodieSubProducts = (props) => {
  const { classes, index, products, sizes, formats, loading } = props;
  return (
    <Box>
      <BoxerSubProduct
        key={`HoodieAuto_${index}-2xl`}
        classes={classes}
        formats={formats}
        sizes={sizes}
        loading={loading}
        size="2XL"
        product={products[1]}
      />
      <BoxerSubProduct
        key={`HoodieAuto_${index}-xl`}
        classes={classes}
        formats={formats}
        loading={loading}
        sizes={sizes}
        size="XL"
        product={products[2]}
      />

      <BoxerSubProduct
        key={`HoodieAuto_${index}-m`}
        classes={classes}
        formats={formats}
        sizes={sizes}
        loading={loading}
        size="M"
        product={products[3]}
      />

      <BoxerSubProduct
        key={`HoodieAuto_${index}-s`}
        classes={classes}
        formats={formats}
        sizes={sizes}
        loading={loading}
        size="S"
        product={products[4]}
      />
    </Box>
  );
};

const WomansUnderwearSubProducts = (props) => {
  const { classes, index, products, sizes, formats, loading } = props;
  return (
    <Box>
      <WomanUnderwearSubProduct
        key={`BoxerAuto_${index}-xl`}
        classes={classes}
        formats={formats}
        loading={loading}
        sizes={sizes}
        size="XL"
        product={products[1]}
      />

      <WomanUnderwearSubProduct
        key={`BoxerAuto_${index}-m`}
        classes={classes}
        formats={formats}
        sizes={sizes}
        loading={loading}
        size="M"
        product={products[2]}
      />

      <WomanUnderwearSubProduct
        key={`BoxerAuto_${index}-s`}
        classes={classes}
        formats={formats}
        sizes={sizes}
        loading={loading}
        size="S"
        product={products[3]}
      />
      {/* 
      <WomanUnderwearSubProduct
        key={`BoxerAuto_${index}-2xl`}
        classes={classes}
        formats={formats}
        sizes={sizes}
        loading={loading}
        size="2XL"
        product={products[4]}
      /> */}
    </Box>
  );
};

const YouthBoxerSubProducts = (props) => {
  const { classes, index, products, sizes, formats, loading } = props;
  return (
    <Box>
      <BoxerSubProduct
        key={`ThirtAuto_${index}-S`}
        classes={classes}
        formats={formats}
        sizes={sizes}
        loading={loading}
        size="YBB-S"
        product={products[1]}
      />
      <BoxerSubProduct
        key={`ThirtAuto_${index}-M`}
        classes={classes}
        formats={formats}
        loading={loading}
        sizes={sizes}
        size="YBB-M"
        product={products[2]}
      />
    </Box>
  );
};

function ProductUploadItem(props) {
  const { classes, data, gray, index } = props;

  const contextData = useContext(UploadContext);
  const templates = [...contextData.templates];
  const tags = [...contextData.tags];
  const cropSettings = [...contextData.cropSettings];
  const productSizes = [...contextData.sizes];
  const formatIds = [...contextData.formats];

  const dropzoneRef1 = createRef();
  const dropzoneRef2 = createRef();
  const [isLoading, setIsLoading] = useState(true);

  const [isNotAHyperOptic, setIsNotAHyperOptic] = useState(false);

  const [frontDimension, setFrontDimension] = useState(null);
  const [backDimension, setBackDimension] = useState(null);
  // ! use this to set the identicalHypOptDimension
  const [identicalHypOptDimension, setIdenticalHypOptDimension] =
    useState(null);
  const [leftFraternalHypOptDimension, setLeftFraternalHypOptDimension] =
    useState(null);
  const [rightFraternalHypOptDimension, setRightFraternalHypOptDimension] =
    useState(null);
  const [boxedImageDimension, setBoxedImageDimension] = useState(null);

  // lg image src's
  const [frontImage, setFrontImage] = useState(null);
  const [backImage, setBackImage] = useState(null);
  const [identicalHypOptImage, setIdenticalHypOptImage] = useState(null);
  const [isLeftFraternalImage, setIsLeftFraternalImage] = useState(false);
  const [isRightFraternalImage, setIsRightFraternalImage] = useState(false);

  const [isItABoxedImage, setIsItABoxedImage] = useState(false);

  const [isFraternalImage, setIsFraternalImage] = useState(false);

  const [boxedImage, setBoxedImage] = useState(null);

  // sm image src's
  const [smFrontImage, setSmFrontImage] = useState(null);
  const [smBackImage, setSmBackImage] = useState(null);
  const [smIdenticalHypOptImage, setSmIdenticalHypOptImage] = useState(null);
  const [smLeftFraternalHypOptImage, setSmLeftFraternalHypOptImage] =
    useState(null);
  const [smRightFraternalHypOptImage, setSmRightFraternalHypOptImage] =
    useState(null);
  const [smBoxedImage, setSmBoxedImage] = useState(null);

  const [soloFrontCanvas, setSoloFrontCanvas] = useState(null);
  const [soloBackCanvas, setSoloBackCanvas] = useState(null);
  const [soloIdenticalHypOptCanvas, setSoloIdenticalHypOptCanvas] =
    useState(null);
  const [soloLeftFraternalHypOptCanvas, setSoloLeftFraternalHypOptCanvas] =
    useState(null);
  const [soloRightFraternalHypOptCanvas, setSoloRightFraternalHypOptCanvas] =
    useState(null);

  const [product, setProduct] = useState(props.data.product);

  const [frontIncorrectDimension, setFrontIncorrectDimension] = useState(false);
  const [backIncorrectDimension, setBackIncorrectDimension] = useState(false);
  const [
    identicalHypOptIncorrectDimension,
    setIdenticalHypOptIncorrectDimension,
  ] = useState(false);
  const [
    leftFraternalHypOptIncorrectDimension,
    setLeftFraternalHypOptIncorrectDimension,
  ] = useState(false);
  const [
    rightFraternalHypOptIncorrectDimension,
    setRightFraternalHypOptIncorrectDimension,
  ] = useState(false);

  const [boxedImageIncorrectDimension, setBoxedImageIncorrectDimension] =
    useState(false);

  const [soloArts, setSoloArts] = useState([{}, {}, {}, {}, {}]);

  // xs and 2xl have not recieced final approval
  const [womansBoyShorts, setWomansBoyShorts] = useState([
    { sku: '' },
    { sku: '' },
    { sku: '' },
    { sku: '' },
    { sku: '' },
    // { sku: '' },
  ]);

  // xs and 2xl have not recieced final approval
  const [womansSportsBras, setWomansSportsBras] = useState([
    { sku: '' },
    { sku: '' },
    { sku: '' },
    { sku: '' },
    { sku: '' },
    // { sku: '' },
  ]);

  const [youthBoxers, setYouthBoxers] = useState([
    { sku: '' },
    { sku: '' },
    { sku: '' },
  ]);

  const [boxerProducts, setBoxerProducts] = useState([
    { sku: '' },
    { sku: '' },
    { sku: '' },
    { sku: '' },
    { sku: '' },
  ]);

  const [adultThirtProducts, setAdultThirtProducts] = useState([
    { sku: '' },
    { sku: '' },
    { sku: '' },
    { sku: '' },
    { sku: '' },
    { sku: '' },
    { sku: '' },
  ]);
  const [youthTshirtProducts, setYouthTshirtProducts] = useState([
    { sku: '' },
    { sku: '' },
    { sku: '' },
    { sku: '' },
    { sku: '' },
  ]);
  const [hoodieProducts, setHoodieProducts] = useState([
    { sku: '' },
    { sku: '' },
    { sku: '' },
    { sku: '' },
    { sku: '' },
  ]);

  const [frontName, setFrontName] = useState('');
  const [backName, setBackName] = useState('');
  const [identicalHypOptName, setIdenticalHypOptName] = useState('');
  const [leftFraternalHypOptName, setLeftFraternalHypOptName] = useState('');
  const [rightFraternalHypOptName, setRightFraternalHypOptName] = useState('');
  const [boxedImageNames, setBoxedImageNames] = useState('');

  const [sizes, setSizes] = useState([...contextData.sizes]);
  const [formats, setFormats] = useState([...contextData.formats]);
  const [styles, setStyles] = useState([]);

  useEffect(() => {
    if (data.rightFraternalHyperOptic) {
      let filename =
        data.rightFraternalHyperOptic.name || 'Error: No File Name Found!';
      // remove the () from the filename
      filename = filename.replace(/\(.*\)/, '');
      setRightFraternalHypOptName(filename);

      const fr = new FileReader();
      fr.onload = function () {
        const img = new Image();
        img.onload = function () {
          setIsRightFraternalImage(img);
        };
        img.src = fr.result;
      };

      fr.readAsDataURL(data.rightFraternalHyperOptic);
    }
  }, [data.rightFraternalHyperOptic]);

  useEffect(() => {
    if (data.isBoxedImageFile) {
      let filename = data.isBoxedImageFile.name || 'Error: No File Name Found!';
      // remove the () from the filename
      filename = filename.replace(/\(.*\)/, '');
      setBoxedImageNames(filename);
      const fr = new FileReader();
      fr.onload = function () {
        const img = new Image();
        img.onload = function () {
          setBoxedImage(img);
        };
        img.src = fr.result;
      };

      fr.readAsDataURL(data.isBoxedImageFile);
    }
  }, [data.isBoxedImageFile]);

  useEffect(() => {
    if (boxedImage) {
      const canvas = resizeImg(boxedImage, 170);
      setSmBoxedImage(canvas.toDataURL('png'));

      const size = data.isBoxedImageFile.size;

      const sizeMB = formatBytes(size, 1);

      setBoxedImageDimension({
        width: boxedImage.width,
        height: boxedImage.height,
        size,
        sizeMB,
      });

      const parameters = extractProductDetailsForBoxedImages(
        boxedImageNames,
        productSizes,
        formatIds,
      );

      const _product = { ...product };
      _product.format = parameters?.format ?? '';
      _product.size = parameters?.size ?? '';
      _product.style = parameters?.style ?? '';
      _product.type = parameters?.type ?? '';
      _product.isBoxedImagePath = boxedImageNames;

      changeProduct(_product);

      if (contextData.onChangeDimension) {
        contextData.onChangeDimension(index, false);
      }

      setProduct(_product);

      setIsItABoxedImage(true);

      setIsLoading(false);
    }
  }, [boxedImage]);

  useEffect(() => {
    if (isRightFraternalImage) {
      const canvas = resizeImg(isRightFraternalImage, 170);
      setSmRightFraternalHypOptImage(canvas.toDataURL('png'));

      const size = data.rightFraternalHyperOptic.size;

      const sizeMB = formatBytes(size, 1);

      setRightFraternalHypOptDimension({
        width: isRightFraternalImage.width,
        height: isRightFraternalImage.height,
        size,
        sizeMB,
      });

      const _product = { ...product };

      const cropSettingMatcher = getMatchedCropSettingsByHyperOptic(
        cropSettings,
        isRightFraternalImage.width,
        isRightFraternalImage.height,
      );

      if (!cropSettingMatcher) {
        setRightFraternalHypOptIncorrectDimension(true);
      }

      _product.format = cropSettingMatcher.format || '';
      _product.size = cropSettingMatcher.size || '';
      _product.style = cropSettingMatcher.style || '';
      _product.type = cropSettingMatcher.type || '';
      _product.pathRight = rightFraternalHypOptName || '';

      // remove the (SIDE) from the product name
      _product.name = _product.name.replace(/ \(Left\)| \(Right\)/, '');

      changeProduct(_product);

      if (contextData.onChangeDimension) {
        contextData.onChangeDimension(index, false);
      }

      setProduct(_product);

      setIsFraternalImage(true);
      setIsLoading(false);
    }
  }, [isRightFraternalImage]);

  useEffect(() => {
    // Check if data and data.isIdenticalHyperOptic exist before proceeding
    if (data.isIdenticalHyperOptic) {
      const filename =
        data.isIdenticalHyperOptic.name || 'Error: No File Name Found!';
      setLeftFraternalHypOptName(filename);

      const fr = new FileReader();
      fr.onload = function () {
        const img = new Image();
        img.onload = function () {
          setIdenticalHypOptImage(img);
        };
        img.src = fr.result;
      };

      // Make sure you're reading the correct file data; this should likely be data.isIdenticalHyperOptic.File
      fr.readAsDataURL(data.isIdenticalHyperOptic);

      if (
        data.isIdenticalHyperOptic &&
        data.product.hyperOpticIdenticalCropsettings.noSizeFound
      ) {
        setIdenticalHypOptIncorrectDimension(true);
        return;
      }

      setIdenticalHypOptIncorrectDimension(false);
    }
  }, [data.isIdenticalHyperOptic]);

  useEffect(() => {
    // Check if data and data.leftFraternalHyperOptic exist before proceeding
    if (data.leftFraternalHyperOptic) {
      let filename =
        data.leftFraternalHyperOptic.name || 'Error: No File Name Found!';
      // remove the () from the filename
      filename = filename.replace(/\(.*\)/, '');

      setIdenticalHypOptName(filename);

      const fr = new FileReader();
      fr.onload = function () {
        const img = new Image();
        img.onload = function () {
          setIsLeftFraternalImage(img);
        };
        img.src = fr.result;
      };

      // Make sure you're reading the correct file data; this should likely be data.leftFraternalHyperOptic.File
      fr.readAsDataURL(data.leftFraternalHyperOptic);
    }
  }, [data.leftFraternalHyperOptic]);

  useEffect(() => {
    if (isLeftFraternalImage) {
      const canvas = resizeImg(isLeftFraternalImage, 170);

      setSmLeftFraternalHypOptImage(canvas.toDataURL('png'));

      const size = data.leftFraternalHyperOptic.size;

      const sizeMB = formatBytes(size, 1);

      setLeftFraternalHypOptDimension({
        width: isLeftFraternalImage.width,
        height: isLeftFraternalImage.height,
        size,
        sizeMB,
      });

      const _product = { ...product };

      const cropSettingMatcher = getMatchedCropSettingsByHyperOptic(
        cropSettings,
        isLeftFraternalImage.width,
        isLeftFraternalImage.height,
      );

      if (!cropSettingMatcher) {
        setLeftFraternalHypOptIncorrectDimension(true);
      }

      _product.format = cropSettingMatcher.format;
      _product.size = cropSettingMatcher.size;
      _product.style = cropSettingMatcher.style;
      _product.type = cropSettingMatcher.type;
      _product.pathLeft = leftFraternalHypOptName;

      // Removes the ()
      _product.name = _product.name.replace(/ \(Left\)| \(Right\)/, '');

      changeProduct(_product);

      if (contextData.onChangeDimension) {
        contextData.onChangeDimension(index, false);
      }

      setProduct(_product);

      setIsFraternalImage(true);
      setIsLoading(false);
    }
  }, [isLeftFraternalImage]);

  useEffect(() => {
    if (identicalHypOptImage) {
      const canvas = resizeImg(identicalHypOptImage, 170);

      setSmIdenticalHypOptImage(canvas.toDataURL('png'));

      if (identicalHypOptIncorrectDimension) {
        setIsLoading(false);
        return;
      }

      const size = data.isIdenticalHyperOptic.size;

      const sizeMB = formatBytes(size, 1);

      setIdenticalHypOptDimension({
        width: identicalHypOptImage.width,
        height: identicalHypOptImage.height,
        size,
        sizeMB,
      });

      const _product = { ...product };

      _product.format = _product.hyperOpticIdenticalCropsettings.format;
      _product.size = _product.hyperOpticIdenticalCropsettings.size;
      _product.style = _product.hyperOpticIdenticalCropsettings.style;
      _product.type = _product.hyperOpticIdenticalCropsettings.type;
      _product.pathFront = identicalHypOptName;

      changeProduct(_product);

      setProduct(_product);

      const _size = _.find(sizes, { id: _product.size });

      const cropSettingsWidthAndHeight = {
        height: identicalHypOptImage.height,
        x: 0,
        width: identicalHypOptImage.width,
        y: 0,
      };

      const cropSettingMatcher = getMatchedCropSettingsByHyperOptic(
        cropSettings,
        identicalHypOptImage.width,
        identicalHypOptImage.height,
      );

      if (!cropSettingMatcher) {
        setIdenticalHypOptIncorrectDimension(true);
        if (contextData.onChangeDimension) {
          contextData.onChangeDimension(index, true);
        }
      } else {
        setIdenticalHypOptIncorrectDimension(false);
      }

      if (_size.sku === 'LXL') {
        createSoloProducts(_product);
        setSoloIdenticalHypOptCanvas(
          cropSoloImg(identicalHypOptImage, cropSettingsWidthAndHeight),
        );
      } else if (_size.sku === 'TDLR24') {
        createSoloProductsTdlr(_product);
        setSoloIdenticalHypOptCanvas(
          cropSoloImg(identicalHypOptImage, cropSettingsWidthAndHeight),
        );
      }

      setIsLoading(false);
    }
  }, [identicalHypOptImage]);

  useEffect(() => {
    if (frontImage) {
      const canvas = resizeImg(frontImage, 170);
      setSmFrontImage(canvas.toDataURL('png'));

      const size = data.front.size;
      const sizeMB = formatBytes(size, 1);
      setFrontDimension({
        width: frontImage.width,
        height: frontImage.height,
        size,
        sizeMB,
      });

      let cropSetting = getMatchedCropSettingByFront(
        cropSettings,
        frontImage.width,
        frontImage.height,
      );

      const formatName =
        _.find(formats, { id: cropSetting?.format })?.name ?? '';

      if (
        cropSetting &&
        cropSetting.type === 'Underwear' &&
        (formatName === "Men's Boxer Briefs" || formatName === 'Boxer')
      ) {
        const sizeId = _.find(sizes, { sku: 'L', type: 'Underwear' }).id;
        cropSetting = _.find(cropSettings, { artType: 'legacy', size: sizeId });
      }

      if (cropSetting) {
        const _product = { ...product };

        _product.identical = cropSetting.isSolo;

        if (_product.size !== '' && _product.size !== cropSetting.size) {
          setFrontIncorrectDimension(true);
          if (contextData.onChangeDimension) {
            contextData.onChangeDimension(index, true);
          }
        } else {
          if (cropSetting.size === sizeYth) {
            cropSetting.size = sizeYthL;
          }

          _product.format = cropSetting.format;
          _product.size = cropSetting.size;
          _product.style = cropSetting.style;
          _product.type = cropSetting.type;
          _product.pathFront = frontName;

          changeProduct(_product);
          // changeProduct(_product);
          setFrontIncorrectDimension(false);
          if (contextData.onChangeDimension) {
            contextData.onChangeDimension(index, false);
          }
        }
        setProduct(_product);
        // getCroppedImg(img, cropSetting);
        // create solo subproducts
        const _size = _.find(sizes, { id: _product.size });
        if (cropSetting.isSolo && _size.sku === 'LXL') {
          createSoloProducts(_product);

          setSoloFrontCanvas(cropSoloImg(frontImage, cropSetting.frontArea));
        } else if (cropSetting.isSolo && _size.sku === 'TDLR24') {
          createSoloProductsTdlr(_product);

          setSoloFrontCanvas(cropSoloImg(frontImage, cropSetting.frontArea));
        } else if (
          cropSetting.type === 'Underwear' &&
          _size.sku === 'L' &&
          (formatName === "Men's Boxer Briefs" || formatName === 'Boxer')
        ) {
          createBoxerProducts(_product);
        } else if (
          cropSetting.type === 'Underwear' &&
          _size.sku === 'L' &&
          formatName === "Women's Boy Shorts"
        ) {
          createWomansUnderwearProducts(
            _product,
            setWomansBoyShorts,
            womansBoyShorts,
          );
        } else if (
          cropSetting.type === 'Underwear' &&
          _size.sku === 'L' &&
          formatName === "Women's Sports Bra"
        ) {
          createWomansUnderwearProducts(
            _product,
            setWomansSportsBras,
            womansSportsBras,
          );
        }
        if (
          cropSetting.type === 'Underwear' &&
          _size.sku === 'YTHL' &&
          formatName === 'Youth Boxer Briefs'
        ) {
          createYouthBoxerProducts(_product);
        }
      } else {
        setFrontIncorrectDimension(true);
        if (contextData.onChangeDimension) {
          contextData.onChangeDimension(index, false);
        }
      }
      setIsNotAHyperOptic(true);
      setIsLoading(false);
    }
  }, [frontImage]);

  useEffect(() => {
    if (!!data && !!data.front && !data.isIdenticalHyperOptic) {
      const filename = data.front.name;
      setFrontName(filename);

      const fr = new FileReader();
      fr.onload = function () {
        const img = new Image();
        img.onload = function () {
          setFrontImage(img);
        };
        img.src = fr.result;
      };
      fr.readAsDataURL(data.front);
    }
  }, [data.front]);

  useEffect(() => {
    if (backImage) {
      const canvas = resizeImg(backImage, 170);
      setSmBackImage(canvas.toDataURL('png'));

      const size = data.back.size;
      const sizeMB = formatBytes(size, 1);
      setBackDimension({
        width: backImage.width,
        height: backImage.height,
        size,
        sizeMB,
      });

      let cropSetting = getMatchedCropSettingByBack(
        cropSettings,
        backImage.width,
        backImage.height,
      );

      const formatName =
        _.find(formats, { id: cropSetting?.format })?.name ?? '';

      if (
        cropSetting &&
        cropSetting.type === 'Underwear' &&
        (formatName === "Men's Boxer Briefs" || formatName === 'Boxer')
      ) {
        const sizeId = _.find(sizes, { sku: 'L', type: 'Underwear' }).id;
        cropSetting = _.find(cropSettings, { artType: 'legacy', size: sizeId });
      }

      if (cropSetting) {
        const _product = { ...product };
        _product.identical = cropSetting.isSolo;
        if (_product.size !== '' && _product.size !== cropSetting.size) {
          setBackIncorrectDimension(true);
          if (contextData.onChangeDimension) {
            contextData.onChangeDimension(index, true);
          }
        } else {
          if (cropSetting.size === sizeYth) {
            cropSetting.size = sizeYthL;
          }
          _product.format = cropSetting.format;
          _product.size = cropSetting.size;
          _product.style = cropSetting.style;
          _product.type = cropSetting.type;
          _product.pathBack = backName;

          changeProduct(_product);
          // changeProduct(_product);
          setBackIncorrectDimension(false);
          if (contextData.onChangeDimension) {
            contextData.onChangeDimension(index, false);
          }
        }
        setProduct(_product);

        const _size = _.find(sizes, { id: _product.size });

        if (cropSetting.isSolo && _size.sku === 'LXL') {
          createSoloProducts(_product);
          setSoloBackCanvas(cropSoloImg(backImage, cropSetting.backArea));
        } else if (cropSetting.isSolo && _size.sku === 'TDLR24') {
          createSoloProductsTdlr(_product);
          setSoloBackCanvas(cropSoloImg(backImage, cropSetting.backArea));
        } else if (
          cropSetting.type === 'Underwear' &&
          _size.sku === 'L' &&
          (formatName === "Men's Boxer Briefs" || formatName === 'Boxer')
        ) {
          createBoxerProducts(_product);
        } else if (
          cropSetting.type === 'Underwear' &&
          _size.sku === 'L' &&
          formatName === "Women's Boy Shorts"
        ) {
          createWomansUnderwearProducts(
            _product,
            setWomansBoyShorts,
            womansBoyShorts,
          );
        } else if (
          cropSetting.type === 'Underwear' &&
          _size.sku === 'L' &&
          formatName === "Women's Sports Bra"
        ) {
          createWomansUnderwearProducts(
            _product,
            setWomansSportsBras,
            womansSportsBras,
          );
        }
        if (
          cropSetting.type === 'Underwear' &&
          _size.sku === 'YTHL' &&
          formatName === 'Youth Boxer Briefs'
        ) {
          createYouthBoxerProducts(_product);
        }
      } else {
        setBackIncorrectDimension(true);
        if (contextData.onChangeDimension) {
          contextData.onChangeDimension(index, true);
        }
      }
      // ! set to show after all code is finished
      setIsNotAHyperOptic(true);
      setIsLoading(false);
    }
  }, [backImage]);

  useEffect(() => {
    if (!!data && !!data.back && !data.isIdenticalHyperOptic) {
      const filename = data.back.name;
      setBackName(filename);

      const fr = new FileReader();
      fr.onload = function () {
        const img = new Image();
        img.onload = function () {
          setBackImage(img);
        };

        img.src = fr.result;
      };
      fr.readAsDataURL(data.back);
    }
  }, [data.back]);

  useEffect(() => {
    loadSpecs(product.type);

    const _size = _.find(sizes, { id: product.size });

    if (!_size) {
      return;
    }

    const formatName = _.find(formats, { id: product.format })?.name ?? '';

    if (product.identical && _size.sku === 'LXL') {
      createSoloProducts(product);
    } else if (product.identical && _size.sku === 'TDLR24') {
      createSoloProductsTdlr(product);
    } else if (
      product.type === 'Underwear' &&
      _size.sku === 'L' &&
      (formatName === "Men's Boxer Briefs" || formatName === 'Boxer')
    ) {
      createBoxerProducts(product);
    } else if (product.type === 'T-Shirt' && _size.sku === 'L') {
      createAdultTshirtProducts(product);
    } else if (product.type === 'T-Shirt' && _size.sku === 'YTHL') {
      createYouthTshirtProducts(product);
    } else if (product.type === 'Outerwear' && _size.sku === 'L') {
      createHoodieProducts(product);
    } else if (
      product.type === 'Underwear' &&
      _size.sku === 'L' &&
      formatName === "Women's Boy Shorts"
    ) {
      createWomansUnderwearProducts(
        product,
        setWomansBoyShorts,
        womansBoyShorts,
      );
    } else if (
      product.type === 'Underwear' &&
      _size.sku === 'L' &&
      formatName === "Women's Sports Bra"
    ) {
      createWomansUnderwearProducts(
        product,
        setWomansSportsBras,
        womansSportsBras,
      );
    } else if (product.type === 'Underwear' && _size.sku === 'YTHL') {
      createYouthBoxerProducts(product);
    }
  }, [product]);

  const loadSpecs = (value) => {
    if (value === '') {
      // setFormats([...formats]);
      // setSizes([...sizes]);
      const _styles = _.filter(filterStyles, (item) => item.id > 2);
      setStyles([..._styles]);
    } else {
      const _styles = _.filter(
        filterStyles,
        (item) => item.id > 2 && item.type.includes(value),
      );
      setStyles([..._styles]);
    }
  };

  const changeProduct = (_product) => {
    if (contextData.onChangeProduct) {
      contextData.onChangeProduct(index, _product);
    }
  };

  const createWomansUnderwearProducts = (
    _product,
    setFunction,
    variableParam,
  ) => {
    const xlProduct = { ..._product },
      mProduct = { ..._product },
      sProduct = { ..._product },
      xxlProduct = { ..._product };
    xlProduct.variants = [..._product.variants];
    xlProduct.tags = [..._product.tags];
    xlProduct.templates = [..._product.templates];

    mProduct.variants = [..._product.variants];
    mProduct.tags = [..._product.tags];
    mProduct.templates = [..._product.templates];

    sProduct.variants = [..._product.variants];
    sProduct.tags = [..._product.tags];
    sProduct.templates = [..._product.templates];

    xxlProduct.variants = [..._product.variants];
    xxlProduct.tags = [..._product.tags];
    xxlProduct.templates = [..._product.templates];

    xlProduct.size = _.find(sizes, { sku: 'XL' }).id;
    mProduct.size = _.find(sizes, { sku: 'M' }).id;
    sProduct.size = _.find(sizes, { sku: 'S' }).id;
    xxlProduct.size = _.find(sizes, { sku: '2XL' }).id;

    if (_product.sku !== '') {
      xlProduct.sku = updateSKU(_product.sku.toUpperCase(), 'L', 'XL');
      mProduct.sku = updateSKU(_product.sku.toUpperCase(), 'L', 'M');
      sProduct.sku = updateSKU(_product.sku.toUpperCase(), 'L', 'S');
      xxlProduct.sku = updateSKU(_product.sku.toUpperCase(), 'L', '2XL');
    }

    for (let i = 0; i < _product.variants.length; i++) {
      if (_product.variants[i] !== '') {
        xlProduct.variants[i] = updateSKU(
          _product.variants[i].toUpperCase(),
          'L',
          'XL',
        );
        mProduct.variants[i] = updateSKU(
          _product.variants[i].toUpperCase(),
          'L',
          'M',
        );
        sProduct.variants[i] = updateSKU(
          _product.variants[i].toUpperCase(),
          'L',
          'S',
        );
        xxlProduct.variants[i] = updateSKU(
          _product.variants[i].toUpperCase(),
          'L',
          '2XL',
        );
      }
    }

    const _boxerProducts = [...variableParam];
    _boxerProducts[1] = xlProduct;
    _boxerProducts[2] = mProduct;
    _boxerProducts[3] = sProduct;
    _boxerProducts[4] = xxlProduct;
    setFunction(_boxerProducts);
  };

  const createYouthBoxerProducts = (_product) => {
    const youthSProduct = { ..._product },
      youthMProduct = { ..._product };
    youthSProduct.variants = [..._product.variants];
    youthSProduct.tags = [..._product.tags];
    youthSProduct.templates = [..._product.templates];

    youthMProduct.variants = [..._product.variants];
    youthMProduct.tags = [..._product.tags];
    youthMProduct.templates = [..._product.templates];

    youthSProduct.size = _.find(sizes, { sku: 'YTHS', type: 'Underwear' }).id;
    youthMProduct.size = _.find(sizes, { sku: 'YTHM', type: 'Underwear' }).id;

    if (_product.sku !== '') {
      youthSProduct.sku = updateSKU(_product.sku.toUpperCase(), 'L', 'S');
      youthMProduct.sku = updateSKU(_product.sku.toUpperCase(), 'L', 'M');
    }

    for (let i = 0; i < _product.variants.length; i++) {
      if (_product.variants[i] !== '') {
        youthSProduct.variants[i] = updateSKU(
          _product.variants[i].toUpperCase(),
          'L',
          'S',
        );
        youthMProduct.variants[i] = updateSKU(
          _product.variants[i].toUpperCase(),
          'L',
          'M',
        );
      }
    }

    const _youthBoxerProducts = [...youthBoxers];
    _youthBoxerProducts[1] = youthSProduct;
    _youthBoxerProducts[2] = youthMProduct;

    setYouthBoxers(_youthBoxerProducts);
  };

  const createBoxerProducts = (_product) => {
    const xlProduct = { ..._product },
      mProduct = { ..._product },
      sProduct = { ..._product },
      xxlProduct = { ..._product };
    xlProduct.variants = [..._product.variants];
    xlProduct.tags = [..._product.tags];
    xlProduct.templates = [..._product.templates];

    mProduct.variants = [..._product.variants];
    mProduct.tags = [..._product.tags];
    mProduct.templates = [..._product.templates];

    sProduct.variants = [..._product.variants];
    sProduct.tags = [..._product.tags];
    sProduct.templates = [..._product.templates];

    xxlProduct.variants = [..._product.variants];
    xxlProduct.tags = [..._product.tags];
    xxlProduct.templates = [..._product.templates];

    xlProduct.size = _.find(sizes, { sku: 'XL' }).id;
    mProduct.size = _.find(sizes, { sku: 'M' }).id;
    sProduct.size = _.find(sizes, { sku: 'S' }).id;
    xxlProduct.size = _.find(sizes, { sku: '2XL' }).id;

    if (_product.sku !== '') {
      xlProduct.sku = updateSKU(_product.sku.toUpperCase(), 'L', 'XL');
      mProduct.sku = updateSKU(_product.sku.toUpperCase(), 'L', 'M');
      sProduct.sku = updateSKU(_product.sku.toUpperCase(), 'L', 'S');
      xxlProduct.sku = updateSKU(_product.sku.toUpperCase(), 'L', '2XL');
    }

    for (let i = 0; i < _product.variants.length; i++) {
      if (_product.variants[i] !== '') {
        xlProduct.variants[i] = updateSKU(
          _product.variants[i].toUpperCase(),
          'L',
          'XL',
        );
        mProduct.variants[i] = updateSKU(
          _product.variants[i].toUpperCase(),
          'L',
          'M',
        );
        sProduct.variants[i] = updateSKU(
          _product.variants[i].toUpperCase(),
          'L',
          'S',
        );
        xxlProduct.variants[i] = updateSKU(
          _product.variants[i].toUpperCase(),
          'L',
          '2XL',
        );
      }
    }

    const _boxerProducts = [...boxerProducts];
    _boxerProducts[1] = xlProduct;
    _boxerProducts[2] = mProduct;
    _boxerProducts[3] = sProduct;
    _boxerProducts[4] = xxlProduct;

    setBoxerProducts(_boxerProducts);
  };

  const createYouthTshirtProducts = (_product) => {
    const youthSProduct = { ..._product },
      youthMProduct = { ..._product };
    youthSProduct.variants = [..._product.variants];
    youthSProduct.tags = [..._product.tags];
    youthSProduct.templates = [..._product.templates];

    youthMProduct.variants = [..._product.variants];
    youthMProduct.tags = [..._product.tags];
    youthMProduct.templates = [..._product.templates];

    youthSProduct.size = _.find(sizes, { sku: 'YTHS', type: 'T-Shirt' }).id;
    youthMProduct.size = _.find(sizes, { sku: 'YTHM', type: 'T-Shirt' }).id;

    if (_product.sku !== '') {
      youthSProduct.sku = updateSKU(_product.sku.toUpperCase(), 'YTHL', 'YTHS');
      youthMProduct.sku = updateSKU(_product.sku.toUpperCase(), 'YTHL', 'YTHM');
    }

    for (let i = 0; i < _product.variants.length; i++) {
      if (_product.variants[i] !== '') {
        youthSProduct.variants[i] = updateSKU(
          _product.variants[i].toUpperCase(),
          'YTHL',
          'YTHS',
        );
        youthMProduct.variants[i] = updateSKU(
          _product.variants[i].toUpperCase(),
          'YTHL',
          'YTHM',
        );
      }
    }

    const _youthTshirtProducts = [...youthTshirtProducts];
    _youthTshirtProducts[1] = youthSProduct;
    _youthTshirtProducts[2] = youthMProduct;

    setYouthTshirtProducts(_youthTshirtProducts);
  };

  const createAdultTshirtProducts = (_product) => {
    const xlProduct = { ..._product },
      mProduct = { ..._product },
      sProduct = { ..._product },
      xxlProduct = { ..._product },
      xxxlProduct = { ..._product },
      xxxxlProduct = { ..._product };
    xlProduct.variants = [..._product.variants];
    xlProduct.tags = [..._product.tags];
    xlProduct.templates = [..._product.templates];

    mProduct.variants = [..._product.variants];
    mProduct.tags = [..._product.tags];
    mProduct.templates = [..._product.templates];

    sProduct.variants = [..._product.variants];
    sProduct.tags = [..._product.tags];
    sProduct.templates = [..._product.templates];

    xxlProduct.variants = [..._product.variants];
    xxlProduct.tags = [..._product.tags];
    xxlProduct.templates = [..._product.templates];

    xlProduct.size = _.find(sizes, { sku: 'XL', type: 'T-Shirt' }).id;
    mProduct.size = _.find(sizes, { sku: 'M', type: 'T-Shirt' }).id;
    sProduct.size = _.find(sizes, { sku: 'S', type: 'T-Shirt' }).id;
    xxlProduct.size = _.find(sizes, { sku: '2XL', type: 'T-Shirt' }).id;
    xxxlProduct.size = _.find(sizes, { sku: '3XL', type: 'T-Shirt' }).id;
    xxxxlProduct.size = _.find(sizes, { sku: '4XL', type: 'T-Shirt' }).id;

    if (_product.sku !== '') {
      xlProduct.sku = updateSKU(_product.sku.toUpperCase(), 'L', 'XL');
      mProduct.sku = updateSKU(_product.sku.toUpperCase(), 'L', 'M');
      sProduct.sku = updateSKU(_product.sku.toUpperCase(), 'L', 'S');
      xxlProduct.sku = updateSKU(_product.sku.toUpperCase(), 'L', '2XL');
      xxxlProduct.sku = updateSKU(_product.sku.toUpperCase(), 'L', '3XL');
      xxxxlProduct.sku = updateSKU(_product.sku.toUpperCase(), 'L', '4XL');
    }

    for (let i = 0; i < _product.variants.length; i++) {
      if (_product.variants[i] !== '') {
        xlProduct.variants[i] = updateSKU(
          _product.variants[i].toUpperCase(),
          'L',
          'XL',
        );
        mProduct.variants[i] = updateSKU(
          _product.variants[i].toUpperCase(),
          'L',
          'M',
        );
        sProduct.variants[i] = updateSKU(
          _product.variants[i].toUpperCase(),
          'L',
          'S',
        );
        xxlProduct.variants[i] = updateSKU(
          _product.variants[i].toUpperCase(),
          'L',
          '2XL',
        );
        xxxlProduct.variants[i] = updateSKU(
          _product.variants[i].toUpperCase(),
          'L',
          '3XL',
        );
        xxxxlProduct.variants[i] = updateSKU(
          _product.variants[i].toUpperCase(),
          'L',
          '4XL',
        );
      }
    }

    const _adultProducts = [...adultThirtProducts];
    _adultProducts[1] = xxlProduct;
    _adultProducts[2] = xlProduct;
    _adultProducts[3] = mProduct;
    _adultProducts[4] = sProduct;
    _adultProducts[5] = xxxlProduct;
    _adultProducts[6] = xxxxlProduct;

    setAdultThirtProducts(_adultProducts);
  };

  const createHoodieProducts = (_product) => {
    const xlProduct = { ..._product },
      mProduct = { ..._product },
      sProduct = { ..._product },
      xxlProduct = { ..._product };
    xlProduct.variants = [..._product.variants];
    xlProduct.tags = [..._product.tags];
    xlProduct.templates = [..._product.templates];

    mProduct.variants = [..._product.variants];
    mProduct.tags = [..._product.tags];
    mProduct.templates = [..._product.templates];

    sProduct.variants = [..._product.variants];
    sProduct.tags = [..._product.tags];
    sProduct.templates = [..._product.templates];

    xxlProduct.variants = [..._product.variants];
    xxlProduct.tags = [..._product.tags];
    xxlProduct.templates = [..._product.templates];

    xlProduct.size = _.find(sizes, { sku: 'XL', type: 'Outerwear' }).id;
    mProduct.size = _.find(sizes, { sku: 'M', type: 'Outerwear' }).id;
    sProduct.size = _.find(sizes, { sku: 'S', type: 'Outerwear' }).id;
    xxlProduct.size = _.find(sizes, { sku: '2XL', type: 'Outerwear' }).id;

    if (_product.sku !== '') {
      xlProduct.sku = updateSKU(_product.sku.toUpperCase(), 'L', 'XL');
      mProduct.sku = updateSKU(_product.sku.toUpperCase(), 'L', 'M');
      sProduct.sku = updateSKU(_product.sku.toUpperCase(), 'L', 'S');
      xxlProduct.sku = updateSKU(_product.sku.toUpperCase(), 'L', '2XL');
    }

    for (let i = 0; i < _product.variants.length; i++) {
      if (_product.variants[i] !== '') {
        xlProduct.variants[i] = updateSKU(
          _product.variants[i].toUpperCase(),
          'L',
          'XL',
        );
        mProduct.variants[i] = updateSKU(
          _product.variants[i].toUpperCase(),
          'L',
          'M',
        );
        sProduct.variants[i] = updateSKU(
          _product.variants[i].toUpperCase(),
          'L',
          'S',
        );
        xxlProduct.variants[i] = updateSKU(
          _product.variants[i].toUpperCase(),
          'L',
          '2XL',
        );
      }
    }

    const _hoodieProducts = [...hoodieProducts];
    _hoodieProducts[1] = xxlProduct;
    _hoodieProducts[2] = xlProduct;
    _hoodieProducts[3] = mProduct;
    _hoodieProducts[4] = sProduct;

    setHoodieProducts(_hoodieProducts);
  };

  const createSoloProducts = (_product) => {
    const smProduct = { ..._product },
      ythProduct = { ..._product },
      ythmProduct = { ..._product },
      babyProduct = { ..._product };
    smProduct.variants = [..._product.variants];
    smProduct.tags = [..._product.tags];
    smProduct.templates = [..._product.templates];
    ythProduct.variants = [..._product.variants];
    ythProduct.tags = [..._product.tags];
    ythProduct.templates = [..._product.templates];
    ythmProduct.variants = [..._product.variants];
    ythmProduct.tags = [..._product.tags];
    ythmProduct.templates = [..._product.templates];
    babyProduct.variants = [..._product.variants];
    babyProduct.tags = [..._product.tags];
    babyProduct.templates = [..._product.templates];
    const _soloArts = [...soloArts];

    smProduct.size = _.find(sizes, { sku: 'SM' }).id;
    ythProduct.size = _.find(sizes, { sku: 'YTHL' }).id;
    ythmProduct.size = _.find(sizes, { sku: 'YTHM' }).id;
    // babyProduct.size = _.find(sizes, { sku: 'BABY' }).id;

    if (data.front) {
      const fileName = _product.pathFront;
      const smFileName = updateImageName(fileName, 'LXL', 'SM');
      const ythFileName = updateImageName(fileName, 'LXL', 'YTHL');
      const ythmFileName = updateImageName(fileName, 'LXL', 'YTHM');
      const babyFileName = updateImageName(fileName, 'LXL', 'BABY');
      smProduct.pathFront = smFileName;
      ythProduct.pathFront = ythFileName;
      ythmProduct.pathFront = ythmFileName;
      babyProduct.pathFront = babyFileName;
    }

    if (data.back) {
      const fileName = _product.pathBack;
      const smFileName = updateImageName(fileName, 'LXL', 'SM');
      const ythFileName = updateImageName(fileName, 'LXL', 'YTHL');
      const ythmFileName = updateImageName(fileName, 'LXL', 'YTHM');
      const babyFileName = updateImageName(fileName, 'LXL', 'BABY');
      smProduct.pathBack = smFileName;
      ythProduct.pathBack = ythFileName;
      ythmProduct.pathBack = ythmFileName;
      babyProduct.pathBack = babyFileName;
    }
    if (_product.sku !== '') {
      smProduct.sku = updateSKU(_product.sku.toUpperCase(), 'LXL', 'SM');
      ythProduct.sku = updateSKU(_product.sku.toUpperCase(), 'LXL', 'YTHL');
      ythmProduct.sku = updateSKU(_product.sku.toUpperCase(), 'LXL', 'YTHM');
      babyProduct.sku = updateSKU(_product.sku.toUpperCase(), 'LXL', 'BABY');
    }

    for (let i = 0; i < _product.variants.length; i++) {
      if (_product.variants[i] !== '') {
        smProduct.variants[i] = updateSKU(
          _product.variants[i].toUpperCase(),
          'LXL',
          'SM',
        );
        ythProduct.variants[i] = updateSKU(
          _product.variants[i].toUpperCase(),
          'LXL',
          'YTHL',
        );
        ythmProduct.variants[i] = updateSKU(
          _product.variants[i].toUpperCase(),
          'LXL',
          'YTHM',
        );
        babyProduct.variants[i] = updateSKU(
          _product.variants[i].toUpperCase(),
          'LXL',
          'BABY',
        );
      }
    }

    _soloArts[1].product = smProduct;
    _soloArts[2].product = ythProduct;
    _soloArts[3].product = ythmProduct;
    _soloArts[4].product = babyProduct;

    setSoloArts(_soloArts);
  };

  const createSoloProductsTdlr = (_product) => {
    const tdlr12Product = { ..._product };
    tdlr12Product.variants = [..._product.variants];
    tdlr12Product.tags = [..._product.tags];
    tdlr12Product.templates = [..._product.templates];
    const _soloArts = [...soloArts];

    tdlr12Product.size = _.find(sizes, { sku: 'TDLR12' }).id;

    if (data.front) {
      const fileName = _product.pathFront;
      const tdlr12FileName = updateImageName(fileName, '2-4Y', '1-2Y');
      tdlr12Product.pathFront = tdlr12FileName;
    }

    if (data.back) {
      const fileName = _product.pathBack;
      const tdlr12FileName = updateImageName(fileName, '2-4Y', '1-2Y');
      tdlr12Product.pathBack = tdlr12FileName;
    }

    if (_product.sku !== '') {
      tdlr12Product.sku = updateSKU(
        _product.sku.toUpperCase(),
        'TDLR24',
        'TDLR12',
      );
    }

    for (let i = 0; i < _product.variants.length; i++) {
      if (_product.variants[i] !== '') {
        tdlr12Product.variants[i] = updateSKU(
          _product.variants[i].toUpperCase(),
          'TDLR24',
          'TDLR12',
        );
      }
    }

    _soloArts[1].product = tdlr12Product;

    setSoloArts(_soloArts);
  };

  const updateSizesAndFormats = (_product) => {
    let updatedSizes = contextData.sizes.map((item) => ({
      id: item.id,
      name: item.name,
      val: item.id,
      sku: item.sku,
      type: item.type,
    }));

    let updatedFormats = contextData.formats.map((item) => ({
      id: item.id,
      name: item.name,
      val: item.id,
    }));

    const selectedStyle = _product.style;
    const selectedType = _product.type;

    if (selectedType === 'Socks' && selectedStyle === 'Graphic') {
      // Apply specific filtering logic here
      updatedSizes = updatedSizes.filter((size) =>
        [
          'L/XL',
          'S/M',
          'Youth L',
          'Youth M',
          'Toddler 2-4Y',
          'Toddler 1-2Y',
          'Baby 0-12M ',
        ].includes(size.name),
      );

      // Filter out only the formats you need
      const filteredFormats = updatedFormats.filter((format) =>
        ['Crew', 'Quarter', 'Low'].includes(format.name),
      );
      // Define a custom order
      const customOrder = ['Crew', 'Quarter', 'Low'];

      // Sort the array based on the custom order
      filteredFormats.sort(
        (a, b) => customOrder.indexOf(a.name) - customOrder.indexOf(b.name),
      );

      updatedFormats = filteredFormats;

      const customOrderSize = [
        'L/XL',
        'S/M',
        'Youth L',
        'Youth M',
        'Toddler 2-4Y',
        'Toddler 1-2Y',
        'Baby 0-12M ',
      ];

      // Sort the array based on the custom order
      updatedSizes.sort(
        (a, b) =>
          customOrderSize.indexOf(a.name) - customOrderSize.indexOf(b.name),
      );
      updatedSizes = updatedSizes.filter((size) => size.type === 'Socks');

      updatedFormats = filteredFormats;
    }

    if (selectedType === 'Socks' && selectedStyle === 'HyperOptic') {
      updatedFormats = updatedFormats.filter((format) =>
        ['Crew'].includes(format.name),
      );
      updatedSizes = updatedSizes.filter((size) =>
        ['L/XL', 'S/M', 'Youth L'].includes(size.name),
      );
      updatedSizes = updatedSizes.filter((size) => size.type === 'Socks');
    }

    if (selectedStyle === 'Knitted' && selectedType === 'Socks') {
      // include Kee high, when ready
      updatedFormats = updatedFormats.filter((format) =>
        ['Crew', 'Quarter', 'Low'].includes(format.name),
      );
      updatedSizes = updatedSizes.filter((size) =>
        ['2XL', 'L/XL', 'S/M', 'Youth L'].includes(size.name),
      );

      // Filter out only the formats you need
      const filteredFormats = updatedFormats.filter((format) =>
        ['Crew', 'Quarter', 'Low'].includes(format.name),
      );

      // Define a custom order
      const customOrder = ['Crew', 'Quarter', 'Low'];

      // Sort the array based on the custom order
      filteredFormats.sort(
        (a, b) => customOrder.indexOf(a.name) - customOrder.indexOf(b.name),
      );

      // reomve any that dont have type of socks in updatedSizes
      updatedSizes = updatedSizes.filter((size) => size.type === 'Socks');

      updatedFormats = filteredFormats;
    }

    if (selectedType === 'Underwear') {
      // First, update the format names if necessary
      updatedFormats = updatedFormats.map((format) => {
        if (format.name === "Men's Boxer Briefs" || format.name === 'Boxer') {
          return { ...format, name: "Men's Boxer Briefs" };
        }
        if (format.name === "Women's Boy Shorts") {
          return { ...format, name: "Women's Boy Shorts" };
        }
        if (format.name === "Women's Sports Bra") {
          return { ...format, name: "Women's Sports Bra" };
        }
        if (format.name === 'Youth Boxer Briefs') {
          return { ...format, name: 'Youth Boxer Briefs' };
        }
        return format;
      });

      // Then, filter formats as before (now with the updated name)
      updatedFormats = updatedFormats.filter((format) =>
        [
          "Men's Boxer Briefs",
          "Women's Boy Shorts",
          "Women's Sports Bra",
          'Youth Boxer Briefs',
        ].includes(format.name),
      );

      // Filter sizes as before
      updatedSizes = updatedSizes.filter((size) =>
        [
          '2XL',
          'XL',
          'L',
          'M',
          'S',
          'XS',
          'Youth S',
          'Youth M',
          'Youth L',
          'YTHL',
          'YTHM',
          'YTHS',
        ].includes(size.name),
      );

      updatedSizes = updatedSizes.filter((size) => size.type === 'Underwear');
    }

    if (selectedType === 'Accessories') {
      updatedFormats = updatedFormats.filter((format) =>
        ['Snapback', 'Trucker'].includes(format.name),
      );
      updatedSizes = updatedSizes.filter((size) => ['O/S'].includes(size.name));
      // get the one that has type == 'Accessories' for size
      updatedSizes = updatedSizes.filter((size) => size.type === 'Accessories');
    }

    if (selectedType === 'Add-On') {
      updatedFormats = updatedFormats.filter((format) =>
        [
          'Greeting Card',
          'Gift Box',
          'NFL 8-Bit Box',
          'VHS Box',
          'Sticker',
          'Coffin Box',
          'Suitcase',
        ].includes(format.name),
      );
      updatedSizes = updatedSizes.filter((size) => ['O/S'].includes(size.name));
    }

    if (selectedType === 'T-Shirt') {
      updatedFormats = updatedFormats?.filter((format) =>
        ['Adult Crew Neck', 'Youth Crew Neck'].includes(format.name),
      );
      // Function to filter sizes based on type
      const filterSizesByType = (sizes) => {
        // change sizes to only return youuth if _product is youth
        const formatChosen = _product?.format;
        const filteredFormat = updatedFormats.find(
          (format) => format.id === formatChosen,
        );

        if (filteredFormat?.name === 'Youth Crew Neck') {
          // find the sizes that are youth (begin with Youth .name ) && are type of Tshirt
          return sizes.filter(
            (size) => size.name.startsWith('Youth') && size.type === 'T-Shirt',
          );
        }
        return sizes.filter(
          (size) => !size.name.startsWith('Youth') && size.type === 'T-Shirt',
        );
      };
      updatedSizes = filterSizesByType(updatedSizes);
    }

    if (selectedStyle === 'Hoodie') {
      updatedFormats = updatedFormats.filter((format) =>
        ['Adult'].includes(format.name),
      );
      // get sizes that have type === 'Outerwear'
      updatedSizes = updatedSizes.filter((size) => size.type === 'Outerwear');
    }

    setSizes(updatedSizes);
    setFormats(updatedFormats);
  };

  useEffect(() => {
    updateSizesAndFormats(product);
  }, [product]);

  const suffixMap = [
    '-WBSH-L',
    '-WBSH-M',
    '-WBSH-S',
    '-WBSH-XL',
    '-WBSH-2XL',
    '-WBRA-L',
    '-WBRA-M',
    '-WBRA-S',
    '-WBRA-XL',
    '-WBRA-2XL',
    '-YBB-L',
    '-YBB-M',
    '-YBB-S',
    '-MBB-L',
    '-MBB-M',
    '-MBB-S',
    '-MBB-XL',
    '-MBB-2XL',
    '-LOW-LXL',
    '-LOW-SM',
    '-LOW-YTHL',
    '-LOW-YTHM',
    '-LOW-YTHS',
    '-LXL',
    '-YTHL',
    '-YTHM',
    '-YTHS',
    '-TDLR24',
    '-TDLR12',
  ];

  const handleChange = (event) => {
    console.log('Product Changed...');
    let { name, value, checked, type } = event.target;
    value = type === 'checkbox' ? checked : value;

    // Store original SKU for comparison
    const originalSku = product.sku || '';

    // Capture cursor position before update
    const cursorPosition = event.target.selectionStart;

    // Create updated product object
    let _product = { ...product, [name]: value };
    let matchedSuffix = '';
    // Handle SKU specifically
    if (name === 'sku') {
      // Detect if any suffix from suffixMap is present in the original SKU
      matchedSuffix = suffixMap.find((suffix) => originalSku.endsWith(suffix));

      if (matchedSuffix) {
        // Enforce that suffix remains intact
        if (!value.endsWith(matchedSuffix)) {
          // Get original prefix length
          const prefixLength = originalSku.length - matchedSuffix.length;

          // Get whatever the user typed as the prefix part
          let newPrefix = value.substring(0, prefixLength);

          // Ensure prefix ends with hyphen if needed
          if (matchedSuffix.startsWith('-') && !newPrefix.endsWith('-')) {
            newPrefix = newPrefix.endsWith('-') ? newPrefix : newPrefix + '-';
          }

          // Reconstruct the SKU with user's prefix + required suffix
          _product.sku =
            newPrefix +
            matchedSuffix.substring(matchedSuffix.startsWith('-') ? 1 : 0);
        }
      }
    }

    if (name === 'type') {
      _product = { ..._product, style: '', size: '', format: '' };
    }

    setProduct(_product);
    changeProduct(_product);
    updateSizesAndFormats(_product);

    // Restore cursor position after state update
    requestAnimationFrame(() => {
      if (event.target && document.activeElement === event.target) {
        let newCursorPosition = cursorPosition;

        // If we had to fix the SKU, ensure cursor stays within editable prefix part
        if (matchedSuffix && name === 'sku') {
          const prefixEnd = _product.sku.length - matchedSuffix.length;
          newCursorPosition = Math.min(newCursorPosition, prefixEnd);
        }

        event.target.setSelectionRange(newCursorPosition, newCursorPosition);
      }
    });
  };

  const handleChangeTemplate = (event) => {
    console.log('Template Changed...');
    const { value } = event.target;

    const _product = { ...product };
    const _templates = [..._product.templates];

    if (value !== '') {
      const currentTemplate = _.find(templates, { id: value });
      if (currentTemplate) {
        _product.isCustomProduct = true;
        _product.isCustomText = currentTemplate.isCustomText;
      }
    } else {
      _product.isCustomProduct = false;
      _product.isCustomText = false;
    }

    if (value === '') {
      _templates.splice(0, 1);
    } else if (_templates.length > 0) {
      _templates[0].template = value;
    } else {
      _templates.push({ template: value });
    }

    _product.templates = _templates;
    setProduct(_product);
    changeProduct(_product);
  };

  const handleSelect = () => {
    if (contextData.onSelect) {
      contextData.onSelect(index, !data.selected);
    }
  };

  const handleDelete = () => {
    if (contextData.onDelete) {
      contextData.onDelete(index);
    }
  };

  const handleApprove = () => {
    if (
      frontIncorrectDimension ||
      backIncorrectDimension ||
      identicalHypOptIncorrectDimension ||
      leftFraternalHypOptIncorrectDimension ||
      rightFraternalHypOptIncorrectDimension
    ) {
      return;
    }

    if (contextData.onApprove) {
      contextData.onApprove(index);
    }
  };

  const handleSwitch = () => {
    if (contextData.onSwitchArt) {
      contextData.onSwitchArt(index);
    }
  };

  const renderSoloImages = () => (
    <SoloImage
      classes={classes}
      front={soloFrontCanvas}
      back={soloBackCanvas}
      identical={soloIdenticalHypOptCanvas}
      leftFraternal={soloLeftFraternalHypOptCanvas}
      rightFraternal={soloRightFraternalHypOptCanvas}
    />
  );

  const renderImages = () => {
    const front = getSizeAndColor(frontDimension);
    const back = getSizeAndColor(backDimension);
    const identical = getSizeAndColor(identicalHypOptDimension);
    const leftFraternal = getSizeAndColor(leftFraternalHypOptDimension);
    const rightFraternal = getSizeAndColor(rightFraternalHypOptDimension);
    const boxedImage = getSizeAndColor(boxedImageDimension);

    return (
      <Box
        display="flex"
        alignItems="center"
        sx={{
          justifyContent:
            identicalHypOptImage || isLoading || boxedImage ? 'center' : '',
        }}
      >
        {isLoading ? (
          <Box className={classes.centeredSpinnerBox}>
            <CircularProgress size={30} />
          </Box>
        ) : (
          <>
            <Box className={classes.ImageBox1}>
              <Checkbox
                color="primary"
                checked={props.data.selected}
                onChange={handleSelect}
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            </Box>

            {isNotAHyperOptic && !isLoading && (
              <>
                <Box className={classes.ImageBox2}>
                  <Typography className={classes.ImageBoxHeader}>
                    Front Art
                  </Typography>
                  <Box
                    className={
                      frontIncorrectDimension
                        ? classes.ImageWrapperError
                        : classes.ImageWrapper
                    }
                  >
                    {smFrontImage !== '' && (
                      <img className={classes.fullImage} src={smFrontImage} />
                    )}
                    <Dropzone
                      ref={dropzoneRef1}
                      onDrop={(files) => handleUploadFrontArt(files[0])}
                      multiple={false}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <Box {...getRootProps()} className={classes.artDropBox}>
                          <input {...getInputProps()} accept="image/png" />
                          {!frontImage && (
                            <Box>
                              <CloudUploadIcon />
                              <p className={classes.dropLabel}>
                                Drag & Drop Front Art File
                              </p>
                            </Box>
                          )}
                        </Box>
                      )}
                    </Dropzone>
                  </Box>
                  {frontDimension && frontIncorrectDimension == false && (
                    <Typography
                      className={classes.ImageBoxFooter}
                      style={{
                        color: front.color,
                      }}
                    >
                      {front.size} {front.unit}
                    </Typography>
                  )}
                  {frontDimension && frontIncorrectDimension && (
                    <Typography
                      className={classes.ImageBoxFooter}
                      sx={{ color: 'red' }}
                    >
                      Incorrect
                    </Typography>
                  )}
                </Box>

                <Box className={classes.ImageBox3}>
                  <SyncIcon onClick={handleSwitch} />
                </Box>

                <Box className={classes.ImageBox4}>
                  <Typography className={classes.ImageBoxHeader}>
                    Back Art
                  </Typography>
                  <Box
                    className={
                      backIncorrectDimension
                        ? classes.ImageWrapperError
                        : classes.ImageWrapper
                    }
                  >
                    {smBackImage && (
                      <img className={classes.fullImage} src={smBackImage} />
                    )}
                    <Dropzone
                      ref={dropzoneRef2}
                      onDrop={(files) => handleUploadBackArt(files[0])}
                      multiple={false}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <Box {...getRootProps()} className={classes.artDropBox}>
                          <input {...getInputProps()} accept="image/png" />
                          {!backImage && (
                            <Box>
                              <CloudUploadIcon />
                              <p className={classes.dropLabel}>
                                Drag & Drop Back Art File
                              </p>
                            </Box>
                          )}
                        </Box>
                      )}
                    </Dropzone>
                  </Box>
                  {backDimension && backIncorrectDimension == false && (
                    <Typography
                      className={classes.ImageBoxFooter}
                      style={{ color: back.color }}
                    >
                      {back.size} {back.unit}
                    </Typography>
                  )}
                  {backDimension && backIncorrectDimension && (
                    <Typography
                      className={classes.ImageBoxFooter}
                      sx={{ color: 'red' }}
                    >
                      Incorrect
                    </Typography>
                  )}
                </Box>
              </>
            )}

            {/* identical Image */}
            {identicalHypOptImage && !isLoading && !isNotAHyperOptic && (
              <Box className={classes.ImageBox2}>
                <Typography className={classes.ImageBoxHeader}>
                  Identical
                </Typography>
                <Box
                  className={
                    identicalHypOptIncorrectDimension
                      ? classes.ImageWrapperError
                      : classes.IdenticalImageWrapper
                  }
                >
                  {smIdenticalHypOptImage && (
                    <img
                      className={classes.fullImage}
                      src={smIdenticalHypOptImage}
                    />
                  )}
                </Box>
                {identical &&
                  identicalHypOptDimension &&
                  !identicalHypOptIncorrectDimension && (
                    <Typography
                      className={classes.ImageBoxFooter}
                      style={{ color: identical.color }}
                    >
                      {identical.size} {identical.unit}
                    </Typography>
                  )}

                {identicalHypOptIncorrectDimension && (
                  <Typography
                    className={classes.ImageBoxFooter}
                    sx={{ color: 'red' }}
                  >
                    Incorrect
                  </Typography>
                )}
              </Box>
            )}

            {isFraternalImage && !isLoading && !isNotAHyperOptic && (
              <>
                <Box className={classes.ImageBox4}>
                  <Typography className={classes.ImageBoxHeader}>
                    Right Art
                  </Typography>
                  <Box
                    className={
                      rightFraternalHypOptIncorrectDimension
                        ? classes.ImageWrapperError
                        : classes.ImageWrapper
                    }
                  >
                    {smRightFraternalHypOptImage && (
                      <img
                        className={classes.fullImage}
                        src={smRightFraternalHypOptImage}
                      />
                    )}

                    <Dropzone
                      ref={dropzoneRef2}
                      onDrop={(files) => handleDropRightArt(files[0])}
                      multiple={false}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <Box {...getRootProps()} className={classes.artDropBox}>
                          <input {...getInputProps()} accept="image/png" />
                          {!smRightFraternalHypOptImage && (
                            <Box>
                              <CloudUploadIcon />
                              <p className={classes.dropLabel}>
                                Drag & Drop Right Art File
                              </p>
                            </Box>
                          )}
                        </Box>
                      )}
                    </Dropzone>
                  </Box>
                  {!rightFraternalHypOptIncorrectDimension &&
                    rightFraternalHypOptDimension && (
                      <Typography
                        className={classes.ImageBoxFooter}
                        style={{ color: rightFraternal.color }}
                      >
                        {rightFraternal.size} {rightFraternal.unit}
                      </Typography>
                    )}
                  {rightFraternalHypOptDimension &&
                    rightFraternalHypOptIncorrectDimension && (
                      <Typography
                        className={classes.ImageBoxFooter}
                        sx={{ color: 'red' }}
                      >
                        Incorrect
                      </Typography>
                    )}
                </Box>
                <Box className={classes.ImageBox3}>
                  <SyncIcon onClick={handleSwitch} />
                </Box>

                <Box className={classes.ImageBox2}>
                  <Typography className={classes.ImageBoxHeader}>
                    Left Art
                  </Typography>
                  <Box
                    className={
                      leftFraternalHypOptIncorrectDimension
                        ? classes.ImageWrapperError
                        : classes.ImageWrapper
                    }
                  >
                    {smLeftFraternalHypOptImage !== '' && (
                      <img
                        className={classes.fullImage}
                        src={smLeftFraternalHypOptImage}
                      />
                    )}
                    <Dropzone
                      ref={dropzoneRef1}
                      onDrop={(files) => handleDropLeftArt(files[0])}
                      multiple={false}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <Box {...getRootProps()} className={classes.artDropBox}>
                          <input {...getInputProps()} accept="image/png" />
                          {!smLeftFraternalHypOptImage && (
                            <Box>
                              <CloudUploadIcon />
                              <p className={classes.dropLabel}>
                                Drag & Drop Left Art File
                              </p>
                            </Box>
                          )}
                        </Box>
                      )}
                    </Dropzone>
                  </Box>
                  {leftFraternal &&
                    leftFraternalHypOptDimension &&
                    !leftFraternalHypOptIncorrectDimension && (
                      <Typography
                        className={classes.ImageBoxFooter}
                        style={{ color: leftFraternal.color }}
                      >
                        {leftFraternal.size} {leftFraternal.unit}
                      </Typography>
                    )}
                  {leftFraternalHypOptIncorrectDimension && (
                    <Typography
                      className={classes.ImageBoxFooter}
                      sx={{ color: 'red' }}
                    >
                      Incorrect
                    </Typography>
                  )}
                </Box>
              </>
            )}

            {isItABoxedImage && !isLoading && (
              <Box className={classes.ImageBox2}>
                <Typography className={classes.ImageBoxHeader}>Art</Typography>
                <Box
                  className={
                    boxedImageIncorrectDimension
                      ? classes.ImageWrapperError
                      : classes.BoxedImageWrapper
                  }
                >
                  {smBoxedImage !== '' && (
                    <img className={classes.fullImage} src={smBoxedImage} />
                  )}
                </Box>
                {boxedImage && !boxedImageIncorrectDimension && (
                  <Typography
                    className={classes.ImageBoxFooter}
                    style={{ color: boxedImage.color }}
                  >
                    {boxedImage.size} {boxedImage.unit}
                  </Typography>
                )}
                {boxedImageIncorrectDimension && (
                  <Typography
                    className={classes.ImageBoxFooter}
                    sx={{ color: 'red' }}
                  >
                    Incorrect
                  </Typography>
                )}
              </Box>
            )}
          </>
        )}
      </Box>
    );
  };

  const handleUploadFrontArt = (file) => {
    console.log('handleUploadFrontArt', file);

    const verified = checkIfItsFrontOrBack(file.name, 'front');

    if (!verified) {
      alert('Invalid file! Please upload a graphic sock image.');
      return;
    }

    if (contextData.onChangeArtFront) {
      contextData.onChangeArtFront(index, file);
    }
  };

  const handleUploadBackArt = (file) => {
    console.log('handleUploadBackArt');

    const verified = checkIfItsFrontOrBack(file.name, 'back');

    if (!verified) {
      alert('Invalid file! Please upload a graphic sock image.');
      return;
    }

    if (contextData.onChangeArtBack) {
      contextData.onChangeArtBack(index, file);
    }
  };

  const handleDropLeftArt = (file) => {
    const verified = destructureName(file.name, HYPEROPTIC_PREFIX.left);

    if (!verified) {
      return;
    }

    if (contextData.onChangeArtLeft) {
      contextData.onChangeArtLeft(index, file);
    }
  };

  const handleDropRightArt = (file) => {
    const verified = destructureName(file.name, HYPEROPTIC_PREFIX.right);

    if (!verified) {
      return;
    }

    if (contextData.onChangeArtRight) {
      contextData.onChangeArtRight(index, file);
    }
  };

  const handleAddVariant = () => {
    const newItem = '';

    const _product = { ...product };
    const _variants = [..._product.variants];
    _variants.push(newItem);
    _product.variants = _variants;

    setProduct(_product);
    changeProduct(_product);
  };

  const handleChangeVariant = (event, ind) => {
    const { value } = event.target;
    const _product = { ...product };
    const _variants = [..._product.variants];
    _variants[ind] = value;
    _product.variants = _variants;
    setProduct(_product);
    changeProduct(_product);
  };

  const handleDeleteVariant = (event, ind) => {
    const _product = { ...product };
    const _variants = [..._product.variants];
    _variants.splice(ind, 1);
    _product.variants = _variants;
    setProduct(_product);
    changeProduct(_product);
  };

  const handleAddTag = () => {
    const newItem = tags[0].name;
    const _product = { ...product };
    const _tags = [..._product.tags];
    _tags.push(newItem);
    _product.tags = _tags;

    setProduct(_product);
    changeProduct(_product);
  };

  const handleTagsChange = (event, ind) => {
    const { value } = event.target;
    const _tags = [...product.tags];
    if (value !== '') {
      _tags[ind] = value;
    } else {
      _tags.splice(index, 1);
    }

    const _product = { ...product };
    _product.tags = _tags;
    setProduct(_product);
    changeProduct(_product);
  };

  const renderSpecs = () => {
    const _template =
      product.templates.length === 0 ? '' : product.templates[0].template;

    const _tData = _.find(templates, { id: _template });

    // HERE APPLY SUFFIX
    if (
      product.sku === '' &&
      product.style === 'Graphic' &&
      (product.identical || product.type === 'Underwear')
    ) {
      const _sku = applySuffix(product, formats, productSizes);
      product.sku = _sku;
    }

    if (!product.identical && product.type === 'Socks') {
      // pass in the size of the sku
      const _sku = applySuffixToFraternal(product, formats, productSizes);
      product.sku = _sku;
    }

    return (
      <Box sx={{ padding: '20px' }}>
        <Grid container spacing={2}>
          <Grid item xs={9}>
            <BootstrapInput
              name="sku"
              placeholder="Product SKU"
              value={product.sku.toUpperCase()}
              onChange={handleChange}
              onFocus={(event) => {
                requestAnimationFrame(() => {
                  event.target.setSelectionRange(0, 0);
                });
              }}
            />

            {data.incorrectSKU && (
              <Typography
                className={classes.errorText}
                sx={{ fontStyle: 'italic', marginTop: '5px' }}
              >
                Incorrect SKU
              </Typography>
            )}
          </Grid>
          <Grid item xs={3}>
            <Link href="#" onClick={handleAddVariant} className={classes.link}>
              + Variants
            </Link>
          </Grid>
        </Grid>

        {product.variants.length > 0 && (
          <Grid container spacing={2}>
            {_.map(product.variants, (variant, index) => (
              <Grid item xs={12}>
                <Box display="flex" sx={{ width: '70%', marginTop: '10px' }}>
                  <BootstrapInput
                    name="variant"
                    value={variant}
                    onChange={(event) => handleChangeVariant(event, index)}
                  />

                  <Button onClick={() => handleDeleteVariant(event, index)}>
                    <DeleteOutlineIcon />
                  </Button>
                </Box>
              </Grid>
            ))}
          </Grid>
        )}

        <Grid container spacing={2} sx={{ marginTop: '20px' }}>
          <Grid item xs={9}>
            <BootstrapInput
              name="name"
              placeholder="Product Name"
              value={product.name}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={3}>
            <Link href="#" className={classes.link} onClick={handleAddTag}>
              + Tags
            </Link>
          </Grid>
        </Grid>

        {product.tags.length > 0 && (
          <Grid container spacing={2}>
            {_.map(product.tags, (tag, index) => (
              <Grid item xs={3}>
                <Select
                  sx={{ width: '100%' }}
                  input={<BootstrapInput />}
                  onChange={(e) => handleTagsChange(e, index)}
                  value={tag}
                >
                  <MenuItem value="">-</MenuItem>
                  {_.map(tags, (item, key) => (
                    <MenuItem key={'tagselect-' + key} value={item.name}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            ))}
          </Grid>
        )}

        <Grid container spacing={2} sx={{ marginTop: '15px' }}>
          <Grid item xs={3}>
            <InputLabel>Type</InputLabel>
            <Select
              name="type"
              value={product.type}
              input={<BootstrapInput />}
              onChange={handleChange}
            >
              <MenuItem value="">-</MenuItem>
              <MenuItem value="Socks">Socks</MenuItem>
              <MenuItem value="Underwear">Underwear</MenuItem>
              <MenuItem value="Add-On">Add-On</MenuItem>
              <MenuItem value="T-Shirt">T-Shirt</MenuItem>
              <MenuItem value="Outerwear">Outerwear</MenuItem>
              <MenuItem value="Accessories">Accessories</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={3}>
            <InputLabel>Style</InputLabel>
            <Select
              name="style"
              value={product.style}
              input={<BootstrapInput />}
              onChange={handleChange}
            >
              <MenuItem value="">-</MenuItem>
              {_.map(styles, (style) => (
                <MenuItem value={style.name}>{style.name}</MenuItem>
              ))}
            </Select>
          </Grid>

          <Grid item xs={3}>
            <InputLabel>Format</InputLabel>
            <Select
              name="format"
              value={product.format}
              input={<BootstrapInput />}
              onChange={handleChange}
            >
              <MenuItem value="">-</MenuItem>
              {_.map(formats, (format) => (
                <MenuItem value={format.id}>{format.name}</MenuItem>
              ))}
            </Select>
          </Grid>

          <Grid item xs={3}>
            <InputLabel>Size</InputLabel>
            <Select
              name="size"
              value={product.size}
              input={<BootstrapInput />}
              onChange={handleChange}
            >
              <MenuItem value="">-</MenuItem>
              {_.map(sizes, (size) => {
                const type = product?.type ?? '';
                // Conditional rendering to exclude "YTH"
                if (size.name === 'YTHL' && type !== 'Underwear') {
                  return null; // Skip rendering this MenuItem
                }
                return (
                  <MenuItem key={size.id} value={size.id}>
                    {size.name}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
        </Grid>

        <Grid container spacing={2} sx={{ marginTop: '15px' }}>
          {/* Removal of template for hyper optics */}
          {isNotAHyperOptic && (
            <Grid item xs={6}>
              <div>
                <InputLabel>Template</InputLabel>
                <Select
                  name="template"
                  sx={{ width: '100%' }}
                  input={<BootstrapInput />}
                  value={_template}
                  onChange={(event) => handleChangeTemplate(event)}
                >
                  <MenuItem value="">None</MenuItem>
                  {_.map(templates, (item) => (
                    <MenuItem value={item.id}>{item.name}</MenuItem>
                  ))}
                </Select>
              </div>
              {!!_tData && _tData.isCustomText && (
                <div sx={{ marginTop: '20px' }}>
                  <InputLabel>Text Color</InputLabel>
                  <BootstrapInput
                    name="color"
                    value={product.color}
                    onChange={handleChange}
                  />
                </div>
              )}
            </Grid>
          )}
          <Grid item xs={3}>
            {product.identical && (
              <IconButton
                className={classes.deleteSoloButton}
                onClick={handleDelete}
              >
                Delete
              </IconButton>
            )}
          </Grid>

          <Grid item xs={3}>
            {product.identical && (
              <IconButton
                className={classes.approveSoloButton}
                onClick={handleApprove}
              >
                Approve
              </IconButton>
            )}
          </Grid>
        </Grid>
      </Box>
    );
  };

  const renderActions = () => (
    <Box>
      <IconButton
        variant="green"
        onClick={handleApprove}
        sx={{ marginBottom: '10px', width: '100%' }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-around"
          sx={{ width: '80%' }}
        >
          <Typography sx={{ marginRight: '30px' }}>Approve</Typography>
          <CheckIcon />
        </Box>
      </IconButton>
      <IconButton variant="red" onClick={handleDelete} sx={{ width: '100%' }}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-around"
          sx={{ width: '80%' }}
        >
          <Typography sx={{ marginRight: '30px' }}>Delete</Typography>
          <DeleteOutlineIcon />
        </Box>
      </IconButton>
    </Box>
  );

  let isAutoSoloProducts = false,
    isAutoBoxerProducts = false,
    isAutoSoloProductsTdlr = false,
    isAutoAdultTshirts = false,
    isYouthTshirts = false,
    isAutoHoodies = false,
    isAutoWomansUnderwearShorts = false,
    isAutoWomansSportsBras = false,
    isAutoYouthBoxerProducts = false;

  if (!!product && sizes.length > 0) {
    const sizeItem = _.find(sizes, { id: product.size });
    const nameOfFormat = _.find(formats, { id: product.format })?.name;
    if (sizeItem) {
      isAutoSoloProducts = sizeItem.sku === 'LXL' && product.identical;
      isAutoSoloProductsTdlr = sizeItem.sku === 'TDLR24' && product.identical;
      isAutoBoxerProducts =
        sizeItem.sku === 'L' &&
        product.type === 'Underwear' &&
        (nameOfFormat === "Men's Boxer Briefs" || nameOfFormat === 'Boxer');
      isAutoAdultTshirts = sizeItem.sku === 'L' && product.type === 'T-Shirt';
      isYouthTshirts = sizeItem.sku === 'YTHL' && product.type === 'T-Shirt';
      isAutoHoodies = sizeItem.sku === 'L' && product.type === 'Outerwear';
      isAutoWomansUnderwearShorts =
        sizeItem.sku === 'L' &&
        product.type === 'Underwear' &&
        nameOfFormat === "Women's Boy Shorts";
      isAutoWomansSportsBras =
        sizeItem.sku === 'L' &&
        product.type === 'Underwear' &&
        nameOfFormat === "Women's Sports Bra";
      isAutoYouthBoxerProducts =
        sizeItem.sku === 'YTHL' &&
        product.type === 'Underwear' &&
        nameOfFormat === 'Youth Boxer Briefs';
    }
  }

  return (
    <Box>
      {props.loading && <LinearProgress />}
      {props.data.error && (
        <LinearProgress variant="determinate" color="secondary" value="100" />
      )}

      <Box className={gray ? classes.grayRow : classes.whiteRow}>
        {isAutoSoloProducts || isAutoSoloProductsTdlr ? (
          <Box
            display="flex"
            alignItems="flex-start"
            sx={{ position: 'relative', padding: '20px 0' }}
          >
            {props.loading && <Box className={classes.itemBoxOverlay}></Box>}
            <Box className={classes.itemBox4}>{renderImages()}</Box>
            <Box className={classes.itemBox5}>{renderSoloImages()}</Box>
            <Box className={classes.itemBox6}>{renderSpecs()}</Box>
          </Box>
        ) : (
          <Box
            display="flex"
            alignItems="center"
            sx={{ position: 'relative', padding: '20px 0' }}
          >
            {props.loading && <Box className={classes.itemBoxOverlay}></Box>}
            <Box className={classes.itemBox1}>{renderImages()}</Box>
            <Box className={classes.itemBox2}>{renderSpecs()}</Box>
            <Box className={classes.itemBox3}>{renderActions()}</Box>
          </Box>
        )}
      </Box>

      {isAutoSoloProducts && (
        <SoloSubProducts
          index={product.index}
          classes={classes}
          loading={props.loading}
          formats={formats}
          sizes={sizes}
          items={soloArts}
        />
      )}

      {isAutoSoloProductsTdlr && (
        <SoloSubProductsTdlr
          index={product.index}
          classes={classes}
          loading={props.loading}
          formats={formats}
          sizes={sizes}
          items={soloArts}
        />
      )}

      {isAutoBoxerProducts && (
        <BoxerSubProducts
          index={product.index}
          classes={classes}
          loading={props.loading}
          formats={formats}
          sizes={sizes}
          products={boxerProducts}
        />
      )}

      {isAutoAdultTshirts && (
        <AdultThirtSubProducts
          index={product.index}
          classes={classes}
          loading={props.loading}
          formats={formats}
          sizes={sizes}
          products={adultThirtProducts}
        />
      )}

      {isYouthTshirts && (
        <YouthTshirtSubProducts
          index={product.index}
          classes={classes}
          loading={props.loading}
          formats={formats}
          sizes={sizes}
          products={youthTshirtProducts}
        />
      )}

      {isAutoHoodies && (
        <HoodieSubProducts
          index={product.index}
          classes={classes}
          loading={props.loading}
          formats={formats}
          sizes={sizes}
          products={hoodieProducts}
        />
      )}

      {isAutoWomansUnderwearShorts && (
        <WomansUnderwearSubProducts
          index={product.index}
          classes={classes}
          loading={props.loading}
          formats={formats}
          sizes={sizes}
          products={womansBoyShorts}
        />
      )}

      {isAutoWomansSportsBras && (
        <WomansUnderwearSubProducts
          index={product.index}
          classes={classes}
          loading={props.loading}
          formats={formats}
          sizes={sizes}
          products={womansSportsBras}
        />
      )}

      {isAutoYouthBoxerProducts && (
        <YouthBoxerSubProducts
          index={product.index}
          classes={classes}
          loading={props.loading}
          formats={formats}
          sizes={sizes}
          products={youthBoxers}
        />
      )}
    </Box>
  );
}

export { SoloImage, SoloSubProducts, BoxerSubProducts, ProductUploadItem };
