/* eslint-disable max-depth */
import { useEffect, useState } from 'react';
import { Box } from '@mui/material';

import Layout from '../Page';
import { withAuthorization } from '../Session';
import ParamBox from './paramBox';
import { styles } from './styles';
import {
  addSizeToFormat,
  createFormat,
  createStyle,
  createType,
  deleteFormatFromType,
  deleteSizeFromFormat,
  deleteStyle,
  deleteType,
  editFormat,
  editSize,
  editStyle,
  editType,
} from '../../db/types';
import {
  getFinishedGoodFormats,
  getFormattedCropSettings,
} from '../../db/cropSettings';
import SettingModal from '../CropSetting/settingModal';
import { Toast } from '../Toast';

const ProductParams = (props) => {
  const [type, setType] = useState(null);
  const [style, setStyle] = useState(null);
  const [format, setFormat] = useState(null);
  const [size, setSize] = useState(null);
  const [typeId, setTypeId] = useState(null);
  const [settings, setSettings] = useState([]);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [showToastInfo, setShowToastInfo] = useState(false);
  const [showToastData, setShowToastData] = useState({
    isError: false,
    message: '',
  });
  // const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedType, setSelectedType] = useState(null);
  const [selectedStyle, setSelectedStyle] = useState(null);
  const [selectedFormat, setSelectedFormat] = useState(null);
  const [selectedSetting, setSelectedSetting] = useState(null);
  const [selectionPath, setSelectionPath] = useState({
    type: null,
    style: null,
    format: null,
    size: null,
  });
  const [openModal, setOpenModal] = useState(false);
  const { types, firebase, sizes, formats } = props;
  const db = firebase.db;

  // 1. First, modify useEffect to preserve selections
  useEffect(() => {
    const typeObjects = types.map((t) => ({
      name: t.name,
      id: t.id,
      styles: t.styles,
    }));

    setType(typeObjects);

    // Only update if we have selections
    if (typeId && selectedType) {
      const currentType = types.find((t) => t.id === typeId);

      // Ensure current type exists
      if (currentType) {
        setStyle(currentType.styles ?? []);

        if (selectedStyle) {
          const currentStyle = currentType.styles?.find(
            (s) => s.styleName === selectedStyle.styleName,
          );

          // Ensure style still exists
          if (currentStyle) {
            setFormat(currentStyle.formats ?? []);

            if (selectedFormat) {
              const format = currentStyle.formats?.find(
                (f) => f.id === selectedFormat.id,
              );

              // Ensure format still exists
              if (format) {
                setSize(format.sizes ?? []);
              }
            }
          }
        }
      }
    }
  }, [types]);

  useEffect(() => {
    getFormattedCropSettings(db, 100, fetchData);
  }, []);

  const fetchData = (items) => {
    console.log('Fetch CropSettings Data');
    setSettings([...items]);
  };

  const handleSelect = async (param, item) => {
    switch (param) {
      case 'type':
        handleTypeSelect(item);
        break;
      case 'style':
        handleStyleSelect(item);
        break;
      case 'format':
        handleFormatSelect(item);
        break;
      case 'size':
        await handleSizeSelect(item);
        break;
      default:
        console.warn('Unknown parameter:', param);
    }
  };

  const handleTypeSelect = (item) => {
    setSelectedType(item);
    setSelectedStyle(null);
    setSelectedFormat(null);
    setStyle(null);
    setFormat(null);
    setSize(null);
    setSelectionPath({ type: item, style: null, format: null, size: null });

    const typeData = types.find((t) => t.id === item.id);
    setStyle(typeData?.styles ?? []);
    setTypeId(typeData?.id ?? null);
  };

  const handleStyleSelect = (item) => {
    setSelectedStyle(item);
    setSelectedFormat(null);
    setFormat(null);
    setSize(null);
    setSelectionPath({
      ...selectionPath,
      style: item,
      format: null,
      size: null,
    });
    setFormat(item.formats ?? []);
  };

  const handleFormatSelect = (item) => {
    setSelectedFormat(item);
    setSize(null);
    setSelectionPath({ ...selectionPath, format: item, size: null });
    setSize(item?.sizes ?? []);
  };

  const handleSizeSelect = async (item) => {
    const { list: finishedGoodFormats, styles: finishedGoodStyles } =
      await getFinishedGoodFormats(db);
    const setting = settings.find(
      (s) => s.format === selectedFormat.id && s.size === item.id,
    );

    const isFinishedGood = finishedGoodFormats.includes(selectedFormat.id);
    const isFinishedGoodStyle = finishedGoodStyles.includes(
      selectedStyle.styleName,
    );

    if (isFinishedGood || isFinishedGoodStyle) {
      setShowToastInfo(true);
      setShowToastData({
        isError: true,
        message: 'Not allowed to create or view settings for Finished Goods.',
      });
      return;
    }

    if (!setting) {
      console.log('no setting found');
      setOpenCreateModal(true);
      return;
    }

    setSelectedSetting(setting);
    setOpenModal(true);
  };

  const handleAddType = async (item) => {
    await createType(db, { name: item });
  };

  const handleAddStyle = async (newStyle) => {
    if (!typeId) {
      console.error('No type selected');
      return;
    }
    await createStyle(db, typeId, newStyle);
  };

  const handleAddFormat = async (item) => {
    await createFormat(db, selectedType, formats, selectedStyle, item);
  };

  const handleAddSize = async (item) => {
    await addSizeToFormat(
      db,
      selectedType,
      sizes,
      selectedStyle,
      selectedFormat,
      item,
    );
  };

  const onDelete = async (item, label) => {
    console.log('LABEL ', label);
    if (label === 'Product Type') {
      await deleteType(db, item?.id ?? '');
    }
    if (label === 'Select Style') {
      await deleteStyle(db, typeId, item);
    }
    if (label === 'Select Format') {
      await deleteFormatFromType(db, typeId, selectedStyle, item);
    }
    if (label === 'Select Size') {
      await deleteSizeFromFormat(
        db,
        typeId,
        selectedStyle,
        selectedFormat,
        item,
      );
    }
  };

  const handleEditType = async (item, newName) => {
    await editType(db, item.id, newName);
  };

  const handleEditStyle = async (item, newName) => {
    await editStyle(db, typeId, item, newName);
  };

  const handleEditFormat = async (item, newName) => {
    await editFormat(
      db,
      typeId,
      selectedStyle,
      item,
      newName,
      formats,
      selectedType,
    );
  };

  const handleEditSize = async (item, newName) => {
    await editSize(
      db,
      typeId,
      selectedStyle,
      selectedFormat,
      item,
      newName,
      sizes,
    );
  };

  const handleCloseDialog = () => {
    setOpenModal(false);
  };

  const handleCloseCreateDialog = () => {
    setOpenCreateModal(false);
  };

  return (
    <Layout {...props} page="product-parameters" user={props.user}>
      <Box sx={styles.container}>
        {type && (
          <ParamBox
            label="Product Type"
            items={type}
            onSelect={(item) => handleSelect('type', item)}
            param="type"
            onAdd={handleAddType}
            onDelete={onDelete}
            onEdit={handleEditType}
            selected={selectedType}
            isSelected={selectionPath.type}
          />
        )}
        {style && (
          <ParamBox
            label="Select Style"
            items={style}
            onSelect={(item) => handleSelect('style', item)}
            param="style"
            onAdd={handleAddStyle}
            onDelete={onDelete}
            onEdit={handleEditStyle}
            selected={selectedType}
            isSelected={selectionPath.style}
          />
        )}
        {format && (
          <ParamBox
            label="Select Format"
            items={format}
            onSelect={(item) => handleSelect('format', item)}
            param="format"
            onAdd={handleAddFormat}
            onDelete={onDelete}
            onEdit={handleEditFormat}
            selected={selectedType}
            isSelected={selectionPath.format}
          />
        )}
        {size && (
          <ParamBox
            label="Select Size"
            items={size}
            onSelect={(item) => handleSelect('size', item)}
            param="size"
            onAdd={handleAddSize}
            onDelete={onDelete}
            onEdit={handleEditSize}
            selected={selectedType}
          />
        )}
      </Box>
      <SettingModal
        open={openModal}
        handleClose={handleCloseDialog}
        modal_name="Edit Crop Setting"
        item={selectedSetting}
      />
      <SettingModal
        isCreate
        open={openCreateModal}
        modal_name="New Crop Setting"
        handleClose={handleCloseCreateDialog}
        item={null}
      />
      <Toast
        show={showToastInfo}
        data={showToastData}
        onClose={() => setShowToastInfo(false)}
      />
    </Layout>
  );
};

const condition = (authUser) => !!authUser;
export default withAuthorization(condition)(ProductParams);
